import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const AppCardUseStyles = (cardColor, height, headerTextColor) => {
    return makeStyles(theme => ({
    root: {
      minHeight: height
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
      backgroundImage: `linear-gradient(${theme.palette.linearTop}, ${theme.palette.linearBottom})`,
      padding: 10,
      '& .MuiCardHeader-title': {
        color: headerTextColor && `${theme.palette[headerTextColor].main}`
      },
      marginBottom: 5,
    },
    leftBorder: {
      borderLeft: cardColor && `3px solid ${theme.palette[cardColor].main}`,
      paddingBottom: 0
    },
    rightBorder: {
      borderRight: cardColor && `3px solid ${theme.palette[cardColor].main}`,
      paddingBottom: 0
    },
    bottomBorder: {
      borderBottom: cardColor && `3px solid ${theme.palette[cardColor].main}`,
      paddingBottom: 0
    },
    topBorder: {
      borderTop: cardColor && `3px solid ${theme.palette[cardColor].main}`,
      paddingBottom: 0
    },
    outline: {
      border: cardColor && `3px solid ${theme.palette[cardColor].main}`,
      paddingBottom: 0
    },
    headerColor: {
      backgroundColor: theme.palette[cardColor].main,
      '& .MuiTypography-h5': {
        color: theme.palette[cardColor].contrastText
      }
    },
    backgroundColor: {
      backgroundColor: theme.palette[cardColor].main,
      color: theme.palette[cardColor].contrastText,
      '& .MuiTypography-body2': {
        color: theme.palette[cardColor].contrastText
      },
      '& .MuiIconButton-label': {
        color: theme.palette[cardColor].contrastText
      },
      '& .MuiTypography-h5': {
        color: theme.palette[cardColor].contrastText
      }
    },
    cardContent: {
      padding: 0,
      '&:last-child': {
        paddingBottom: 0
      }
    }
  }));
};

export default AppCardUseStyles;