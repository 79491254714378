import ActionBuilder from '../../ActionBuilder';
import ProcessTypes from '../../ProcessTypes';
import ActionTypes from '../../ActionTypes';
import GatewayFacade from "../../GatewayFacade";

const loadDeliveredCount = async ({ shell }) => {

  const gateway = new GatewayFacade(shell.communicator);

  const counts = await gateway.getDeliveryCount();

  return {
    type: ActionTypes.Persona.LoadedDeliveredCount,
    deliveredCount: counts.delivered
  };

};

export default ActionBuilder
  .for(loadDeliveredCount)
  .process(ProcessTypes.Persona.LoadingDeliveredCount)
  .build();