import * as Redux from 'redux';

import defaultLtlEquipmentType from './defaultLtlEquipmentType';
import defaultTlEquipmentType from './defaultTlEquipmentType';
import ltlEquipmentTypes from './ltlEquipmentTypes';
import tlEquipmentTypes from './tlEquipmentTypes';

export default Redux.combineReducers({
  defaultLtlEquipmentType,
  defaultTlEquipmentType,
  ltlEquipmentTypes,
  tlEquipmentTypes
});