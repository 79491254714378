import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import ComponentBuilder from "../../core/ComponentBuilder";
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import FreightCategoryTypeNames from '../../hubs/shipment/FreightCategoryTypeNames';
import AppTextBox from "../../core/components/inputs/AppTextBox/AppTextBox";
import combineClassNames from '../../core/utils/combineClassNames';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: 0,
    '& .MuiOutlinedInput-root': {
      borderColor: theme.palette.tgfGrey.border,
      padding: '0 0 0 10px'
    }
  },
  disabled: {
    backgroundColor: theme.palette.tgfGrey.disabled
  }
}));

const CrmShipmentCarrierComboBox = (props) => {
  const classes = useStyles();
  const {
    searchCarriers,
    onChange,
    staticContext,
    filterMap,
    placeholder,
    disabled = false,
    ...otherProps
  } = props;

  const [isOpen, setIsOpen] = React.useState(false);
  const [carriers, setCarriers] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleSearchTermChange = async (e) => {
    setIsLoading(true);
    const carriers = await searchCarriers(e.target.value);

    setCarriers(carriers);
    setIsLoading(false);
  };

  const handleOnChange = (e, carrierListing, reason) => {
    onChange(carrierListing);
  };

  const filterOptions = createFilterOptions({
    // matchFrom: 'start', *** ('any' | 'start' [optional]): Defaults to 'any'.
    stringify: (option) => option.name + option.mcNumber,
  });

  return (
    <Autocomplete
      open={isOpen}
      className={combineClassNames(classes.root, disabled ? classes.disabled : null)}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      disabled={disabled}
      options={carriers}
      loading={isLoading}
      onChange={handleOnChange}
      {...otherProps}
      getOptionSelected={(carrierListing, selectedAbListing) => carrierListing.mcNumber === selectedAbListing.mcNumber}
      getOptionLabel={(carrierListing) => `${carrierListing.mcNumber} / ${carrierListing.name}`}
      filterOptions={filterOptions}
      renderInput={
        (params) => (
          <AppTextBox
            {...params}
            variant={'outlined'}
            onChange={handleSearchTermChange}
            placeholder={placeholder}
            inputProps={{
              ...params.inputProps
            }}
          />
        )
      }
      renderOption={
        (option, state) => {
          return (
            <React.Fragment>
              {
                option.freightCategory.name === FreightCategoryTypeNames.Truckload ?
                  <span>{option.mcNumber} / {option.name} </span> :
                  <span>{option.name} </span>
              }
            </React.Fragment>
          )
        }
      }
    />
  );

};

export default ComponentBuilder
  .wrap(CrmShipmentCarrierComboBox)
  .dispatchToProps((shell, dispatch, ownProps) => {
    return {
      async searchCarriers(searchTerm) {

        if (searchTerm.trim().length === 0)
          return [];

        const filter = ownProps.filterMap({
          $or: [
            {
              name: {
                $like: `${searchTerm}%`
              }
            },
            {
              mcNumber: {
                $like: `${searchTerm}%`
              }
            }
          ]
        });

        const searchOptions = {
          filter,
          limit: ownProps.limit
        }

        const { carriers } = await shell.gateway.searchCarriers(searchOptions);
        return carriers;
      }
    }

  })
  .build();