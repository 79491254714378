import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';
import GatewayFacade from '../../../GatewayFacade';

const loadCompanyFirstShipment = async (shell, companyId) => {

  const gateway = new GatewayFacade(shell.communicator);
  const firstShipment = await gateway.getCompanyFirstShipment(companyId);

  return {
    type: ActionTypes.AddressBook.Modification.CompanyFirstShipmentLoaded,
    firstShipment
  };
};

export default ActionBuilder
  .for(loadCompanyFirstShipment)
  .build();