import combineClassNames from '../../../core/utils/combineClassNames';
import ComponentBuilder from '../../../core/ComponentBuilder';
import React from 'react';
import { Card, CardContent, CardActions, Typography } from '@material-ui/core';

const MetricCard = (props) => {

  const {
    classes,
    metric,
    title,
    isTgfMetric = false
  } = props;

  const actionClasses = {
    root: isTgfMetric ?
      classes.tgfActions :
      classes.associateActions
  };

  const titleClassName = isTgfMetric ?
    classes.tgfTitle :
    classes.associateTitle;

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography align={'center'} variant={'h5'}>
          { metric }
        </Typography>
      </CardContent>
      <CardActions classes={actionClasses}>
        <Typography align={'center'} className={titleClassName}>
          { title }
        </Typography>
      </CardActions>
    </Card>
  );
};

const metricCardStyles = (theme) => {

  const commonActions = {
    textAlign: 'center',
    display: 'block',
    padding: 6
  };

  const commonTitle = {
    fontSize: '.9em',
    fontWeight: 500,
    textTransform: 'uppercase'
  };

  return {
    root: {
     width: 125
    },
    associateActions: {
      backgroundColor: theme.palette.tgfGrey.dark,
      ...commonActions
    },
    associateTitle: {
      color: theme.palette.white,
      ...commonTitle
    },
    tgfActions: {
      backgroundColor: theme.palette.primary.light,
      ...commonActions
    },
    tgfTitle: {
      color: theme.palette.black,
      ...commonTitle
    }
  };
};

export default ComponentBuilder
  .wrap(MetricCard)
  .withStyles(metricCardStyles)
  .build();