import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';
import GatewayFacade from '../../../GatewayFacade';

const loadShipmentContents = async (shell, bolNumber) => {

  const gateway = new GatewayFacade(shell.communicator);
  const loadContents = await gateway.getAllShipmentContents(bolNumber);

  return {
    type: ActionTypes.Shipment.Modification.ShipmentContentsLoaded,
    loadContents
  };
};

export default ActionBuilder
  .for(loadShipmentContents)
  .build();
