import ActionBuilder from '../../../ActionBuilder';
import ActionTypes from '../../../ActionTypes';
import ProcessTypes from '../../../ProcessTypes';
import GatewayFacade from '../../../GatewayFacade';

const deleteCompanyContact = async ({ shell }, companyId, contactId) => {

  const gateway = new GatewayFacade(shell.communicator);

  const result = await gateway.deleteCompanyContact(companyId, contactId);

  const contacts = await gateway.getCompanyContacts(companyId);

  return {
    type: ActionTypes.AddressBook.Modification.CompanyContactDeleted,
    contacts
  };

};

export default ActionBuilder
  .for(deleteCompanyContact)
  .process(ProcessTypes.AddressBook.Modification.CompanyContactDeleting)
  .build();