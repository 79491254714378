import React from "react";
import {makeStyles} from '@material-ui/core/styles';
import ComponentBuilder from "../../core/ComponentBuilder";
import { TextField} from '@material-ui/core';
import Autocomplete, {createFilterOptions} from '@material-ui/lab/Autocomplete';
import RoleNames from "../../hubs/persona/RoleNames";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: 'white',
    padding: 0,
    '& .MuiOutlinedInput-root': {
      borderColor: theme.palette.tgfGrey.border,
      padding: '0 0 0 10px'
    }
  },
}));

const CrmAssociateDropDown = (props) => {

  const classes = useStyles();
  const {
    load,
    searchAssociates,
    onChangeAssociate,
    staticContext,
    placeholder,
    roleId,
    autoSelectFirst = true,
    ...otherProps
  } = props;

  const [associates, setAssociates] = React.useState([]);
  const [selectedAssociate, setSelectedAssociate] = React.useState(null);

  React.useEffect( () => {
    load()
      .then((associates) => {
        setAssociates(associates);
        if (autoSelectFirst && associates.length > 0) {
          setSelectedAssociate(associates[0]);
          onChangeAssociate(associates[0]);
        }
      })
  }, [])

  const handleChange = (e, associate, reason) => {
    setSelectedAssociate(associate);
    onChangeAssociate(associate);
  };

  return (
    <Autocomplete
      className={classes.root}
      options={associates}
      onChange={handleChange}
      value={selectedAssociate}
      {...otherProps}
      getOptionSelected={(associateListing, selectedAbListing) => associateListing.id === selectedAbListing.id}
      getOptionLabel={(associateListing) => { return associateListing.fullName}}
      renderInput={
        (params) => (
          <TextField
            {...params}
            variant={'outlined'}
            InputProps={{
              ...params.InputProps
            }}
          />
        )
      }
    />
  );

}

export default ComponentBuilder
  .wrap(CrmAssociateDropDown)
  .dispatchToProps((shell, dispatch, ownProps) => {
    return {
      async load() {

        const roles = await shell.gateway.getAllRoles();

        const searchOptions = {
          searchTerm: null,
          roleId: roles.map(r => {
            if(r.name === RoleNames.Queen)
              return r.id;
          }),
          offset: 0,
          limit: ownProps.limit,
          sort: [['lastName', 'asc']]
        };

        const associates = await shell.gateway.searchAllAssociates(searchOptions);
        return associates;
      }
    }

  })
  .build();