import React from 'react';
import { Route, Switch } from 'react-router-dom';

import HomePage from './areas/home/HomePage'
import AddressBookListingPage from './areas/address-book/ListingPage';
import AgingShipmentHistoryPage from './areas/address-book/AgingShipmentHistoryPage';
import AddressBookDocumentsPage from './areas/address-book/DocumentsPage';
import AddressBookCompanyOutcomeStreamPage from './areas/address-book/OutcomeStreamPage/OutcomeStreamPage';
import CarrierDetailPage from './areas/carriers/DetailsPage';
import CarrierCompliancePage from './areas/carriers/CompliancePage';
import CarrierDocumentsPage from './areas/carriers/DocumentsPage';
import CarrierNotesPage from './areas/carriers/NotesPage';
import CarrierHistoryPage from './areas/carriers/HistoryPage';
import BillingSettingsPage from './areas/address-book/BillingSettingsPage';
import FinancialHealthPage from './areas/address-book/FinancialHealthPage';
import ContactsPage from './areas/address-book/ContactsPage';
import DetailsPage from './areas/address-book/DetailsPage';
import LoginPage from './areas/security/LoginPage';
import OpenLoadsPage from './areas/open-loads';
import NotesPage from './areas/address-book/NotesPage';
import ShipmentHistoryPage from './areas/address-book/ShipmentHistoryPage';
import ShipmentCreateDocsPage from './areas/shipments/CreateDocsPage/CreateDocsPage';
import ShipmentListingPage from './areas/shipments/ListingPage/ListingPage';
import ShipmentDetailsPage from './areas/shipments/DetailsPage/DetailsPage';
import ShipmentDuplicationPage from './areas/shipments/DuplicationPage/DuplicationPage';
import ShipmentContentsPage from './areas/shipments/ContentsPage/ContentsPage';
import ShipmentLoadTrackingPage from './areas/shipments/LoadTrackingPage/LoadTrackingPage';
import ShipmentDocumentsPage from './areas/shipments/DocumentsPage/DocumentsPage';
import ShipmentFinancialPage from './areas/shipments/FinancialPage/FinancialPage';
import ShipmentAuditingPage from './areas/shipments/AuditingPage/AuditingPage';
import ShipmentMultipleStopsPage from './areas/shipments/MultipleStopsPage';
import SecureRoute from './areas/security/SecureRoute';

//Reporting route section
import CarriersList from './areas/reporting/AllCarriers';
import CustomerReport from './areas/reporting/CustomerReport';
import InvoiceAging from './areas/reporting/InvoiceAging';
import InvoiceAgingSummaryPage from './areas/reporting/InvoiceAgingSummary/InvoiceAgingSummaryPage';
import LaneLookup from './areas/reporting/LaneLookUp';
import SearchLoads from './areas/reporting/SearchLoads';
import MonthlyGrossMarginPage from './areas/reporting/MonthlyGrossMargin';
import CompanyGrossMarginPage from './areas/admin/CompanyGrossMargin';
import MissingCreditAppPage from './areas/reporting/MissingCreditApp';
import MissingPrimaryContactsPage from './areas/reporting/MissingPrimaryContacts';
import Reports from './areas/reporting/Reports';

//Admin route section
import CommissionSummary from './areas/admin/CommissionsSummary';
import CarrierListingPage from './areas/carriers/CarrierListing/CarrierListingPage';
import Admin from './areas/admin';
import AdminCommissionCenterPage from './areas/admin/CommissionCenter/CommissionsCenterPage';
import ReportCommissionCenterPage from './areas/reporting/CommissionCenter/CommissionCenterPage';
import CustomerPaymentsPage from './areas/admin/CustomerPayments';
import HomeSettingsPage from "./areas/admin/HomeSettingsPage";
import ManageUsersListingPage from './areas/admin/ManageUsers/ListingPage/ListingPage';
import ManageUsersDetailsPage from './areas/admin/ManageUsers/DetailsPage/DetailsPage';
import Help from './areas/help';
import DashboardPage from './areas/admin/Dashboard';
import AutomatedInvoiceQueuePage from './areas/admin/AutomatedInvoiceQueue/AutomatedInvoiceQueuePage';
import InvoicingExceptionWorkflowQueuePage from './areas/admin/InvoicingExceptionWorkflowQueue/InvoicingExceptionWorkflowQueuePage';
import BOLs from './areas/bols';
import GlobalSnackbar from './crm/components/GlobalSnackbar';
import GlobalSpinner from './crm/components/GlobalSpinner';
import Error404Page from './areas/errors/Error404Page';

const Noop = (props) => null;
const adminOnlyRoles = ['Ace'];

const App = (props) => {
  return (
    <React.Fragment>
      <Switch>
        <Route path={'/login'} component={LoginPage} />
        <SecureRoute exact path={'/'} component={HomePage} />
        <SecureRoute path={'/logout'} component={Noop} />

        <SecureRoute path={'/address-book/listing/:categoryTypeId?'} component={AddressBookListingPage} />
        <SecureRoute path={'/address-book/:id/details'} component={DetailsPage} />
        <SecureRoute path={'/address-book/:id/documents'} component={AddressBookDocumentsPage} />
        <SecureRoute path={'/address-book/:id/contacts'} component={ContactsPage} />
        <SecureRoute path={'/address-book/:id/notes'} component={NotesPage} />
        <SecureRoute path={'/address-book/:id/billing'} component={BillingSettingsPage} />
        <SecureRoute path={'/address-book/:id/shipment-history'} component={ShipmentHistoryPage} />
        <SecureRoute path={'/address-book/:id/aging-shipment-history'} component={AgingShipmentHistoryPage} />
        <SecureRoute path={'/address-book/:id/financial-health'} component={FinancialHealthPage} />
        <SecureRoute path={'/address-book/:id/outcome-stream'} component={AddressBookCompanyOutcomeStreamPage} />

        <SecureRoute path={'/carrier/listing'} component={CarrierListingPage} />
        <SecureRoute path={'/carrier/:id/details'} component={CarrierDetailPage} />
        <SecureRoute path={'/carrier/:id/compliance'} component={CarrierCompliancePage} />
        <SecureRoute path={'/carrier/:id/documents'} component={CarrierDocumentsPage} />
        <SecureRoute path={'/carrier/:id/history'} component={CarrierHistoryPage} />
        <SecureRoute path={'/carrier/:id/notes'} component={CarrierNotesPage} />

        <SecureRoute path={'/open-loads'} component={OpenLoadsPage} />

        <SecureRoute path={'/shipment/listing/:trackedSet'} component={ShipmentListingPage} />
        <SecureRoute path={'/shipment/:id/create-docs'} component={ShipmentCreateDocsPage} />
        <SecureRoute path={'/shipment/:id/details'} component={ShipmentDetailsPage} />
        <SecureRoute path={'/shipment/:id/duplicate'} component={ShipmentDuplicationPage} />
        <SecureRoute path={'/shipment/:id/contents'} component={ShipmentContentsPage} />
        <SecureRoute path={'/shipment/:id/load-tracking'} component={ShipmentLoadTrackingPage} />
        <SecureRoute path={'/shipment/:id/documents'} component={ShipmentDocumentsPage} />
        <SecureRoute path={'/shipment/:id/financial'} component={ShipmentFinancialPage} />
        <SecureRoute path={'/shipment/:id/auditing'} component={ShipmentAuditingPage} />
        <SecureRoute path={'/shipment/:id/multiple-stops'} component={ShipmentMultipleStopsPage} />

        <SecureRoute exact path={'/bols'} component={BOLs} />
        <SecureRoute exact path={'/support/help'} component={Help} />

        <SecureRoute exact path={'/reports'} component={Reports} />
        <SecureRoute path={'/reports/customer-report'} component={CustomerReport} />
        <SecureRoute path={'/reports/invoice-aging'} component={InvoiceAging} />
        <SecureRoute path={'/reports/invoice-aging-summary'} component={InvoiceAgingSummaryPage} />
        <SecureRoute path={'/reports/lane-lookup'} component={LaneLookup} />
        <SecureRoute path={'/reports/search-loads'} component={SearchLoads} />
        <SecureRoute path={'/reports/monthly-gross-margin'} component={MonthlyGrossMarginPage} />
        <SecureRoute path={'/reports/carriers-list'} component={CarriersList} />
        <SecureRoute path={'/reports/missing-credit-app'} component={MissingCreditAppPage} />
        <SecureRoute path={'/reports/commission-center'} component={ReportCommissionCenterPage} />
        <SecureRoute path={'/reports/missing-primary-contacts'} component={MissingPrimaryContactsPage} />

        <SecureRoute exact path={'/admin'} component={Admin} roles={adminOnlyRoles}/>
        <SecureRoute path={'/admin/commission-summary'} component={CommissionSummary} roles={adminOnlyRoles} />
        <SecureRoute exact path={'/admin/home-settings'} component={HomeSettingsPage} roles={adminOnlyRoles} />
        <SecureRoute path={'/admin/commission-center'} component={AdminCommissionCenterPage} roles={adminOnlyRoles} />
        <SecureRoute path={'/admin/customer-payments'} component={CustomerPaymentsPage} roles={adminOnlyRoles} />
        <SecureRoute path={'/admin/dashboard/:statusSet'} component={DashboardPage} roles={adminOnlyRoles} />
        <SecureRoute exact path={'/admin/manage-users'} component={ManageUsersListingPage} roles={adminOnlyRoles} />
        <SecureRoute path={'/admin/manage-users/:id'} component={ManageUsersDetailsPage} roles={adminOnlyRoles} />
        <SecureRoute path={'/admin/company-gross-margin'} component={CompanyGrossMarginPage} roles={adminOnlyRoles} />
        <SecureRoute path={'/admin/automated-invoice-queue'} component={AutomatedInvoiceQueuePage} roles={adminOnlyRoles} />
        <SecureRoute path={'/admin/invoicing-exception-queue'} component={InvoicingExceptionWorkflowQueuePage} roles={adminOnlyRoles} />
        <Route component={Error404Page} />
      </Switch>
      <GlobalSnackbar />
      <GlobalSpinner />
    </React.Fragment>
  );
};

export default App;