import React from 'react';
import {Grid} from '@material-ui/core';
import AppDialog from '../../../../core/components/AppDialog/AppMuiDialog';
import AppDropdown from '../../../../core/components/dropdowns/AppDropdown';
import AppMuiInput from '../../../../core/components/inputs/AppInput';
import AppAlertStatic from '../../../../core/components/AppAlertStatic';

const LockShipmentDialog = (props) => {

  const {
    confirmMessage,
    onSave,
    onCancel
  } = props;

  const dialogActions = [
    {
      title: 'Cancel',
      action: onCancel
    },
    {
      title: 'Save',
      action: onSave
    }
  ];

  return (
    <AppDialog
      title={'Lock Shipment'}
      open={true}
      onClose={onCancel}
      width={'sm'}
      actionButtons={dialogActions}>

      <Grid container spacing={2}>
        <Grid item>
          {confirmMessage}
        </Grid>
      </Grid>
    </AppDialog>
  );
};

export default LockShipmentDialog;