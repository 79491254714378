import Core from '@atomos/core';
import React from 'react';
import { Grid } from '@material-ui/core';
import * as Yup from 'yup';

import FullWidthLayout from '../../../core/layouts/FullWidthLayout';

import AddressBookRecordNav from '../AddressBookRecordNav';
import AppDialog from '../../../core/components/AppDialog';
import ChangeCategory from './ChangeCategory';
import ComponentBuilder from '../../../core/ComponentBuilder';
import FormFactor from '../../../core/FormFactor/FormFactor';
import isAce from '../../../hubs/persona/selectors/isAce';
import isNewCompany from '../../../hubs/addressBook/actions/modification/isNewCompany';
import renderDetailsForm from './renderDetailsForm';
import selectStateProvinces from '../../../hubs/support/selectors/stateProvinces';
import selectCompanyCategoryTypes from '../../../hubs/support/selectors/companyCategoryTypes';
import CategoryTypeNames from "../../../hubs/addressBook/CategoryTypeNames";
import composePageTitle from '../../../core/utils/composePageTitle';
import DisableCustomerReason from "./DisableCustomerReason";

const LoadProcessName = 'AddressBook.DetailsPage.Load';
const SaveProcessName = 'AddressBook.DetailsPage.Save';
const DisableCustomerProcessName = 'AddressBook.DetailsPage.DisableCustomer';
const LoadDisablementHistoryProcessName = 'AddressBook.DetailsPage.LoadDisablementHistory';

const DefaultCreditLimit = 10000.00;

const DetailsPage = (props) => {

  const {
    match,
    history,
    affiliateCompany,
    candidateAffiliates,
    associates,
    companyContacts = null,
    company,
    categoryTypes = [],
    creditStatus,
    customerCategoryType,
    saveCompanyCategoryChange,
    firstShipment,
    stateProvinces,
    isAce,
    modifyingAssociate,
    load,
    dispose,
    saveCompany,
    searchAffiliates,
    sendSnackbarMessage,
    loadDisablementHistory,
    manualDisablement,
    disabledCompanyHistory,
    disabledCompanyHistoryCount
  } = props;

  const stateProvinceObjects = stateProvinces
    .map(([abbreviation, name]) => ({ abbreviation, name }));

  const companyId = match.params.id !== 'new' ?
    parseInt(match.params.id) : undefined;

  const [showChangeCategory, setShowChangeCategory] = React.useState(false)
  const [showConvertToCustomer, setShowConvertToCustomer] = React.useState(false)
  const [showOpenDisableCustomer, setShowOpenDisableCustomer] = React.useState(false)
  const [disabledHistoryOffset, setDisabledHistoryOffset] = React.useState(0);
  const [disabledHistoryLimit, setDisabledHistoryLimit] = React.useState(1000);
  const [disabledHistorySort, setDisabledHistorySort] = React.useState([['createDate', 'desc']]);

  React.useEffect(() => {
    load(companyId);
    if (companyId) {
      loadDisablementHistory(companyId, disabledHistoryOffset, disabledHistoryLimit, disabledHistorySort);
    }
    return () => dispose();
  }, [companyId]);

  const cancelConvertToCustomer = () => setShowConvertToCustomer(false)

  const closeConvertToCustomer = () => {
    setShowConvertToCustomer(false)

    const clonedCompany = Core.Utils.cloneDeep(company);
    clonedCompany.categoryTypeId = customerCategoryType.id;
    clonedCompany.creditLimit = DefaultCreditLimit;
    const confirmText = "Lead converted to customer.";

    saveCompanyCategoryChange(clonedCompany, confirmText)
      .then(() => {
        sendSnackbarMessage({ content: 'Company converted to customer.' });
      });
  };
  const convertToCustomer = () => setShowConvertToCustomer(true);

  const convertActionButtons = [
    {
      title: "cancel",
      action: cancelConvertToCustomer
    },
    {
      title: "confirm",
      action: closeConvertToCustomer
    }
  ]

  const selectedCategoryType = company?.categoryTypeId ?
    categoryTypes.find(ct => ct.id === company?.categoryTypeId) :
    null;

  // Base data for the form.
  const companyData = Core.Utils
    .merge({}, company);

  const openChangeCategory = () => {
    setShowChangeCategory(true)
  };

  const closeChangeCategory = () => {
    setShowChangeCategory(false)
  };

  const openDisableCustomer = () => {
    setShowOpenDisableCustomer(true)
  };

  const handleChangeConfirmClick = (categoryType, reasonText) => {
    setShowChangeCategory(false);
    const confirmText = reasonText ? `Company category changed: ${reasonText}` : "Company category changed.";

    const clonedCompany = Core.Utils.cloneDeep(company);
    clonedCompany.categoryTypeId = categoryType.id;

    // set the credit limit to null if category type
    // anything but 'customer'
    categoryTypes.find(ct => ct.id === clonedCompany.categoryTypeId).name === CategoryTypeNames.Customer ?
      clonedCompany.creditLimit = 10000.00 :
      clonedCompany.creditLimit = null;

    saveCompanyCategoryChange(clonedCompany, confirmText)
      .then(() => {
        sendSnackbarMessage({ content: 'Company type changed.' });
      });
  };

  const handleDisabledCustomerHistoryPageChange = (e, page) => {
    setDisabledHistoryOffset(page * disabledHistoryLimit);
  };

  const handleDisabledCustomerHistorySortChange = (column) => {
    const [[columnName, order]] = disabledHistorySort;
    const changeOrder = (order === 'asc' && columnName === column) ? 'desc' : 'asc';

    setDisabledHistorySort([[column, changeOrder]]);
  };

  const handleDisabledCustomerHistoryLimitChange = (e) => {
    setDisabledHistoryOffset(0);
    setDisabledHistoryLimit(e.target.value);
  };

  const handleEnableCustomer = () => {
    manualDisablement(companyId, null)
      .then(() => {
        return loadDisablementHistory(companyId, disabledHistoryOffset, disabledHistoryLimit, disabledHistorySort);
      })
      .then(() => {
        sendSnackbarMessage({ content: 'Company enabled.' });
      });
  };

  const handleAutoCreditIncreaseComplete = () => {
    load(companyId)
      .then(() => {
        sendSnackbarMessage({ content: 'Company credit limit increase.' });
      });
  };

  const closeDisableCustomer = () => {
    setShowOpenDisableCustomer(false);
  };

  const handleDisableConfirmClick = (reasonText) => {
    setShowOpenDisableCustomer(false);
    manualDisablement(companyId, reasonText)
      .then(() => {
        return loadDisablementHistory(companyId, disabledHistoryOffset, disabledHistoryLimit, disabledHistorySort);
      })
      .then(() => {
        sendSnackbarMessage({ content: 'Company disabled.' });
      });
  };

  const filterCompanyCategoryTypes = () => isAce ?
      categoryTypes :
      categoryTypes.filter( ct => !ct.requiresAdmin);

  // Supporting objects for complex controls.
  const supportingData = {
    companyId,
    isAce,
    isNew: isNewCompany(companyId),
    associates,
    creditRemaining: creditStatus ?
      creditStatus.companyCreditRemaining :
      null,
    firstShipmentDate: firstShipment ?
      firstShipment.shipmentBolDate :
      null,
    categoryTypes: filterCompanyCategoryTypes(),
    stateProvinces: stateProvinceObjects,
    associateId: company?.associateId || modifyingAssociate.id,
    selectedAssociate: company?.associate || modifyingAssociate,
    disablingAssociate: company?.disablingAssociate,
    selectedCategoryType,
    selectedStateProvince: company?.stateProvince ?
      stateProvinceObjects
        .find(sp => sp.abbreviation === company?.stateProvince) : null,
    selectedAffiliate: affiliateCompany,
    companyContacts,
    candidateAffiliates,
    searchAffiliates,
    openChangeCategory,
    convertToCustomer,
    openDisableCustomer,
    disabledCompanyHistory,
    disabledCompanyHistoryCount,
    disabledHistoryOffset,
    disabledHistoryLimit,
    disabledHistorySort,
    handleDisabledCustomerHistoryPageChange,
    handleDisabledCustomerHistorySortChange,
    handleDisabledCustomerHistoryLimitChange,
    handleEnableCustomer,
    handleAutoCreditIncreaseComplete
  };

  const initialValues = Core.Utils
    .merge({}, companyData, supportingData);

  const handleSubmit = (values, formFactor) => {

    const {
      originalValues
    } = formFactor;

    const hasDisabledCompany = values.isDisabled && !originalValues.isDisabled;
    const hasEnabledCompany = !values.isDisabled;

    // If the user has opted to disable the
    // company, capture who is doing the disabling.
    if (hasDisabledCompany) {
      values.disablingAssociateId = modifyingAssociate.id;
      values.disabledDate = new Date();
    }
    // If reenabling, then clear the disable information.
    else if (hasEnabledCompany) {
      values.disablingAssociateId = null;
      values.disabledDate = null;
    }

    // set the credit limit to null if category type
    // anything but 'customer'
    if (categoryTypes.find(ct => ct.id === values.categoryTypeId).name !== CategoryTypeNames.Customer) {
      values.creditLimit = null;
    }

    saveCompany(values)
      .then((updatedCompany) => {
        loadDisablementHistory(updatedCompany.id, disabledHistoryOffset, disabledHistoryLimit, disabledHistorySort);
        return updatedCompany;
      })
      .then((updatedCompany) => {
        sendSnackbarMessage({content: 'Company saved.'});
        if (!companyId) {
          history.replace(`/address-book/${updatedCompany.id}/details`);
        }
      });

  };

  const companyName = companyId ?
    company?.name : null;

  const title = composePageTitle('Address Book', 'Details', companyName);

  const schema = company ?
    DetailsFormSchema : null;

  return (
    <FullWidthLayout
      SideNav={isNewCompany(companyId) ? null : AddressBookRecordNav}
      title={title}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FormFactor
            initialValues={initialValues}
            schema={schema}
            onSubmit={handleSubmit}
          >
            {renderDetailsForm}
          </FormFactor>
        </Grid>
      </Grid>
      {
        showChangeCategory && <ChangeCategory
          categoryTypes={categoryTypes}
          selectedCategoryTypeId={company.categoryTypeId}
          open={showChangeCategory}
          onClose={closeChangeCategory}
          onConfirmClick={handleChangeConfirmClick}
        />
      }
      {
        showConvertToCustomer && <AppDialog title="Conversion Confirmation"
          width={'xs'}
          open={showConvertToCustomer}
          onClose={closeConvertToCustomer}
          actionButtons={convertActionButtons}
        >
          Confirm <b>{company.name}</b> to customer?
        </AppDialog>
      }
      {
        showOpenDisableCustomer &&
        <DisableCustomerReason
          onClose={closeDisableCustomer}
          onConfirmClick={handleDisableConfirmClick}
        />
      }
    </FullWidthLayout>
  );
};

const DetailsFormSchema = Yup.lazy(values => {

  const schema = {
    name: Yup.string()
      .nullable()
      .required('Name is required.'),
    selectedAssociate: Yup.object()
      .nullable()
      .required('Associate must be selected.'),
    selectedCategoryType: Yup.object()
      .nullable()
      .required('Company Type must be selected.'),
    businessPhone: Yup.string()
      .nullable()
      .required('Business Phone is required.')
      .matches(Core.Text.PhoneRegExp, 'Business Phone is invalid.'),
    faxPhone: Yup.string()
      .nullable()
      .matches(Core.Text.PhoneRegExp, 'Fax Phone is invalid.'),
    address1: Yup.string()
      .nullable()
      .required('Address Line 1 is required.'),
    city: Yup.string()
      .nullable()
      .required('City is required.'),
    selectedStateProvince: Yup.string()
      .nullable()
      .required('State/Province must be selected.'),
    paymentTerms: Yup.number()
      .nullable()
      .required('Payment terms is required.')
      .typeError('Must be a number.')
      .min(0, 'Cannot be negative value.')
      .max(120, 'Cannot be over 120.'),
    postalCode: Yup.string()
      .nullable()
      .min(5, 'Zip/Postal Code must be at least 5 digits.')
      .max(7, 'Zip/Postal Code cannot be more than 7 digits.')
      .required('Zip/Postal Code is required.')
  };
  if (values.selectedCategoryType && values.selectedCategoryType.requiresCredit) {
    schema.creditLimit = Yup.number()
      .nullable()
      .min(0, 'Cannot be negative value.')
      .typeError('Credit Limit is required.')
      .required('Credit Limit is required.')
  }
  // The categoryType property should be a full CategoryType
  // instance that also
  if (values.selectedCategoryType && values.selectedCategoryType.requiresAffiliate) {
    schema.selectedAffiliate = Yup.object()
      .nullable()
      .required('Affiliate is required for Shipper/Consignee.');
  }

  return Yup.object().shape(schema);
});

export default ComponentBuilder
  .wrap(DetailsPage)
  .stateToProps((state, ownProps) => {
    return {
      affiliateCompany: state.addressBook.modification.affiliateCompany,
      candidateAffiliates: state.addressBook.modification.candidateAffiliates,
      associates: state.persona.brokerAssociates,
      company: state.addressBook.modification.company,
      companyContacts: state.addressBook.modification.contacts || null,
      creditStatus: state.addressBook.modification.creditStatus,
      customerCategoryType: state.support.customerCategoryType,
      firstShipment: state.addressBook.modification.firstShipment,
      categoryTypes: selectCompanyCategoryTypes(state),
      stateProvinces: selectStateProvinces(state),
      isAce: isAce(state),
      modifyingAssociate: state.persona.modifyingAssociate,
      disabledCompanyHistory: state.addressBook.modification.companyDisablementHistories,
      disabledCompanyHistoryCount: state.addressBook.modification.companyDisablementHistoriesCount
    };
  })
  .dispatchToProps((shell, dispatch, getState) => {
    return {
      async load(companyId) {
        dispatch(shell.actions.sys.processStart(LoadProcessName));

        const isNew = isNewCompany(companyId);

        const loadCompany = isNew ?
          shell.actions.addressBook.modification.loadNewCompany :
          () => shell.actions.addressBook.modification.loadCompany(companyId);

        const results = await Promise.all([
          loadCompany(),
          !isNew ? shell.actions.addressBook.modification.loadCompanyAffiliate(companyId) : undefined,
          !isNew ? shell.actions.addressBook.modification.loadCompanyContacts(companyId) : undefined,
          !isNew ? shell.actions.addressBook.modification.loadCompanyCreditStatus(companyId) : undefined,
          !isNew ? shell.actions.addressBook.modification.loadCompanyFirstShipment(companyId) : undefined,
          shell.actions.persona.loadBrokerAssociates()
        ]);

        results
          .filter(Core.Utils.identity)
          .forEach(dispatch);

        dispatch(shell.actions.sys.processComplete(LoadProcessName));
      },
      async dispose() {
        dispatch(await shell.actions.addressBook.modification.dispose());
      },
      async saveCompany(company) {
        dispatch(shell.actions.sys.processStart(SaveProcessName));
        const saveAction = await shell.actions.addressBook.modification.saveCompany(company);
        dispatch(saveAction);
        // Reload company affiliate.
        dispatch(await shell.actions.addressBook.modification.loadCompanyAffiliate(saveAction.company.id))
        dispatch(await shell.actions.addressBook.modification.loadCompanyCreditStatus(saveAction.company.id));
        dispatch(shell.actions.sys.processComplete(SaveProcessName));

        return saveAction.company;
      },
      async saveCompanyCategoryChange(company, noteText) {
        dispatch(shell.actions.sys.processStart(SaveProcessName));
        const saveAction = await shell.actions.addressBook.modification.saveCompany(company);
        dispatch(saveAction);
        dispatch(await shell.actions.addressBook.modification.saveCompanyNote(saveAction.company.id, noteText))
        dispatch(shell.actions.sys.processComplete(SaveProcessName));

        return saveAction.company;
      },
      async searchAffiliates(searchTerm) {
        // Perform a simple "fetch" of the affiliates versus
        // dispatching through the redux store to rerender the form.
        return await shell.gateway.searchAffiliates(searchTerm);
      },
      async sendSnackbarMessage(message) {
        dispatch(await shell.actions.sys.sendSnackbarMessage(message));
      },
      async loadDisablementHistory(companyId, offset, limit, sort) {
        dispatch(shell.actions.sys.processStart(LoadDisablementHistoryProcessName));
        dispatch(await shell.actions.addressBook.modification.loadCompanyDisablementHistory(companyId, offset, limit, sort));
        dispatch(shell.actions.sys.processComplete(LoadDisablementHistoryProcessName));
      },
      async manualDisablement(companyId, reasonText) {
        dispatch(shell.actions.sys.processStart(DisableCustomerProcessName));
        dispatch(await shell.actions.addressBook.modification.manualDisablement(companyId, reasonText));
        dispatch(shell.actions.sys.processComplete(DisableCustomerProcessName));
      }
    };
  })
  .build();