import ActionBuilder from '../../../ActionBuilder';
import ActionTypes from '../../../ActionTypes';
import ProcessTypes from '../../../ProcessTypes';

/**
 * Retrieves the first shipment for a given customer.
 * @param {object} shell - A shell instance.
 * @param {number} customerId - The ID for a customer.
 */
const retrieveCustomerFirstShipment = async ({ shell }, customerId) => {

  if (!customerId)
    return;

  const firstShipment = await shell.communicator
    .get(`/shipment/customer/${customerId}/first-shipment`);

  return {
    type: ActionTypes.Shipment.Customer.RetrievedCustomerFirstShipment,
    customerId,
    firstShipment
  };

};

const selectCustomerId = metadata => metadata.customerId;

const createProcessMetadata = (context, customerId) => {
  return {
    customerId
  };
};

export default ActionBuilder
  .for(retrieveCustomerFirstShipment)
  .process(ProcessTypes.Shipment.Customer.RetrievingCustomerFirstShipment, selectCustomerId, createProcessMetadata)
  .build();
