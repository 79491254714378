import * as Redux from 'redux';

import defaultStatusType from './defaultStatusType';
import deliveredStatusType from './deliveredStatusType';
import statusTypes from './statusTypes';

export default Redux.combineReducers({
  defaultStatusType,
  deliveredStatusType,
  statusTypes
});