import React from 'react';
import { Container, Grid, CssBaseline } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import AppNavBar from '../../core/components/AppNavBar';
import AppTitleBar from '../../core/components/AppTitleBar';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  appBarSpacer: {...theme.mixins.toolbar, marginBottom: theme.spacing(2)},
  content: {
    flexGrow: 1
  },
  container: {
    paddingBottom: theme.spacing(1),
  }
}));

const FullWidthLayout = ({ children, SideNav, title, ...props }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppNavBar />
      {SideNav && <SideNav />}
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="xl" className={classes.container}>
          <Grid container>
            {
              title && 
            <Grid item xs={12}>
              <AppTitleBar title={title} />
            </Grid>
            }
            {children}
          </Grid>
        </Container>
      </main>
    </div>
  );
};

export default FullWidthLayout;