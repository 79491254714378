import ActionBuilder from '../../../ActionBuilder2';
import GatewayFacade from '../../../GatewayFacade';
import ActionTypes from '../../../ActionTypes';

const saveShipmentContent = async (shell, content) => {

  const gateway = new GatewayFacade(shell.communicator);

  const updateContent = await gateway.saveShipmentContent(content);

  const loadContents = await gateway.getAllShipmentContents(content.bolNumber);

  return {
    type: ActionTypes.Shipment.Modification.ShipmentContentsSaved,
    loadContents
  }
};

export default ActionBuilder
  .for(saveShipmentContent)
  .build();
