import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import CrmDataInitializer from '../../crm/components/CrmDataInitializer';
import ComponentBuilder from '../../core/ComponentBuilder';
import selectIsAuthenticated from '../../hubs/persona/selectors/selectIsAuthenticated';
import isAce from '../../hubs/persona/selectors/isAce';
import isRole from '../../hubs/persona/selectors/isRole';

const checkRole = (stateValue, data) => {
  let isAuthorized = false;

  //data.roles is a prop on the route used to allow access via array of roles ['Ace', 'Jack'] from variable 'secureAceRole'
  if (isAce(stateValue) || data.roles === undefined) {
    isAuthorized = true;
  } else {
    if (data.roles) {
      data.roles.map((item) => {
        const validRole = isRole(stateValue, item)
        if (validRole) {
          isAuthorized = validRole;
        }
      });
    }
  }

  return isAuthorized;
}

const SecureRoute = secureRouteProps => {

  const {
    isAuthenticated,
    component,
    render,
    isRole = true,
    roles = null,
    ...routeProps
  } = secureRouteProps;

  const pageNotFoundRedirect = {
    pathname: '/page-not-found'
  };
  const safeRender = (safeRenderProps) => {

    // Provide options for the redirect.  This gives the
    // receiving component a location to come back to.
    const loginRedirect = {
      pathname: '/login'
    };

    const DelegateComponent = !!component ?
      component : render;

    return isAuthenticated ?
      (<CrmDataInitializer><DelegateComponent {...safeRenderProps} /></CrmDataInitializer>) :
      (<Redirect to={loginRedirect} />);

  };

  return (
    isRole ?
      <Route
        {...routeProps}
        render={safeRender}
      />
      : <Redirect to={pageNotFoundRedirect} />
  );

};

export default ComponentBuilder
  .wrap(SecureRoute)
  .props({
    isAuthenticated: PropTypes.bool.isRequired
  })
  .stateToProps((state, roles) => ({
    isAuthenticated: selectIsAuthenticated(state),
    isRole: checkRole(state, roles),
  }))
  .build();