import React from 'react';
import ComponentBuilder from '../../../core/ComponentBuilder';
import FullWidthLayout from '../../../core/layouts/FullWidthLayout';
import { Grid } from '@material-ui/core';
import AppMuiButton from '../../../core/components/AppButton';
import AppMuiCard from '../../../core/components/cards/AppCard';
import FailIcon from '../../../core/components/icons/FailIcon';
import {ArrowBack, Assignment} from '@material-ui/icons';
import SuccessIcon from '../../../core/components/icons/SuccessIcon';
import {Link} from 'react-router-dom';
import computeConstraints from './computeConstraints';
import DocumentTabs from './DocumentTabs';

const LoadProcessName = 'Shipment.CreateDocs.Load';

const CreateDocsPage = (props) => {

  const {
    shipment,
    match,
    load,
    dispose
  } = props;

  const bolNumber = parseInt(match.params.id);

  React.useEffect(() => () => dispose(), []);

  React.useEffect(() => {
    load(bolNumber);
  }, [bolNumber]);

  const [showGeneratedDocs, setShowGeneratedDocs] = React.useState(false);

  let constraintGroups = [];
  let isTlShipment = true;

  // Only build the constraints if the shipment is loaded.
  // No sense in showing any items until the shipment is loaded.
  if (shipment) {
    [constraintGroups, isTlShipment] = computeConstraints(props);
  }

  const canCreateDocs = constraintGroups.length > 0 &&
    constraintGroups.every(([category, constraints]) =>
      constraints.every(c => c.passed));

  const title = `Shipment - Create Docs - ${bolNumber}`;

  const handleCreateDocsClick = () => {
    setShowGeneratedDocs(true);
  };

  const rulesContent = (
    <ConstraintsGroup constraintGroups={constraintGroups} />
  );

  let bolContent = null;
  let rateConContent = null;
  let deliveryOrderContent = null;

  const docHeight = window.innerHeight * .6;

  if (showGeneratedDocs) {

    bolContent = (
      <iframe src={`/generate/shipment/${bolNumber}/bill-of-lading`} frameBorder={0} style={{ width: '100%', height: docHeight }} />
    );

    if (isTlShipment)
      rateConContent = (
        <iframe src={`/generate/shipment/${bolNumber}/rate-confirmation`} frameBorder={0} style={{ width: '100%', height: docHeight }} />
      );

    if (shipment.isDeliveryOrderRequired)
      deliveryOrderContent = (
        <iframe src={`/generate/shipment/${bolNumber}/delivery-order`} frameBorder={0} style={{ width: '100%', height: docHeight }} />
      );
  }

  return (
    <FullWidthLayout title={title}>
      <Grid container spacing={1}>
        <Grid container item spacing={1} xs={12}>
          <Grid item>
            <AppMuiButton
              type={'button'}
              startIcon={<Assignment />}
              disabled={!canCreateDocs}
              onClick={handleCreateDocsClick}>
              Create
            </AppMuiButton>
          </Grid>
          <Grid item>
            <AppMuiButton
              component={Link}
              to={`/shipment/${bolNumber}/details`}
              startIcon={<ArrowBack />}>
              Back to Shipment
            </AppMuiButton>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <DocumentTabs
            rulesContent={rulesContent}
            bolContent={bolContent}
            rateConContent={rateConContent}
            deliveryOrderContent={deliveryOrderContent}
          />
        </Grid>
      </Grid>
    </FullWidthLayout>
  );
};

const ConstraintsGroup = (props) => {
  const { constraintGroups } = props;
  return (
    <Grid container spacing={2}>
      {
        constraintGroups.map(([category, constraints], groupIndex) => {
          return (
            <Grid item xs={12} md={6} lg={3} key={groupIndex}>
              <AppMuiCard title={category} key={groupIndex}>
                {
                  constraints.map((constraint, index) => {
                    return (
                      <Grid container key={index}>
                        <Grid item xs={2}>
                          { constraint.passed ? <SuccessIcon /> : <FailIcon />}
                        </Grid>
                        <Grid item xs={10}>
                          { constraint.title }
                        </Grid>
                      </Grid>
                    );
                  })
                }
              </AppMuiCard>
            </Grid>
          );
        })
      }
    </Grid>
  )
}

export default ComponentBuilder
  .wrap(CreateDocsPage)
  .stateToProps((state, ownProps) => {
    return {
      shipmentStatusTypes: state.support.shipmentStatusTypes,
      shipmentFreightCategoryTypes: state.support.freightCategoryTypes,
      shipment: state.shipment.createDocs.shipment,
      customer: state.shipment.createDocs.customer,
      shipper: state.shipment.createDocs.shipper,
      consignee: state.shipment.createDocs.consignee,
      customerContacts: state.shipment.createDocs.customerContacts,
      shipperContacts: state.shipment.createDocs.shipperContacts,
      consigneeContacts: state.shipment.createDocs.consigneeContacts,
      loadContents: state.shipment.createDocs.loadContents,
      truckloadValueConfirmation: state.shipment.createDocs.truckloadValueConfirmation,
      carrierContact: state.shipment.createDocs.carrierContact,
      multiStopCompanies: state.shipment.createDocs.multiStopCompanies,
      multiStopCompanyContacts: state.shipment.createDocs.multiStopCompanyContacts,
      multiStopLocations: state.shipment.createDocs.multiStopLocations
    };
  })
  .dispatchToProps((shell, dispatch, getState) => {
    return {
      async load(bolNumber) {
        dispatch(shell.actions.sys.processStart(LoadProcessName));
        dispatch(await shell.actions.shipment.createDocs.loadShipment(bolNumber));
        dispatch(shell.actions.sys.processComplete(LoadProcessName));
      },
      async dispose() {
        dispatch(await shell.actions.shipment.createDocs.dispose());
      },
    };
  })
  .build();
