import React from 'react';
import ComponentBuilder from '../../core/ComponentBuilder';

const CrmDataInitializer = (props) => {

  const {
    actions,
    children
  } = props;

  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    const promises = actions.map(a => a());
    Promise.all(promises)
      .then(() => {
        setIsLoading(false);
      })
      .catch(error => console.error(error));
  }, []);

  if (isLoading)
    return null;

  return (
    <React.Fragment>
      {children}
    </React.Fragment>
  );
};

export default ComponentBuilder
  .wrap(CrmDataInitializer)
  .dispatchToProps((shell, dispatch, getState) => {
    return {
      actions: [
        async () => dispatch(await shell.actions.support.loadShipmentStatusTypes()),
        async () => dispatch(await shell.actions.support.loadFreightCategoryTypes()),
        async () => dispatch(await shell.actions.support.loadCompanyCategoryTypes()),
        async () => dispatch(await shell.actions.support.loadCompanyInvoiceDeliveryMethodTypes()),
        async () => dispatch(await shell.actions.support.loadShipmentEquipmentTypes()),
        async () => dispatch(await shell.actions.support.loadShipmentHazardousStates()),
        async () => dispatch(await shell.actions.support.loadShipmentTrackingPhases()),
        async () => dispatch(await shell.actions.support.loadShipmentFreightClasses()),
        async () => dispatch(await shell.actions.support.loadStateProvinces()),
        async () => dispatch(await shell.actions.support.loadShipmentDocumentTypes()),
        async () => dispatch(await shell.actions.support.loadCarrierDocumentTypes()),
        async () => dispatch(await shell.actions.support.loadShipmentPackageTypes()),
        async () => dispatch(await shell.actions.support.loadShipmentTruckLoadValueConfirmationType()),
        async () => dispatch(await shell.actions.support.loadCompanyDocumentTypes()),
        async () => dispatch(await shell.actions.persona.loadRoles())
      ]
    };
  })
  .build();