import React from 'react';
import ComponentBuilder from '../../../core/ComponentBuilder';
import FullWidthLayout from '../../../core/layouts/FullWidthLayout';
import {Grid, Typography} from '@material-ui/core';
import {ArrowBack, FileCopy} from '@material-ui/icons';
import AppMuiButton from '../../../core/components/AppButton';
import {Link} from 'react-router-dom';
import AppMuiCard from '../../../core/components/cards/AppCard';
import SuccessIcon from '../../../core/components/icons/SuccessIcon';
import FailIcon from '../../../core/components/icons/FailIcon';
import {makeStyles} from '@material-ui/core/styles';
import AppMuiNumericInput from '../../../core/components/inputs/AppNumericInput';

const LoadProcessName = 'Shipment.DuplicationPage.Load';
const DuplicateProcessName = 'Shipment.DuplicationPage.Duplicate';

const useStyles = makeStyles((theme) => ({
  warningLanguage: {
    color: theme.palette.danger.main,
    marginTop: 10,
    marginBottom: 10
  }
}));

const isActive = (company) =>
  company?.isInactive === false;

const isNotDisabled = (company) =>
  company?.isDisabled === false;

const hasPrimaryContact = (contacts) =>
  contacts?.length > 0 &&
  contacts.some(c => c.isPrimary);

const DuplicationPage = (props) => {

  const classes = useStyles();

  const {
    shipment,
    customer,
    shipper,
    consignee,
    customerContacts,
    shipperContacts,
    consigneeContacts,
    duplicateBolNumbers,
    customerCreditStatus,
    multiStopCompanies,
    multiStopCompanyContacts,
    match,
    load,
    duplicateShipment,
    dispose,
    sendSnackbarMessage
  } = props;

  const bolNumber = parseInt(match.params.id);

  React.useEffect(() => () => dispose(), []);

  React.useEffect(() => {
    load(bolNumber);
  }, [bolNumber]);

  const [duplicateCount, setDuplicateCount] = React.useState(null);

  const handleDuplicateCountChange = (e) => {
    const value = e.target.value && e.target.value.trim().length > 0 ?
      parseInt(e.target.value) : null;
    setDuplicateCount(value);
  };

  const handleDuplicateClick = (e) => {
    duplicateShipment(bolNumber, duplicateCount)
      .then(() => {
        setDuplicateCount(null);
        sendSnackbarMessage({ content: `Shipment ${bolNumber} duplicated ${duplicateCount} time(s).` })
      });
  };

  const title = `Duplicate Shipment - ${bolNumber}`;

  let duplicationConstraints = [];
  let coreCompanyOptions = [];

  if (shipment) {
    duplicationConstraints = [
      {
        title: 'Customer has credit. ($2,000+)',
        passed: customerCreditStatus?.companyCreditRemaining >= 2000
      },
      {
        title: 'Customer is active.',
        passed: isActive(customer)
      },
      {
        title: 'Customer is not disabled.',
        passed: isNotDisabled(customer)
      },
      {
        title: 'Customer has a primary contact.',
        passed: hasPrimaryContact(customerContacts)
      },
      {
        title: 'Shipper is active.',
        passed: isActive(shipper)
      },
      {
        title: 'Shipper has a primary contact.',
        passed: hasPrimaryContact(shipperContacts)
      },
      {
        title: 'Consignee is active.',
        passed: isActive(consignee)
      },
      {
        title: 'Consignee has a primary contact.',
        passed: hasPrimaryContact(consigneeContacts)
      }
    ];

    coreCompanyOptions = [
      {
        type: 'Customer',
        name: customer?.name,
        id: customer?.id
      },
      {
        type: 'Shipper',
        name: shipper?.name,
        id: shipper?.id
      },
      {
        type: 'Consignee',
        name: consignee?.name,
        id: consignee?.id
      }
    ];
  }

  const multiStopCompanyOptions = multiStopCompanies
    .map((company, index) => ({
      type: `Stop #${index + 1}`,
      name: company.name,
      id: company.id
    }));

  if (multiStopCompanies.length > 0) {
    multiStopCompanies.forEach((company, index) => {
      duplicationConstraints.push({
        title: `Stop #${index + 1} company is active.`,
        passed: isActive(company)
      });

      const companyContacts = multiStopCompanyContacts[index] || [];

      duplicationConstraints.push({
        title: `Stop #${index + 1} company has a primary contact.`,
        passed: companyContacts.some(c => c.isPrimary)
      });
    });
  }

  const canDuplicate = duplicationConstraints.every(c => c.passed) &&
    duplicateCount > 0 &&
    duplicateCount < 21;

  const hasDuplicatedShipment = duplicateBolNumbers.length > 0;

  return (
    <FullWidthLayout title={title}>
      <Grid container spacing={1}>
        <Grid container item spacing={1} xs={12}>
          <Grid item>
            <AppMuiButton
              type={'button'}
              startIcon={<FileCopy />}
              disabled={!canDuplicate}
              onClick={handleDuplicateClick}>
              Duplicate
            </AppMuiButton>
          </Grid>
          <Grid item>
            <AppMuiButton
              component={Link}
              to={`/shipment/${bolNumber}/details`}
              startIcon={<ArrowBack />}>
              Back to Shipment
            </AppMuiButton>
          </Grid>
        </Grid>
        <Grid container item xs={12} md={6} spacing={1}>
          <Grid container item xs={12}>
            <AppMuiCard>
              <Grid item xs={12}>
                Enter the number of duplicates you'd like for this shipment; maximum of 20.
                When the duplication is complete, the new shipment numbers will
                appear below.
              </Grid>
              <Grid item xs={12} className={classes.warningLanguage}>
                Duplicated shipments will have today's date as the pickup date by default.  You MUST adjust them as needed.
              </Grid>
              <Grid item xs={3}>
                <AppMuiNumericInput
                  label={'Number of Duplicates'}
                  onChange={handleDuplicateCountChange}
                  value={duplicateCount}
                />
              </Grid>
            </AppMuiCard>
          </Grid>
          {
            hasDuplicatedShipment &&
            <Grid item xs={12}>
              <AppMuiCard>
                <Grid container item xs={12} spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant={'body2'}>New Shipments</Typography>
                  </Grid>
                  {
                    duplicateBolNumbers.map((number, index) => (
                      <Grid item key={index}><Link to={`/shipment/${number}/details`}>{number}</Link></Grid>
                    ))
                  }
                </Grid>
              </AppMuiCard>
            </Grid>
          }
          <Grid item xs={12} md={6}>
            <AppMuiCard title={'Companies'}>
              <Grid container spacing={1}>
                { coreCompanyOptions.map(renderCompanyOption) }
              </Grid>
              {
                multiStopCompanyOptions.length > 0 &&
                <Grid container spacing={1} style={{ marginTop: 15 }}>
                  <Typography variant={'h5'}>
                    Multi-Stop Locations:
                  </Typography>
                  {
                    multiStopCompanyOptions.map(renderCompanyOption)
                  }
                </Grid>
              }
            </AppMuiCard>
          </Grid>
          <Grid item xs={12} md={6}>
            <AppMuiCard title={'Rule Checks'}>
              <Grid container spacing={1}>
                {
                  duplicationConstraints.map((constraint, index) => {
                    return (
                      <React.Fragment key={index}>
                        <Grid item xs={2}>
                          { constraint.passed ? <SuccessIcon /> : <FailIcon />}
                        </Grid>
                        <Grid item xs={10}>
                          { constraint.title }
                        </Grid>
                      </React.Fragment>
                    );
                  })
                }
              </Grid>
            </AppMuiCard>
          </Grid>
        </Grid>
      </Grid>
    </FullWidthLayout>
  );
};

const renderCompanyOption = (company, index) => {
  return (
    <Grid container item xs={12} key={index}>
      <Grid item xs={3}>
        {company.type}
      </Grid>
      <Grid item xs={9}>
        <Link to={`/address-book/${company.id}/details`}>{company.name}</Link>
      </Grid>
    </Grid>
  );
};

export default ComponentBuilder
  .wrap(DuplicationPage)
  .stateToProps((state, ownProps) => {
    return {
      shipment: state.shipment.duplication.shipment,
      customer: state.shipment.duplication.customer,
      shipper: state.shipment.duplication.shipper,
      consignee: state.shipment.duplication.consignee,
      customerContacts: state.shipment.duplication.customerContacts,
      shipperContacts: state.shipment.duplication.shipperContacts,
      consigneeContacts: state.shipment.duplication.consigneeContacts,
      duplicateBolNumbers: state.shipment.duplication.duplicateBolNumbers,
      customerCreditStatus: state.shipment.duplication.customerCreditStatus,
      multiStopCompanies: state.shipment.duplication.multiStopCompanies,
      multiStopCompanyContacts: state.shipment.duplication.multiStopCompanyContacts
    };
  })
  .dispatchToProps((shell, dispatch, getState) => {
    return {
      async load(bolNumber) {
        dispatch(shell.actions.sys.processStart(LoadProcessName));
        dispatch(await shell.actions.shipment.duplication.loadShipment(bolNumber));
        dispatch(shell.actions.sys.processComplete(LoadProcessName));
      },
      async duplicateShipment(bolNumber, duplicateCount) {
        dispatch(shell.actions.sys.processStart(DuplicateProcessName));
        dispatch(await shell.actions.shipment.duplication.duplicateShipment(bolNumber, duplicateCount));
        dispatch(shell.actions.sys.processComplete(DuplicateProcessName));
      },
      async dispose() {
        dispatch(await shell.actions.shipment.duplication.dispose());
      },
      async sendSnackbarMessage(message) {
        dispatch(await shell.actions.sys.sendSnackbarMessage(message));
      }
    };
  })
  .build();