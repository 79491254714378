import ActionTypes from '../../../ActionTypes';

const defaultStatusType = (state = null, action) => {
  switch (action.type) {
    case ActionTypes.Shipment.Statusing.Loaded:
      return action.defaultStatusType;
    default:
      return state;
  }
};

export default defaultStatusType;