import React from 'react';
import FullWidthLayout from '../../core/layouts/FullWidthLayout';
import LeftNav from './LeftNav';

class Hub {

  constructor() {
    this._sessions = new Map();
    this._state = new Map();
  }

  attach(id, delegate) {
    this._sessions.set(id, {
      state: new Map(),
      delegate
    })
    this._id = id;
    this._delegate = delegate;
  }

  doSomething() {
    this._state.set('one', 1);
    this._state.set('two', 2);
    this._state.set('counter', 0);
    setInterval(() => {
      this._state.set('counter', this._state.get('counter') + 1);
      this._delegate(Symbol());
    }, 1000);
  }

  get(key) {
    return this._state.get(key);
  }

}

const hub = new Hub();

const Signaler = () => {

  const [_, setSignal] = React.useState(null);

  React.useEffect(() => {
    hub.attach('test', setSignal);
    hub.doSomething();
  }, []);

  const count = hub.get('counter') || 0;

  return (
    <div>
      {count}
    </div>
  );
};

const Admin = (props) => {
  return (
    <FullWidthLayout SideNav={LeftNav} title={'Admin'}>

    </FullWidthLayout>
  );
}

export default Admin;