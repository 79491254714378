import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import AppMUITable from '../../../../core/components/AppDenseGrid/AppMUITable';

const useStyles = makeStyles((theme) => ({
  tdStyles: {
    textDecoration: 'none',
    color: theme.palette.tgfLink.main,  
    fontWeight: 'bold',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}));

const AddressBookListingTable = (props) => {
  const classes = useStyles();
  const {
    count,
    data,
    orderBy,
    order,
    rowsPerPage,
    page,
    onChangePage,
    onChangeRowsPerPage,
    onSort,
  } = props;

  const buildLink = (path, title) => <Link to={path} className={classes.tdStyles}>{title}</Link>;
  const AssociateCell = (props) => <>{props.rowData.associateFirstName} {props.rowData.associateLastName}</>;
  const rowClass = (data) => {
    return data.companyIsDisabled ? 'inActive' : null;
  }

  const GridColumns = [
    {
      title: "Name",
      field: 'companyName',
      component: ({rowData}) => buildLink(`/address-book/${rowData.companyId}/details`, rowData.companyName || '[BLANK]'),
      styles: {
        width: 240
      },
      dataType: 'component'
    },
    {
      field: "categoryTypeName",
      title: "Type",
      styles: {
        width: 110
      },
      dataType: 'string'
    },
    {
      title: "Affiliate",
      field: "affiliateCompanyName",
      component: ({rowData}) => buildLink(`/address-book/${rowData.affiliateCompanyId}/details`, rowData.affiliateCompanyName || '[BLANK]'),
      styles: {
        width: 200
      },
      dataType: 'component'
    },
    {
      field: "primaryContactFirstName",
      title: "Contact",
      styles: {
        width: 145
      },
      dataType: 'string'
    },
    {
      field: "companyBusinessPhone",
      title: "Phone",
      styles: {
        width: 90
      },
      dataType: 'string'
    },
    {
      field: "companyAddress1",
      title: "Address 1",
      styles: {
        width: 220
      },
      dataType: 'string'
    },
    {
      field: "companyCity",
      title: "City",
      styles: {
        width: 100
      },
      dataType: 'string'
    },
    {
      field: "companyStateProvince",
      title: "St/Prov",
      styles: {
        width: 50
      },
      dataType: 'string'
    },
    {
      field: "companyPostalCode",
      title: "Zip",
      styles: {
        width: 55
      },
      dataType: 'string'
    },
    {
      field: "associateLastName",
      title: "Associate",
      component: AssociateCell,
      styles: {
        width: 130
      },
      dataType: 'component'
    }
  ];

  return (
    <AppMUITable
      count={count}
      data={data}
      orderBy={orderBy}
      order={order}
      rowsPerPage={rowsPerPage}
      page={page}
      onChangePage={onChangePage}
      rowProps={rowClass}
      onChangeRowsPerPage={onChangeRowsPerPage}
      onSort={onSort}
      columns={GridColumns}
    />
  )
};

export default AddressBookListingTable;