import React from 'react';
import Core from '@atomos/core';
import { Grid } from '@material-ui/core';
import Add from '@material-ui/icons/Add';

import ComponentBuilder from '../../../../core/ComponentBuilder';
import isProcessActive from '../../../../hubs/sys/selectors/isProcessActive';
import FullWidthLayout from '../../../../core/layouts/FullWidthLayout';
import LeftNav from '../../LeftNav';

import AppButton from '../../../../core/components/AppButton';
import AppCheckbox from '../../../../core/components/inputs/AppCheckbox';
import AppMuiInput from '../../../../core/components/inputs/AppInput/AppMuiInput';
import AssociatesTable from './AssociatesTable';
import TableTopBlock from './TableTopBlock';
import {Link} from 'react-router-dom';

const LoadProcessName = 'ManageUsers.Loading';

// The name of each property that should be involved in search term filtration.
const SearchTermProps = [
  'firstName',
  'lastName',
  'emailAddress',
  'businessPhoneExt',
  'systemId'
];
const fieldIncludesTerm = (fieldName, searchTerm, associate) =>
  (associate[fieldName] || '').toLowerCase().includes(searchTerm);
const PropSearchTermFilter = (propNames, searchTerm) => (associate) =>
  searchTerm.length === 0 ||
  propNames.some(propName => fieldIncludesTerm(propName, searchTerm, associate));

// SearchField is a debounced text box for filtering associates.
const SearchField = (props) => {

  const {
    value,
    onChange
  } = props;

  const [searchTerm, setSearchTerm] = React.useState(value);
  const broadcastChange = React.useCallback(Core.Utils.debounce(onChange, 400), [onChange]);

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
    broadcastChange(e.target.value);
  };

  return (
    <AppMuiInput
      label="Search"
      value={searchTerm}
      onChange={handleChange}
    />
  );
};

const simplifyAssociateRecords = (associates) => {
  return associates.map(associate => {
    const role = associate.roles[0];
    return {
      ...associate,
      roleId: role?.id || null,
      roleName: role?.name || null
    };
  });
};

const ListingPage = (props) => {
  const {
    loadAssociates,
    associates
  } = props;

  const [sort, setSort] = React.useState([['lastName', 'asc']]);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('lastName');
  const [searchTerm, setSearchTerm] = React.useState('');
  const [isAllAssociates, setIsAllAssociates] = React.useState(false);

  React.useEffect(() => {
    loadAssociates(isAllAssociates);
  }, [isAllAssociates]);

  // Filter associates according to the current search term.
  const filteredAssociates = associates
    .filter(PropSearchTermFilter(SearchTermProps, searchTerm));

  // Convert the associates to a more sortable format.
  const simplifiedAssociates = simplifyAssociateRecords(filteredAssociates);

  // Next apply sorting to the associates.
  const orderedAssociates = Core.Utils.orderBy(simplifiedAssociates, [orderBy], [order]);

  // Handles when the user clicks on column headers for sorting.
  const handleSortChange = (column) => {
    const changeOrder = (order === 'asc' && sort[0][0] === column) ? 'desc' : 'asc';

    setSort([[column, changeOrder]]);
    setOrder(changeOrder);
    setOrderBy(column);
  };


  const handleSearchTerm = (searchTerm) => {
    setSearchTerm(searchTerm);
  };

  const handleAllAssociates = (e) => {
    setIsAllAssociates(e.target.checked);
  }

  return (
    <FullWidthLayout SideNav={LeftNav} title="Manage Users">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={1} alignItems={'flex-end'}>
            <Grid item xs={12} md={6} lg={3}>
              <SearchField
                value={searchTerm}
                onChange={handleSearchTerm}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3} style={{ textAlign: 'center' }}>
              <AppCheckbox
                name={"allAssociates"}
                label={"show all / past associates"}
                handleChange={handleAllAssociates}
                checked={isAllAssociates}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6} style={{ textAlign: 'right' }}>
              <AppButton component={Link} startIcon={<Add />} to={'/admin/manage-users/new'}>Add User</AppButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <AssociatesTable
            count={orderedAssociates.length}
            data={orderedAssociates}
            orderBy={orderBy}
            order={order}
            onSort={handleSortChange}
            HeaderBar={TableTopBlock}
            FooterBar={TableTopBlock}
          />
        </Grid>
      </Grid>
    </FullWidthLayout>
  )
}
export default ComponentBuilder
  .wrap(ListingPage)
  .stateToProps((state, ownProps) => {
    return {
      associates: state.admin.manageUsers.associates
    };
  })
  .dispatchToProps((shell, dispatch, getState) => {
    return {
      loadAssociates: async (showAllAssociates) => {
        dispatch(shell.actions.sys.processStart(LoadProcessName));
        dispatch(await shell.actions.admin.manageUsers.loadAssociates(showAllAssociates));
        dispatch(shell.actions.sys.processComplete(LoadProcessName));
      }
    };
  })
  .build();