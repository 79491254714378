import Core from '@atomos/core';
import Business from '@tgf-crm/business';
import ActionTypes from '../../../ActionTypes';
import ActionBuilder from '../../../ActionBuilder';
import ProcessTypes from '../../../ProcessTypes';

const { EquipmentTypeNames } = Business.Shipment;

/**
 * Creates an action that fetches shipment equipment types.
 * @constructor
 * @returns {function}
 */
const load = async ({ shell }) => {

  const equipmentTypes = await shell.communicator
    .get(`/shipment-equipment-type`);

  // Define the defaults needed when freight category is TL or LTL.
  const defaultLtlEquipmentType = equipmentTypes
    .find(et => et.name === EquipmentTypeNames.TlEquipmentTypeNames.Ltl);

  const defaultTlEquipmentType = null;

  // Split the equipment types into two groups:
  // - Those named "LTL" which will only be one.
  // - The rest for the TL equipment types.
  const [ltlEquipmentTypes, tlEquipmentTypes] = Core.Utils
    .partition(equipmentTypes, et => et.name === EquipmentTypeNames.TlEquipmentTypeNames.Ltl);

  return {
    type: ActionTypes.Shipment.Equipment.Loaded,
    ltlEquipmentTypes,
    tlEquipmentTypes,
    defaultLtlEquipmentType,
    defaultTlEquipmentType
  };

};

export default ActionBuilder
  .for(load)
  .process(ProcessTypes.Shipment.Equipment.Loading)
  .build();