import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';
import GatewayFacade from '../../../GatewayFacade';

const loadCompanyAgingNotes = async (shell, companyId) => {

  const gateway = new GatewayFacade(shell.communicator);

  const notes = await gateway.getCompanyAgingNotes(companyId);

  return {
    type: ActionTypes.AddressBook.Modification.CompanyAgingNotesLoaded,
    notes
  };

};

export default ActionBuilder
  .for(loadCompanyAgingNotes)
  .build();