import React from 'react';
import ComponentBuilder from '../../../core/ComponentBuilder';
import AppDialog from '../../../core/components/AppDialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Grid, Table, TableBody, TableCell, TableRow} from '@material-ui/core';
import AppCurrencyText from '../../../core/components/text/AppCurrencyText';
import AppTypography from '../../../core/components/AppTypography';
import AppAlertStatic from '../../../core/components/AppAlertStatic';

const CreditLink = ComponentBuilder.wrap(({ classes }) => (
    <a href={`mailto:credit@tallgrassfreight.com`} className={classes.link}>credit@tallgrassfreight.com</a>
  ))
  .withStyles((theme) => ({
    link: {
      textDecoration: 'none',
      color: theme.palette.tgfLink.main,
      fontWeight: 'bold',
      '&:hover': {
        textDecoration: 'underline'
      }
    }
  }))
  .build();

const SummarizeCheck = ({ analysis }) => {

  if (analysis.autoCreditAllowed) {

    const warnings = analysis.ruleSummaries
      .filter((rule) => rule.classification === 'warning')
      .map((rule) => rule.description);

    return (
      <div>
        <div>
          The customer has been approved for a credit limit increase. Click <i>Confirm</i> to complete the increase.
          If you have questions, email <CreditLink />.
        </div>
        {
          warnings.length > 0 &&
          <React.Fragment>
            <AppAlertStatic color={'warning'} style={{ marginTop: 12 }}>
              Warnings
            </AppAlertStatic>
            <ul>
              {
                warnings.map((warning, index) => (<li key={index}>{warning}</li>))
              }
            </ul>
          </React.Fragment>
        }
      </div>
    );
  }
  else {
    return (
      <div>
        At this time, you will need to email <CreditLink /> to request a credit limit increase for this customer.
      </div>
    );
  }
};

const LesserAmountGivenText = ({ value }) => (
  <div>
    At this time, we could only increase the credit limit for this customer by an
    additional <AppCurrencyText value={value} />.  You will need to
    email <CreditLink /> if you need a larger increase.
  </div>
);

const CreditLimitIncreasedSuccessText = ({ value }) => (
  <div>
    The credit limit for this customer has been increased to <AppCurrencyText value={value}/>.
    Please email <CreditLink /> if you have any questions.
  </div>
);

const PromptForCompletion = ({ analysis }) => {
  return (
    <Grid container>
      <Grid item>
        <SummarizeCheck analysis={analysis} />
      </Grid>
    </Grid>
  );
};

const SummarizeRequest = ({ completedRequest }) => {

  if (!completedRequest)
    return null;

  const difference = completedRequest.activeCreditLimit - completedRequest.expiredCreditLimit;

  const summaryText = difference < completedRequest.increment ?
    (
      <LesserAmountGivenText value={difference} />
    ) :
    (
      <CreditLimitIncreasedSuccessText value={completedRequest.activeCreditLimit} />
    )

  return (
    <Grid container>
      <Grid item>
        {summaryText}
      </Grid>
    </Grid>
  );
};

const SimpleRuleSummary = ({ analysis }) => {

  if (!analysis)
    return null;

  return (
    <Grid container justify={'center'} direction={'row'} spacing={2}>
      <Grid item>
        <Table>
          <TableBody>
            {
              analysis.ruleSummaries.map((rule, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <ClassificationText value={rule.classification} />
                  </TableCell>
                  <TableCell>
                    {rule.description}
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </Grid>
      <Grid item>
        <AppTypography variant={'h5'}>
          Customer can receive auto-credit increase: {analysis.autoCreditAllowed ? 'YES' : 'NO'}
        </AppTypography>
      </Grid>
    </Grid>
  );
};

const ClassificationText = ({ value }) => {
  return value.toUpperCase();
};

const SpinnerWithText = ComponentBuilder.wrap(({ classes, text }) => (
    <Grid container>
      <Grid container item justify={'center'} alignItems={'center'}>
        <div>
          <div className={classes.loadingSpinner}>
            <CircularProgress color={'primary'} size={40} />
          </div>
          <div className={classes.loadingText}>
            {text}
          </div>
        </div>
      </Grid>
    </Grid>
  ))
  .withStyles((theme) => ({
    loadingSpinner: {
      display: 'flex',
      justifyContent: 'center'
    },
    loadingText: {
      display: 'flex',
      justifyContent: 'center'
    }
  }))
  .build();

/*

New “Request Credit Increase” Button
- Aces do not see this button.
- Visible only for customer category type.
- Not visible on new customers.
- Disabled when customer has outstanding invoice 16+ days overdue.

Rules when clicked…
- Credit line will not be increased, and the user will be advised to contact the credit department via email if…
- They have already met their Auto-Credit Threshold limit.
- The customer’s available credit is $5k or more.
- The customer has any invoice showing 16 days or more past due.
- The customer is disabled.

Otherwise...
- the user will see a confirmation message about increasing their credit limit to X-amount.  If they confirm, the line is increased.
  X-amount will be determined by the “Auto-Credit Increase Increment".

 */

const WorkflowSteps = {
  CreditCheckLoading: Symbol('step-1'),
  CreditRequestPrompting: Symbol('step-2'),
  CreditRequestProcessing: Symbol('step-3'),
  CreditRequestComplete: Symbol('step-4'),
  CreditAnalysisDisplay: Symbol('step-2-alt'),
  Error: Symbol('step-error')
};

const RequestCreditIncreaseDialog = (props) => {

  const {
    companyId,
    summarizeOnly,
    onCancelClick,
    onCompleteClick,
    performCreditCheck,
    confirmCreditRequest
  } = props;

  const [workflowStep, setWorkflowStep] = React.useState(WorkflowSteps.CreditCheckLoading);
  const [analysis, setAnalysis] = React.useState(null);
  const [completedRequest, setCompletedRequest] = React.useState(null);

  React.useEffect(() => {
    performCreditCheck(companyId)
      .then((analysis) => {
        setAnalysis(analysis);
        setWorkflowStep(
          summarizeOnly ?
            WorkflowSteps.CreditAnalysisDisplay :
            WorkflowSteps.CreditRequestPrompting
        );
      })
      .catch((error) => {
        console.error(error);
        setWorkflowStep(WorkflowSteps.Error);
      });
  }, [companyId, performCreditCheck]);

  const handleCancelClick = () => {
    onCancelClick();
  };

  const handleConfirmClick = () => {
    setWorkflowStep(WorkflowSteps.CreditRequestProcessing);
    confirmCreditRequest(companyId)
      .then((requestResult) => {
        setCompletedRequest(requestResult);
        setWorkflowStep(WorkflowSteps.CreditRequestComplete);
      })
      .catch((error) => {
        console.error(error);
        setWorkflowStep(WorkflowSteps.Error);
      });
  };

  const actionButtons = [];

  if (workflowStep === WorkflowSteps.CreditCheckLoading || workflowStep === WorkflowSteps.CreditRequestProcessing) {
    actionButtons.push({
      title: '...',
      disabled: true
    });
  }
  else if (workflowStep === WorkflowSteps.CreditRequestPrompting && analysis?.autoCreditAllowed) {
    actionButtons.push({
      title: 'Cancel',
      action: handleCancelClick
    });
    actionButtons.push({
      title: 'Confirm',
      action: handleConfirmClick
    });
  }
  else if (workflowStep === WorkflowSteps.CreditRequestPrompting && analysis && !analysis.autoCreditAllowed) {
    actionButtons.push({
      title: 'Done',
      action: handleCancelClick
    });
  }
  else if (workflowStep === WorkflowSteps.CreditRequestComplete) {
    actionButtons.push({
      title: 'Done',
      action: onCompleteClick
    });
  }
  else if (workflowStep === WorkflowSteps.CreditAnalysisDisplay) {
    actionButtons.push({
      title: 'Done',
      action: handleCancelClick
    });
  }

  const title = summarizeOnly ?
    'Auto-Credit Increase Check' :
    'Credit Increase Request';

  return (
    <AppDialog
      title={title}
      width={'sm'}
      open={true}
      onClose={handleCancelClick}
      actionButtons={actionButtons}
    >
      <AppTypography variant={'h6'}>
        {
          workflowStep === WorkflowSteps.CreditCheckLoading &&
          <SpinnerWithText text={'Checking customer credit...'} />
        }
        {
          workflowStep === WorkflowSteps.CreditRequestPrompting &&
          <PromptForCompletion analysis={analysis} />
        }
        {
          workflowStep === WorkflowSteps.CreditRequestProcessing &&
          <SpinnerWithText text={'Process credit limit increase...'} />
        }
        {
          workflowStep === WorkflowSteps.CreditRequestComplete &&
          <SummarizeRequest completedRequest={completedRequest} />
        }
        {
          workflowStep === WorkflowSteps.CreditAnalysisDisplay &&
          <SimpleRuleSummary analysis={analysis} />
        }
      </AppTypography>
    </AppDialog>
  );

};

export default ComponentBuilder
  .wrap(RequestCreditIncreaseDialog)
  .dispatchToProps((shell, dispatch, ownProps) => {
    return {
      performCreditCheck: async (companyId) => {
        return shell.gateway.getCompanyAutoCreditCheck(companyId);
      },
      confirmCreditRequest: (companyId) => {
        return shell.gateway.performAutoCreditIncrease(companyId);
      }
    };
  })
  .build();