import ActionTypes from '../../../ActionTypes';
import ActionBuilder from '../../../ActionBuilder';

const dispose = (context) => {
  return {
    type: ActionTypes.Shipment.Modification.Disposed
  };
};

export default ActionBuilder
  .for(dispose)
  .build();