import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Core from '@atomos/core';
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import { FacebookProvider, Page } from 'react-facebook';
import { Link } from "react-router-dom";
import { Grid, IconButton, Link as Href } from "@material-ui/core";
import { LinkedIn } from '@material-ui/icons';
import NumberFormat from 'react-number-format';

import FullWidthLayout from "../../../core/layouts/FullWidthLayout";
import ComponentBuilder from "../../../core/ComponentBuilder";
import AppCard from '../../../core/components/cards/AppCard';
import AppIconButton from '../../../core/components/AppButton/AppIconButton';

import isAce from "../../../hubs/persona/selectors/isAce";
import isJack from "../../../hubs/persona/selectors/isJack";
import isProcessActive from '../../../hubs/sys/selectors/isProcessActive';
import MetricCard from './MetricCard';

import './HomePage.scss';

const useStyles = makeStyles(theme => ({
  linkedInContainer: {
    padding: '5px 5px 0 10px',
    '& .MuiIconButton-root': {
      padding: 0
    },
    '& .MuiIconButton-root:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'none'
    }
  },
  linkedIn: {
    paddingRight: 5,
    fontSize: 14,
    color: theme.palette.text.primary,
    fontWeight: 500,
    textDecoration: 'none'
  }
}));

const LoadProcessName = 'HomePage.Load';

const renderCurrencyText = (value) =>
  <NumberFormat
    displayType={'text'}
    thousandSeparator={','}
    decimalScale={2}
    fixedDecimalScale
    prefix={'$'}
    value={value}
  />;

const renderCountText = (value) =>
  <NumberFormat
    displayType={'text'}
    thousandSeparator={','}
    value={value}
  />;

const renderPercentageText = (value) => {
  if (!Core.Utils.isNumber(value)) {
    return null;
  }
  return (
    <>
      <NumberFormat
        displayType={'text'}
        decimalScale={1}
        fixedDecimalScale
        value={value * 100}
      />%
    </>
  );
};

const HomePage = (props) => {
  const classes = useStyles();
  const {
    announcement,
    trackedSetCounts,
    associateMetrics,
    tgfMetrics,
    isAce,
    isJack,
    load
  } = props;

  React.useEffect(() => {
    load()
  }, [load]);

  const markup = {
    __html: announcement
  };

  const reportBar = [
    {
      title: '# Shipments',
      metric: renderCountText(associateMetrics && associateMetrics.shipmentCount)
    },
    {
      title: 'Margin',
      metric: renderCurrencyText(associateMetrics && associateMetrics.totalMargin)
    },
    {
      title: 'Avg. Margin',
      metric: renderCurrencyText(associateMetrics && associateMetrics.averageMargin)
    },
    {
      title: 'Avg. Margin %',
      metric: renderPercentageText(associateMetrics && associateMetrics.averageMarginPercent)
    },
    {
      title: 'Avg. Revenue',
      metric: renderCurrencyText(associateMetrics && associateMetrics.averageRevenue)
    },
    {
      title: 'TGF Avg. Margin',
      metric: renderCurrencyText(tgfMetrics && tgfMetrics.averageMargin),
      isTgfMetric: true
    },
    {
      title: 'TGF Avg. Margin %',
      metric: renderPercentageText(tgfMetrics && tgfMetrics.averageMarginPercent),
      isTgfMetric: true
    },
    {
      title: 'TGF Avg. Revenue',
      metric: renderCurrencyText(tgfMetrics && tgfMetrics.averageRevenue),
      isTgfMetric: true
    }
  ];

  const LinkedInAction = () => <div className={classes.linkedInContainer}>
  <IconButton
    component={Href}
    href={"https://www.linkedin.com/company/tallgrass-freight-company/posts"}
    target={"_blank"}
    disableFocusRipple
  >
    <span className={classes.linkedIn}>
      Click here for LinkedIn:
    </span>
    <LinkedIn color="primary" />
  </IconButton>
</div>;


  const hideSocialFeeds = Boolean(localStorage.getItem('tgf-crm-disable-social-media'));

  return (
    <FullWidthLayout>
      <Grid container justify={'center'} spacing={2}>
        {
          !isJack &&
          <Grid container justify={'center'} item xs={12} spacing={1}>
            {
              reportBar.map((item, index) =>
                <Grid item key={index}>
                  <MetricCard
                    title={item.title}
                    metric={item.metric}
                    isTgfMetric={item.isTgfMetric}
                  />
                </Grid>
              )
            }
          </Grid>
        }
        <Grid container spacing={1} justify="center">
          <Grid item xs={12} sm={4}>
            <Grid container spacing={1} justify="center" direction={"column"}>
              <Grid item>
                <AppCard title="Announcements"
                  action={isAce && <Link to={'/admin/home-settings'} className="float-right btn btn-sm btn-outline-primary" style={{ marginLeft: 'Auto' }}>
                    <i className="k-icon k-i-change-manually"></i>
                  </Link>}
                >
                  <div className={'announcement-text'} dangerouslySetInnerHTML={markup}></div>
                </AppCard>
              </Grid>
              <Grid item>
                <AppCard title="Shipments">
                  <Link to={'shipment/listing/delivered'}>
                    {trackedSetCounts.delivered} shipments ready to be locked.
              </Link>
                </AppCard>
              </Grid>
            </Grid>
          </Grid>
          {
            !hideSocialFeeds &&
            <Grid item xs={12} md={4}>
              <AppCard title="Twitter"
                action={<LinkedInAction />}
              >
                <Grid container spacing={1} direction="column">
                  <Grid item>
                    <TwitterTimelineEmbed
                      sourceType="profile"
                      screenName="tallgrassfrt"
                      options={{ height: '65vh', fontSize: '.85em' }}
                    />
                  </Grid>
                </Grid>
              </AppCard>
            </Grid>
          }
          {
            !hideSocialFeeds &&
            <Grid item xs={12} md={4}>
              <AppCard title="Facebook">
                <FacebookProvider appId="825865087935123">
                  <Page href="http://www.facebook.com/tallgrassFreightCompany" tabs="timeline" width="450" height="600" />
                </FacebookProvider>
              </AppCard>
            </Grid>
          }
        </Grid>
      </Grid>
    </FullWidthLayout>
  )
};

export default ComponentBuilder
  .wrap(HomePage)
  .stateToProps((state, ownProps) => {
    return {
      associateMetrics: state.persona.associateMetrics,
      tgfMetrics: state.persona.tgfMetrics,
      announcement: state.persona.announcementNote,
      trackedSetCounts: state.shipment.trackedSetCounts,
      isAce: isAce(state),
      isJack: isJack(state),
    };
  })
  .dispatchToProps((shell, dispatch, getState) => {
    return {
      async load() {
        dispatch(shell.actions.sys.processStart(LoadProcessName));
        const actions = await Promise.all([
          shell.actions.persona.loadAssociateMonthlyMetrics(),
          shell.actions.persona.loadTgfMonthlyMetrics(),
          shell.actions.persona.loadAnnouncementNote(),
          shell.actions.shipment.loadTrackedSetCounts()
        ]);
        actions.forEach(dispatch);
        dispatch(shell.actions.sys.processComplete(LoadProcessName))
      }
    };
  })
  .build();
