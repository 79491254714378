import React from 'react';
import ComponentBuilder from '../../core/ComponentBuilder';
import AppDropdown from '../../core/components/dropdowns/AppDropdown/AppDropdown';

const CrmCollectionAssociateDropDown = (props) => {

  const {
    useUnassignedOption = false,
    disableClearable = false,
    onChange,
    getCollectionAssociates,
    staticContext, // Pluck annoying property to allow others to pass on.
    ...otherProps
  } = props;

  const [associates, setAssociates] = React.useState([]);

  React.useEffect(() => {
    let mounted = true;

    getCollectionAssociates()
      .then((associates) => {
        if (mounted)
          setAssociates(associates);
      });

    return () => mounted = false;
  }, []);

  const handleOnChange = (e, value) => {
    onChange(value);
  };

  const associateOptions = useUnassignedOption ?
    [{ id: null, fullName: 'Unassigned' }].concat(associates) : associates;

  return (
    <AppDropdown
      field={'fullName'}
      fieldIndex={'id'}
      disableClearable={disableClearable}
      options={associateOptions}
      onChange={handleOnChange}
      {...otherProps}
    />
  )
};

export default ComponentBuilder
  .wrap(CrmCollectionAssociateDropDown)
  .dispatchToProps((shell, dispatch, ownProps) => {
    return {
      async getCollectionAssociates() {

        const searchOptions = {
          filter: {
            collectionRole: true
          },
          offset: 0,
          sort: [['lastName', 'asc']]
        };

        const { associates } = await shell.gateway.getAllAssociates(searchOptions);

        return associates;

      }
    }
  })
  .build();