import React from 'react';
import AppDialog from '../../../../core/components/AppDialog';
import ComponentBuilder from '../../../../core/ComponentBuilder';
import {Grid} from '@material-ui/core';
import AppInput from '../../../../core/components/inputs/AppInput';
import AppMuiNumericInput from '../../../../core/components/inputs/AppNumericInput';
import AppMuiSwitch from '../../../../core/components/inputs/AppSwitch';

const ModifyFinancialsDialog = (props) => {

  const {
    autoCreditIncreaseThreshold,
    autoCreditIncreaseIncrement,
    dunBradstreetScore,
    paydexScore,
    paydexAverageDaysToPay,
    creditRiskIndicator,
    allowMarketingEngagement,
    allowInvoiceBackdating,
    onSaveClick,
    onCancelClick
  } = props;

  const [threshold, setThreshold] = React.useState(autoCreditIncreaseThreshold);
  const [increment, setIncrement] = React.useState(autoCreditIncreaseIncrement);
  const [dbScore, setDbScore] = React.useState(dunBradstreetScore);
  const [pdScore, setPdScore] = React.useState(paydexScore);
  const [pdAverageDaysToPay, setPdAverageDaysToPay] = React.useState(paydexAverageDaysToPay);
  const [crIndicator, setCrIndicator] = React.useState(typeof(creditRiskIndicator) === 'boolean' ? creditRiskIndicator : false);
  const [allowMarketing, setAllowMarketing] = React.useState(typeof(allowMarketingEngagement) === 'boolean' ? allowMarketingEngagement : true);
  const [allowInvBackdating, setAllowInvBackdating] = React.useState(typeof(allowInvoiceBackdating) === 'boolean' ? allowInvoiceBackdating : true);

  const actions = [
    {
      title: 'Cancel',
      action: () => {
        onCancelClick()
      }
    },
    {
      title: 'Save',
      action: () => {
        onSaveClick({
          autoCreditIncreaseThreshold: threshold,
          autoCreditIncreaseIncrement: increment,
          dunBradstreetScore: dbScore,
          paydexScore: pdScore,
          paydexAverageDaysToPay: pdAverageDaysToPay,
          creditRiskIndicator: crIndicator,
          allowMarketingEngagement: allowMarketing,
          allowInvoiceBackdating: allowInvBackdating
        });
      }
    }
  ];

  const handleAutoCreditIncreaseThreshold = (e) => {
    setThreshold(e.target.value ? Number(e.target.value) : null);
  };
  const handleAutoCreditIncreaseIncrement = (e) => {
    setIncrement(e.target.value ? Number(e.target.value) : null);
  };
  const handleDbScoreChange = (e) => {
    setDbScore(e.target.value || null);
  };
  const handlePdScoreChange = (e) => {
    setPdScore(e.target.value || null);
  };
  const handlePdAverageDaysToPayChange = (e) => {
    setPdAverageDaysToPay(e.target.value || null);
  };
  const handleCrIndicatorChange = (e) => {
    setCrIndicator(e.target.checked);
  };
  const handleAllowMarketingChange = (e) => {
    setAllowMarketing(e.target.checked);
  };
  const handleAllowInvBackdatingChange = (e) => {
    setAllowInvBackdating(e.target.checked);
  };

  return (
    <AppDialog title={'Modify Financials'} actionButtons={actions} open={true} width={'sm'} onClose={() => onCancelClick()}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={4}>
          <AppMuiNumericInput
            label={'Auto Credit Threshold'}
            value={threshold}
            onChange={handleAutoCreditIncreaseThreshold}
          />
        </Grid>
        <Grid container item xs={12} md={4}>
          <AppMuiNumericInput
            label={'Auto Credit Increment'}
            value={increment}
            onChange={handleAutoCreditIncreaseIncrement}
          />
        </Grid>
        <Grid item xs={12} md={4}></Grid>
        <Grid item xs={12} md={4}>
          <AppInput label={'D&B Score'} value={dbScore || ''} onChange={handleDbScoreChange} />
        </Grid>
        <Grid item xs={12} md={4}>
          <AppInput label={'Paydex Score'} value={pdScore || ''} onChange={handlePdScoreChange} />
        </Grid>
        <Grid item xs={12} md={4}>
          <AppInput label={'Paydex Avg. Days to Pay'} value={pdAverageDaysToPay || ''} onChange={handlePdAverageDaysToPayChange} />
        </Grid>
        <Grid item xs={12} md={4}>
          <AppMuiSwitch label={'Marked as Bad Debt'} checked={crIndicator} onChange={handleCrIndicatorChange} />
        </Grid>
        <Grid item xs={12} md={4}>
          <AppMuiSwitch label={'Allow Marketing Engagement'} checked={allowMarketing} onChange={handleAllowMarketingChange} />
        </Grid>
        <Grid item xs={12} md={4}>
          <AppMuiSwitch label={'Allow Invoice Backdating'} checked={allowInvBackdating} onChange={handleAllowInvBackdatingChange} />
        </Grid>
      </Grid>
    </AppDialog>
  );
};

export default ComponentBuilder
  .wrap(ModifyFinancialsDialog)
  .props({

  })
  .build();