import React from 'react';
import Core from '@atomos/core';
import { Grid } from '@material-ui/core';

import AppSearch from '../../../core/components/inputs/AppSearch';
import ComponentBuilder from '../../../core/ComponentBuilder';
import FullWidthLayout from '../../../core/layouts/FullWidthLayout';
import isAce from '../../../hubs/persona/selectors/isAce';
import ListingButtonGroup from './ListingButtonGroup.js';
import AddressBookListingTable from './includes/AddressBookListingTable';

const DisabledRowClassName = 'bg-warning';
const LoadProcessName = 'ListingPage.Load';

const AllCategoryType = {
  id: '',
  name: 'All',
  requiresAdmin: false
};

const ListingPage = (props) => {
  const {
    match,
    isAce,
    categoryTypes,
    companies,
    companyCount,
    load,
    dispose
  } = props;

  const [offset, setOffset] = React.useState(0);
  const [limit, setLimit] = React.useState(20);
  const [sort, setSort] = React.useState([['companyName', 'asc']]);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('companyName');

  // Determine if the user is filtering by a certain category type.
  const activeCategoryTypeId = match.params.categoryTypeId && parseInt(match.params.categoryTypeId);

  const securedCategoryTypes = categoryTypes
    .filter(t => isAce || !t.requiresAdmin);

  const filteredCategoryTypeIds = securedCategoryTypes
    .filter(t => !activeCategoryTypeId || t.id === activeCategoryTypeId)
    .map(t => t.id);

  React.useEffect(() => () => dispose(), []);

  // Register a mount/update hook to load companies
  // when the context is ready.
  React.useEffect(() => {
    load(searchTerm, filteredCategoryTypeIds, offset, limit, sort);
  }, [activeCategoryTypeId, categoryTypes, offset, limit, sort, searchTerm]);

  const allCategoryTypes = [AllCategoryType]
    .concat(securedCategoryTypes)
    // Attach an isActive flag to make it easier for the
    //ListingButtonGroup to highlight the links.
    .map(t => Object.assign({}, t, {
      isActive: t.id === activeCategoryTypeId ||
        (!t.id && !activeCategoryTypeId)
    }));

  //Handles when the user changes pages within the table.
  const handlePageChange = (e, page) => {
    setOffset(page * limit);
  };

    // Handles when the user clicks on column headers for sorting.
    const handleSortChange = (column) => {
      const changeOrder = (order === 'asc' && sort[0][0] === column) ? 'desc' : 'asc';

      setSort([[column, changeOrder]]);
      setOrder(changeOrder);
      setOrderBy(column);
    };

    // Debounce the call to load.
    const searchCompanies = Core.Utils.debounce((searchTerm) => {
      setSearchTerm(searchTerm);
      setOffset(0);
    }, 300);

    // Event handler for calling the debounced load.
    const handleSearchTermChange = e => searchCompanies(e.target.value);
    const handleChangeRowsPerPage = (e) => {
      setOffset(0);
      setLimit(e.target.value);
    };

    return (
      <FullWidthLayout title={'Address Book - Companies'}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={10}>
            <ListingButtonGroup links={convertCategoryTypes(allCategoryTypes, setOffset)} />
          </Grid>
          <Grid item xs={12} md={2}>
            <AppSearch onChange={handleSearchTermChange} />
          </Grid>
          <Grid item xs={12}>
            <AddressBookListingTable
              count={companyCount}
              data={companies}
              orderBy={orderBy}
              order={order}
              rowsPerPage={limit}
              page={offset / limit}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              onSort={handleSortChange}
            />
          </Grid>
        </Grid>
      </FullWidthLayout>
    );
  };

  const highlightDisabledRows = (trElement, props) => {
    const abListing = props.dataItem;
    const isDisabled = abListing.companyIsDisabled;

    const newRowProps = {};

    if (isDisabled) {
      newRowProps.className = trElement.props.className ?
        trElement.props.className + ` ${DisabledRowClassName}` :
        DisabledRowClassName;
    }

    return React.cloneElement(trElement, newRowProps, trElement.props.children);
  };

  const convertCategoryTypes = (categoryTypes, setOffSet) => {
    return categoryTypes
      .map(t => ({
        url: `/address-book/listing/${t.id}`,
        title: t.name,
        isActive: t.isActive,
        onClick: () => setOffSet(0)
      }));
  };

  export default ComponentBuilder
    .wrap(ListingPage)
    .stateToProps((state, ownProps) => {
      return {
        categoryTypes: state.support.companyCategoryTypes,
        companies: state.addressBook.listing.companies,
        companyCount: state.addressBook.listing.companyCount,
        isAce: isAce(state)
      };
    })
    .dispatchToProps((shell, dispatch, getState) => {
      return {
        load: async (searchTerm, categoryTypes, offset, limit, sort) => {
          dispatch(shell.actions.sys.processStart(LoadProcessName));
          dispatch(await shell.actions.addressBook.listing.load(searchTerm, categoryTypes, offset, limit, sort));
          dispatch(shell.actions.sys.processComplete(LoadProcessName));
        },
        dispose: () =>
          dispatch(shell.actions.addressBook.listing.dispose())
      };
    })
    .build();