import React from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';

const AppMuiCheckbox = ({ name, label, value, color, handleChange, checked, ...otherProps }) =>
    <FormControlLabel
        value={value}
        control={
            <Checkbox
                name={name} 
                checked={checked}
                onChange={handleChange}
                color={color}
            />}
        label={label}
        {...otherProps}
    />

export default AppMuiCheckbox;