import Core from '@atomos/core';
import ActionBuilder from '../../../ActionBuilder';
import ActionTypes from '../../../ActionTypes';
import VisitationStore from './VisitationStore';

const MaxVisitationCount = 100;

const addRecentVisit = (context, visitationType, visitationData) => {

  const visitation = {
    timestamp: new Date(),
    visitationType,
    visitationData
  };

  const visitStore = new VisitationStore();

  // Load visitations.
  const visitations = visitStore.getVisitations();

  // Update the list, prepending the new visitation.
  visitStore.saveVisitations(Core.Utils.take([visitation].concat(visitations), MaxVisitationCount));

  return {
    type: ActionTypes.Persona.Mrv.RecentlyVisited,
    visitation
  };
};

export default ActionBuilder
  .for(addRecentVisit)
  .build();