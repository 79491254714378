import ActionBuilder from '../../ActionBuilder';
import ProcessTypes from '../../ProcessTypes';

/**
 * Compound action to initialize elements of the shipments hub.
 */
const init = async ({ shell }) => {
  return [
    shell.actions.shipment.equipment.load(),
    shell.actions.shipment.freight.load(),
    shell.actions.shipment.loadTracking.load(),
    shell.actions.shipment.statusing.load()
  ];
};

export default ActionBuilder
  .for(init)
  .process(ProcessTypes.Shipment.Initializing)
  .build();