import React from 'react';

import AppMUITable from '../../../../core/components/AppDenseGrid/AppMUITable';
import Core from "@atomos/core";
import {Link} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  tdStyles: {
    textDecoration: 'none',
    color: theme.palette.tgfLink.main,
    fontWeight: 'bold',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}));

const AllCarriersListing = (props) => {
  const {
    count,
    data,
    orderBy,
    order,
    rowsPerPage,
    page,
    onChangePage,
    onChangeRowsPerPage,
    onSort,
  } = props;

  const classes = useStyles();

  const booleanToWord = (data) => Boolean(data) ? 'Yes' : 'No';
  const buildLink = (path, title) => <Link to={path} className={classes.tdStyles}>{title}</Link>;

  const GridColumns = [
    {
      title: 'Carrier Name',
      field: 'carrierName',
      styles: {
        width: 70,
      },
      dataType: 'string'
    },
    {
      title: 'City',
      field: 'carrierCity',
      styles: {
        width: 50,
      },
      dataType: 'string'
    },
    {
      title: 'ST',
      field: 'carrierState',
      styles: {
        width: 43,
      },
      dataType: 'string'
    },
    {
      title: 'Zip',
      field: 'carrierPostalCode',
      styles: {
        width: 50,
      },
      dataType: 'string'
    },
    {
      title: 'MC #',
      field: 'carrierMcNumber',
      styles: {
        width: 70,
      },
      dataType: 'component',
      component: ({rowData}) => buildLink(`/carrier/${rowData.carrierMcNumber}/compliance`, rowData.carrierMcNumber),
    },
    {
      title: 'DOT #',
      field: 'carrierDotNumber',
      styles: {
        width: 60,
      },
      dataType: 'string'
    },
    {
      title: 'Type',
      field: 'freightCategoryName',
      styles: {
        width: 50,
      },
      dataType: 'string'
    },
    {
      title: 'Cargo Amt',
      field: 'rmisInsuranceCoverageLimitCargoAmount',
      styles: {
        width: 70,
      },
      dataType: 'currency',
    },
    {
      title: 'Cargo Exp',
      field: 'rmisInsuranceCoverageCargoExpirationDate',
      styles: {
        width: 80,
      },
      dataType: 'date',
    },
    {
      title: 'Auto Amt',
      field: 'rmisInsuranceCoverageLimitAutoAmount',
      styles: {
        width: 70,
      },
      dataType: 'currency',
    },
    {
      title: 'Auto Exp',
      field: 'rmisInsuranceCoverageAutoExpirationDate',
      styles: {
        width: 80,
      },
      dataType: 'date'
    },
    {
      title: 'Good to Go',
      field: 'carrierIsGoodToGo',
      styles: {
        width: 50,
      },
      dataType: 'component',
      component: ({rowData}) => booleanToWord(rowData.carrierIsGoodToGo)
    },
    {
      title: 'RMIS Comp',
      field: 'rmisCarrierIsCertified',
      styles: {
        width: 50,
      },
      dataType: 'component',
      component: ({rowData}) => booleanToWord(rowData.rmisCarrierIsCertified)
    },
    {
      title: 'RMIS Mntrd',
      field: 'carrierIsMonitored',
      styles: {
        width: 50,
      },
      dataType: 'component',
      component: ({rowData}) => booleanToWord(rowData.carrierIsMonitored)
    },
    {
      title: 'Black listed',
      field: 'carrierIsBlackListed',
      styles: {
        width: 50,
      },
      dataType: 'component',
      component: ({rowData}) => booleanToWord(rowData.carrierIsBlackListed)
    },
    {
      title: 'Entry Date',
      field: 'carrierHireDate',
      styles: {
        width: 80,
      },
      dataType: 'date'
    },
    {
      title: 'Last RMIS Update',
      field: 'carrierPacketReceivedDate',
      styles: {
        width: 75,
      },
      dataType: 'date'
    },
  ];

  return (
    <AppMUITable
      count={count}
      data={data}
      orderBy={orderBy}
      order={order}
      rowsPerPage={rowsPerPage}
      page={page}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      onSort={onSort}
      columns={GridColumns}
    />
  )
};

export default AllCarriersListing;