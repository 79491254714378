import ActionTypes from '../../../ActionTypes';

const ltlEquipmentTypes = (state = null, action) => {
  switch (action.type) {
    case ActionTypes.Shipment.Equipment.Loaded:
      return action.ltlEquipmentTypes;
    default:
      return state;
  }
};

export default ltlEquipmentTypes;