import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';
import GatewayFacade from '../../../GatewayFacade';

const loadCompanyNotes = async (shell, companyId) => {

  const gateway = new GatewayFacade(shell.communicator);

  const notes = await gateway.getCompanyNotes(companyId);

  return {
    type: ActionTypes.AddressBook.Modification.CompanyNotesLoaded,
    notes
  };

};

export default ActionBuilder
  .for(loadCompanyNotes)
  .build();