import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Input, FormControl, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    search: {
      ...theme.tgfStyles.search
    },
  }
  ));
  
const AppSearch = ({ placeHolder="Search…", ...otherProps }) => {
    const classes = useStyles();

    return (
        <FormControl>
            <Input
                {...otherProps}
                placeholder={placeHolder}
                disableUnderline
                className={classes.search}
                endAdornment={<InputAdornment position="end"><SearchIcon /></InputAdornment>}
            />
        </FormControl>
    )
}
export default AppSearch;