import ActionTypes from '../../../ActionTypes';

const nmfc = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.Shipment.Freight.Loaded:
      return action.nmfc;
    default:
      return state;
  }
};

export default nmfc;