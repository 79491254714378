import React from 'react';
import MaskedInput from 'react-text-mask';
import { makeStyles, withStyles, fade } from '@material-ui/core/styles';
import { Input, InputLabel, FormControl } from '@material-ui/core';

const StyledTextField = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: 18,
    },
  },
  input: {
    ...theme.tgfStyles.inputField
  },
  disabled: {
    backgroundColor: theme.palette.tgfGrey.disabled
  }
}))(Input);

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  }
}));

const getMask = (value) => {
  if (value) {
    return ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  }
  return []
}

function TextMaskCustom(props) {
  const classes = useStyles();
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={value => getMask(value)}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

const AppMuiMaskedInput = ({ label, maskType = 'phone', ...otherProps }) => {
  const classes = useStyles();

  return (
    <FormControl>
      {label && <InputLabel shrink htmlFor={otherProps.id}>
        {label}
      </InputLabel>}
      <StyledTextField
        disableUnderline={true}
        inputComponent={TextMaskCustom}
        {...otherProps}
      />
    </FormControl>
  );
}

export default AppMuiMaskedInput;