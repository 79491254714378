import * as Redux from 'redux';

import createDocs from './createDocs';
import customer from './customer';
import duplication from './duplication';
import equipment from './equipment';
import freight from './freight';
import loadBoard from './loadBoard';
import loadTracking from './loadTracking';
import openLoad from './openLoad';
import modification from './modification';
import statusing from './statusing';
import trackedSetCounts from './trackedSetCounts';

export default Redux.combineReducers({
  createDocs,
  customer,
  duplication,
  equipment,
  freight,
  loadBoard,
  loadTracking,
  modification,
  openLoad,
  statusing,
  trackedSetCounts
});