import React from 'react';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import {CheckCircle, Functions} from '@material-ui/icons';

import AppCard from '../../../../core/components/cards/AppCard';
import AppTooltip from '../../../../core/components/AppTooltip';

const useStyles = makeStyles((theme) => ({
  highlight: {
    verticalAlign: 'super',
    color: 'red'
  },
  formulaText: {
    fontSize: '1.15em'
  },
  highlightNote: {
    fontSize: 14,
    color: 'red',
  },
  greenMark: {
    color: theme.palette.tgfGreen.main,
    fontWeight: 'bold'
  },
  blueMark: {
    color: theme.palette.tgfBlue.main,
    fontWeight: 'bold'
  },
  blackMark: {
    color: theme.palette.tgfBlack.main,
    fontWeight: 'bold'
  }
}));

const LegendCheckMark = (props) => {
  const classes = useStyles();

  return (
    <AppCard title="Legend Check Marks">
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={12}>
          <Grid container spacing={1} direction="column">
            <Grid item>
              <CheckCircle className={classes.greenMark}/> <span
              className={classes.greenMark}>Green Payment -</span> Shipments that were locked the 26th of the previous
              month through the 10th of this month.
            </Grid>
            <Grid item>
              <CheckCircle className={classes.blueMark}/> <span className={classes.blueMark}>Blue Payment -</span> Shipments
              that were locked on the 11th through the 25th of this month.
            </Grid>
            <Grid item>
              <CheckCircle className={classes.blackMark}/> <span
              className={classes.blackMark}>Black Payment -</span> Shipments
              that were paid past this month's pay periods. Refer to locked date for specific pay period.
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AppCard>
  )
}
export default LegendCheckMark;