import Core from '@atomos/core';
import ActionBuilder from '../../../ActionBuilder';
import ProcessTypes from '../../../ProcessTypes';
import StatusTypeNames from '../../StatusTypeNames';
import ActionTypes from '../../../ActionTypes';

const DefaultStatusName = StatusTypeNames.New;

/**
 * Predicate to filter to only statuses that
 * have a bolHistoryOrder set.
 * @param {object} s - A ShipmentStatus model.
 * @returns {boolean}
 */
const statusFilter = s =>
  Core.Utils.isNumber(s.bolHistoryOrder);

/**
 * Loads data for the statusing area of shipments.
 */
const load = async ({ shell }) => {

  const statusTypes = await shell.communicator.get(`/shipment-status`);

  const defaultStatusType = statusTypes
    .find(st => st.name === DefaultStatusName);

  if (!defaultStatusType) {
    throw new Error(`Default status type [${DefaultStatusName}] could not be found in status received from the gateway.`);
  }

  const deliveredStatusType = statusTypes
    .find(st => st.name === StatusTypeNames.Delivered);

  if (!deliveredStatusType) {
    throw new Error(`Cannot find delivered status type with name [${StatusTypeNames.Delivered}].`);
  }

  // Limit and sort the statuses by the order
  // they are processed in a BoL's history.
  const readiedStatusTypes = Core.Utils
    .sortBy(statusTypes.filter(statusFilter), 'bolHistoryOrder');

  return {
    type: ActionTypes.Shipment.Statusing.Loaded,
    statusTypes: readiedStatusTypes,
    defaultStatusType,
    deliveredStatusType
  };

};

export default ActionBuilder
  .for(load)
  .process(ProcessTypes.Shipment.Statusing.Loading)
  .build();