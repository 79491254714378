import ActionTypes from '../../../ActionTypes';

const statusTypes = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.Shipment.Statusing.Loaded:
      return action.statusTypes;
    default:
      return state;
  }
};

export default statusTypes;