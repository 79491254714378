import ActionBuilder from '../../../ActionBuilder';
import ProcessTypes from '../../../ProcessTypes';
import ActionTypes from '../../../ActionTypes';
import GatewayFacade from "../../../GatewayFacade";

const loadTrackingPhases = async ({ shell }) => {

  const gateway = new GatewayFacade(shell.communicator);
  const loadTrackingPhases = await gateway.getLoadTrackingPhases();

  return {
    type: ActionTypes.Shipment.LoadTrackingPhases.Loaded,
    loadTrackingPhases
  };

};

export default ActionBuilder
  .for(loadTrackingPhases)
  .process(ProcessTypes.Shipment.LoadTrackingPhases.Loading)
  .build();