import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';
import GatewayFacade from '../../../GatewayFacade';

const saveCompany = async (shell, company) => {

  const gateway = new GatewayFacade(shell.communicator);

  const updatedCompany = await gateway.saveCompany(company);

  return {
    type: ActionTypes.AddressBook.Modification.CompanySaved,
    company: updatedCompany
  };

};

export default ActionBuilder
  .for(saveCompany)
  .build();