import ActionBuilder from "../../../ActionBuilder";
import ProcessTypes from "../../../ProcessTypes";
import ActionTypes from "../../../ActionTypes";
import GatewayFacade from "../../../GatewayFacade";

const saveShipmentInvoice = async ({shell}, invoice) => {

  const gateway = new GatewayFacade(shell.communicator);

  const updateInvoice = await gateway.saveInvoice(invoice);

  return {
    type: ActionTypes.Shipment.Modification.InvoiceSaved,
    invoice: updateInvoice
  };

};

export default ActionBuilder
  .for(saveShipmentInvoice)
  .process(ProcessTypes.Shipment.Modification.InvoiceSaving)
  .build();