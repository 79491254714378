import React from 'react';
import * as Yup from 'yup';
import AppDialog from '../../../../core/components/AppDialog/AppMuiDialog';
import FasterFormFactor from '../../../../core/FormFactor/FasterFormFactor';
import FormFactorSlice from '../../../../core/FormFactor/FormFactorSlice';
import {Grid} from '@material-ui/core';
import AppDropdown from '../../../../core/components/dropdowns/AppDropdown';
import AppInput from '../../../../core/components/inputs/AppInput/AppMuiInput';
import FormFactorValidationFailure from '../../../../core/FormFactor/FormFactorValidationFailure';

const NewNoteDialog = (props) => {

  const {
    noteTypes,
    onSaveClick,
    onCancelClick
  } = props;

  const formProps = {
    initialValues: {
      selectedType: null,
      content: null
    },
    supportingValues: {
      noteTypes,
      onCancelClick
    },
    schema: Yup.object().shape({
      selectedType: Yup
        .object()
        .nullable()
        .required('Category is required.'),
      content: Yup
        .string()
        .nullable()
        .required('Note content is required.')
    }),
    onSubmit: (values) => {
      onSaveClick(values.selectedType, values.content);
    }
  };

  return (
    <FasterFormFactor {...formProps}>
      <FormFactorSlice
        slices={[
          'values',
          'supportingValues',
          'isDirty',
          'isValid',
          'setFieldValue',
          'submitForm'
        ]}>
        {([values, supportingValues, isDirty, isValid, setFieldValue, submitForm]) => {

          const actions = [
            {
              title: 'Cancel',
              action: supportingValues.onCancelClick
            },
            {
              title: 'Save',
              action: submitForm,
              disabled: !(isDirty && isValid)
            }
          ];

          const handleTypeChange = (e, noteType) => {
            setFieldValue('selectedType', noteType);
          };

          const handleContentChange = (e) => {
            setFieldValue('content', e.target.value);
          };

          return (
            <AppDialog
              title={'New Note'}
              open={true}
              onClose={onCancelClick}
              actionButtons={actions}
              width={'sm'}
            >
              <Grid container direction={'column'} spacing={1}>
                <Grid item>
                  <AppDropdown
                    id={'selectedType'}
                    label={'Category'}
                    field={'name'}
                    fieldIndex={'id'}
                    value={values.selectedType}
                    options={supportingValues.noteTypes}
                    onChange={handleTypeChange}
                  />
                  <FormFactorValidationFailure field={'selectedType'} />
                </Grid>
                <Grid item>
                  <AppInput
                    id={'content'}
                    label={'Content'}
                    rows={'5'}
                    cols={'45'}
                    multiline={true}
                    value={values.content || ''}
                    onChange={handleContentChange}
                    inputProps={{ maxLength: 1000 }}
                  />
                  <FormFactorValidationFailure field={'content'} />
                </Grid>
              </Grid>
            </AppDialog>
          );

        }}
      </FormFactorSlice>
    </FasterFormFactor>
  );

};

export default NewNoteDialog;