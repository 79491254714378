import React from 'react';
import { Grid } from '@material-ui/core';

import ComponentBuilder from '../../../core/ComponentBuilder';
import isProcessActive from '../../../hubs/sys/selectors/isProcessActive';

import FullWidthLayout from '../../../core/layouts/FullWidthLayout';
import AppAlert from '../../../core/components/AppAlertStatic';
import ReportNav from '../ReportNav';

import MissingPrimaryContactsListing from './includes/MissingPrimaryContactsListing';

const LoadProcessName = 'Reporting.PrimaryContacts.Loaded';

const PrimaryContacts = (props) => {

  const {
    companies,
    companyCount,
    load,
    dispose
  } = props;

  const [offset, setOffset] = React.useState(0);
  const [limit, setLimit] = React.useState(20);
  const [sort, setSort] = React.useState([['companyName', 'asc']]);

  React.useEffect(() => () => dispose(), []);

  React.useEffect(() => {
      load(offset, limit, sort);
  }, [offset, limit, sort]);

  const handleSortChange = (column) => {
    const [[sortColumn, sortDir]] = sort;
    const changeOrder = (sortDir === 'asc' && sortColumn === column) ? 'desc' : 'asc';
    setSort([[column, changeOrder]]);
  };

  const handlePageChange = (e, page) => {
    setOffset(page * limit);
  };

  const handleChangeRowsPerPage = (e) => {
    setOffset(0);
    setLimit(e.target.value);
  };

  return (
    <FullWidthLayout 
    SideNav={ReportNav} 
    title=" Companies Missing Primary Contacts"
   >
      <Grid container spacing={2} direction="column">
        <Grid item>
          <AppAlert color="error">
            The active companies below are missing a primary contact. These companies will not show
            as options on shipments until a primary contact has been selected.
          </AppAlert>
        </Grid>
        <Grid item>
          <MissingPrimaryContactsListing
            count={companyCount}
            data={companies}
            orderBy={sort[0][0]}
            order={sort[0][1]}
            rowsPerPage={limit}
            page={offset / limit}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            onSort={handleSortChange}
          />
        </Grid>
      </Grid>
    </FullWidthLayout>
  )
};

export default ComponentBuilder
  .wrap(PrimaryContacts)
  .stateToProps((state, ownProps) => ({
    companyCount: state.reporting.missingPrimaryContacts.count,
    companies: state.reporting.missingPrimaryContacts.listing
  }))
  .dispatchToProps((shell, dispatch, getState) => {
    return {
      async load(offset, limit, sort) {
        dispatch(shell.actions.sys.processStart(LoadProcessName));
        dispatch(await shell.actions.reporting.missingPrimaryContacts.load(offset, limit, sort));
        dispatch(shell.actions.sys.processComplete(LoadProcessName));
      },
      async dispose() {
        dispatch(await shell.actions.reporting.missingPrimaryContacts.dispose())
      }
    };
  })
  .build();