import * as  Yup from 'yup';
import React, { useState, useEffect } from 'react';
import { Add } from '@material-ui/icons';
import { Grid } from '@material-ui/core';
import Core from '@atomos/core';
import Business from '@tgf-crm/business';

import FullWidthLayout from '../../../core/layouts/FullWidthLayout';
import AddressBookRecordNav from '../AddressBookRecordNav';
import ComponentBuilder from "../../../core/ComponentBuilder";
import FormFactor from '../../../core/FormFactor/FormFactor';
import isNewCompany from '../../../hubs/addressBook/actions/modification/isNewCompany';
import selectCustomerShipmentsSearch from "../../../hubs/shipment/selectors/selectCustomerShipmentsSearch";

import AppButton from '../../../core/components/AppButton';
import AppDialog from '../../../core/components/AppDialog/AppMuiDialog';
import AppValidationFailure from '../../../core/components/AppValidationFailure';
import AppInput from '../../../core/components/inputs/AppInput/AppMuiInput';
import AppMuiSimpleTable from '../../../core/components/AppTable/AppMuiSimpleTable';
import AgingShipmentListing from './includes/AgingShipmentListing';
import composePageTitle from '../../../core/utils/composePageTitle';
import AppDateTimeText from '../../../core/components/text/AppDateTimeText';
import AppMuiCard from '../../../core/components/cards/AppCard';
import CrmCollectionAssociateDropDown from '../../../crm/components/CrmCollectionAssociateDropDown';
import isAce from '../../../hubs/persona/selectors/isAce';
import CrmDocumentQuickViewDrawer from '../../../crm/components/CrmDocumentQuickViewDrawer';

const LoadProcessName = 'AddressBook.AgingShipmentHistoryPage.Load';
const SaveProcessName = 'AddressBook.AgingShipmentHistoryPage.Save';
const SearchProcessName = 'AddressBook.AgingShipmentHistoryPage.Search';
const AgedShipmentSearchId = 'AddressBook.AgingShipmentHistoryPage';

const NewNoteSchema = Yup.object().shape({
  note: Yup.string().required('Note is required.')
});
const AgingShipmentHistoryPage = (props) => {
  const {
    isAce,
    classes,
    company,
    companyAgingNotes,
    companyFinancial,
    dispose,
    match,
    shipments = [],
    shipmentCount,
    searchCustomerShipments,
    clearCustomerSearch,
    load,
    saveCompanyAgingNote,
    createCompanyFinancial,
    saveCompanyFinancial,
    sendSnackbarMessage,
    shipmentStatusTypes
  } = props;

  const companyId = match.params.id && parseInt(match.params.id);

  if (isNewCompany(companyId)) {
    throw new Error('CompanyId url parameter missing.');
  }

  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(20);
  const [sort, setSort] = useState([['shipmentBolNumber', 'desc']]);
  const [isAddingNote, setIsAddingNote] = useState(false);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('shipmentBolNumber');
  const [docQuickViewShipmentId, setDocQuickViewShipmentId] = React.useState(null);

  useEffect(() => {
    load(companyId);
    return () => dispose();
  }, [companyId]);

  useEffect(() => {
    const args = [
      companyId,
      offset,
      limit,
      sort
    ];

    searchCustomerShipments(...args);

  }, [offset, limit, sort]);

  useEffect(() => {
    return () => clearCustomerSearch();
  }, [clearCustomerSearch]);

  //Handles when the user changes pages within the table.
  const handlePageChange = (e, page) => {
    setOffset(page * limit);
  };

  // Handles when the user clicks on column headers for sorting.
  const handleSortChange = (column) => {
    const changeOrder = (order === 'asc' && sort[0][0] === column) ? 'desc' : 'asc';

    setSort([[column, changeOrder]]);
    setOrder(changeOrder);
    setOrderBy(column);
  };

  const handleChangeRowsPerPage = (e) => {
    setLimit(e.target.value);
    setOffset(0);
  }
  const handleNewNoteOpen = (e) => setIsAddingNote(true);
  const handleNewNoteClose = (e) => setIsAddingNote(false);

  const handleNewNoteSubmit = (values, formFactor) => {
    setIsAddingNote(false);
    saveCompanyAgingNote(company.id, values.note)
      .then(() => {
        sendSnackbarMessage({ content: 'Note Saved.' });
      });
  };

  const handleCollectionAssociateChange = (associate) => {

    const updatedCompanyFinancial = {
      ...(companyFinancial || createCompanyFinancial(companyId)),
      collectionAssociateId: associate.id
    };

    saveCompanyFinancial(updatedCompanyFinancial)
      .then(() => sendSnackbarMessage({ content: `Collection associate changed to: ${associate.fullName}` }))
      .catch((error) => {
        console.error(error);
        sendSnackbarMessage({ content: `Failed to change collection associate to: ${associate.fullName}`, color: 'error' });
      });
  };

  const handleDocumentQuickViewClick = (shipment) => {
    setDocQuickViewShipmentId(shipment.shipmentBolNumber);
  };

  const handleDocumentQuickViewClose = () => {
    setDocQuickViewShipmentId(null);
  };

  const disputeStatus = shipmentStatusTypes
    .find(type => type.name === Business.Shipment.ShipmentStatusNames.Dispute);

  const processedShipments = shipments.map(s => {
    s.isDisputed = s.statusId === disputeStatus.id;
    return s;
  });

  const newNoteInitialValues = {
    note: '',
    onClose: handleNewNoteClose
  };

  const title = composePageTitle('Address Book', 'Aging Shipment', company?.name);

  const sortedAgingNotes = companyAgingNotes
    .slice()
    .sort(NoteSort);

  const collectionTuple = [
    companyFinancial?.collectionAssociate ?
      companyFinancial.collectionAssociate.firstName + ' ' + companyFinancial.collectionAssociate.lastName : 'Unassigned',
    companyFinancial?.collectionAssociate ?
      companyFinancial.collectionAssociate.emailAddress : 'credit@tallgrassfreight.com'
  ];


  return (
    <FullWidthLayout SideNav={AddressBookRecordNav} title={title}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          TGF Collections Rep: <a href={`mailto:${collectionTuple[1]}`} className={classes.link}>{collectionTuple[0]}</a>
        </Grid>
        <Grid item xs={12}>
          <AgingShipmentListing
            paymentTerms={company?.paymentTerms}
            count={shipmentCount}
            data={processedShipments || []}
            orderBy={orderBy}
            order={order}
            rowsPerPage={limit}
            page={offset / limit}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            onSort={handleSortChange}
            onDocumentQuickViewClick={handleDocumentQuickViewClick}
          />
        </Grid>
        <Grid container item xs={12} spacing={1}>
          <Grid container item xs={12} md={isAce ? 9 : 12}>
            <AppMuiCard title={'Notes'}>
              <AppButton look={'outline'} startIcon={<Add />} onClick={handleNewNoteOpen}>New Note</AppButton>
              <AppMuiSimpleTable
                columns={Columns}
                tablePropMap={makeTableFixed}
                data={sortedAgingNotes}
              />
            </AppMuiCard>
          </Grid>
          {
            isAce &&
            <Grid item xs={12} md={3}>
              <AppMuiCard title={'Collections'} height={null}>
                <CrmCollectionAssociateDropDown
                  disableClearable
                  label={'Assigned Collection Associate'}
                  value={companyFinancial?.collectionAssociate || null}
                  onChange={handleCollectionAssociateChange}
                />
              </AppMuiCard>
            </Grid>
          }
        </Grid>
        <Grid item xs={12}>
          {isAddingNote && <FormFactor
            initialValues={newNoteInitialValues}
            schema={NewNoteSchema}
            open={isAddingNote}
            onSubmit={handleNewNoteSubmit}>
            {renderNewNoteForm}
          </FormFactor>
          }
        </Grid>
      </Grid>
      {
        docQuickViewShipmentId &&
        <CrmDocumentQuickViewDrawer
          shipmentId={docQuickViewShipmentId}
          onClose={handleDocumentQuickViewClose}
        />
      }
    </FullWidthLayout>
  );

};

const makeTableFixed = (baseProps) =>
  Core.Utils.merge({}, baseProps, {
    style: { tableLayout: 'fixed' },
    width: '100%'
  });

const Columns = [
  {
    title: 'Note',
    headerPropMap(index, columns) {
      return { width: '65%' };
    },
    cellPropMap(note, index, data, columns) {
      return {
        style: { overflowWrap: 'break-word' }
      };
    },
    valueMap(note, index, data, columns) {
      return note.note
    }
  },
  {
    title: 'Date',
    headerPropMap(index, columns) {
      return { width: '15%', style: { textAlign: 'center' } };
    },
    valueMap(note, index, data, columns) {
      return <AppDateTimeText value={note.createDate} />;
    }
  },
  {
    title: 'Associate',
    headerPropMap(index, columns) {
      return { width: '20%', style: { textAlign: 'center' } };
    },
    cellPropMap(note, index, data, columns) {
      return {
        style: { textAlign: 'center' }
      };
    },
    valueMap(note, index, data, columns) {
      return note.associate ?
        `${note.associate.firstName}  ${note.associate.lastName}` :
        '[BLANK]'
    }
  }
];

const renderNewNoteForm = ({ values, errors, isValid, setFieldValue, submitForm }) => {

  const newNoteActions = [
    {
      title: 'Cancel',
      action: values.onClose,
    },
    {
      title: 'Save',
      action: submitForm,
      type: 'submit',
      disabled: !isValid
    }
  ];

  const handleNoteChange = (e) =>
    setFieldValue('note', e.target.value);

  return (
    <AppDialog
      title="New Note"
      open={true}
      onClose={values.onClose}
      actionButtons={newNoteActions}
      width="sm"
    >
      <Grid container>
        <Grid item xs={12}>
          <AppInput
            id="companyNote"
            rows="8"
            cols="45"
            multiline={true}
            inputProps={{
              maxLength: 1000
            }}
            onChange={handleNoteChange} />
          <AppValidationFailure message={errors.note} />
        </Grid>
      </Grid>
    </AppDialog>
  );
};

const NoteSort = (noteA, noteB) => {
  if (noteA.createDate > noteB.createDate)
    return -1;
  else if (noteB.createDate > noteA.createDate) {
    return 1;
  }
  else return 0;
};

export default ComponentBuilder
  .wrap(AgingShipmentHistoryPage)
  .withStyles((theme) => ({
    link: {
      textDecoration: 'none',
      color: theme.palette.tgfLink.main,
      fontWeight: 'bold',
      '&:hover': {
        textDecoration: 'underline'
      }
    }
  }))
  .stateToProps((state, ownProps) => {
    const { shipments, count: shipmentCount } = selectCustomerShipmentsSearch(state, AgedShipmentSearchId) || {};
    return {
      isAce: isAce(state),
      company: state.addressBook.modification.company,
      shipments,
      shipmentCount,
      companyAgingNotes: state.addressBook.modification.companyAgingNotes,
      companyFinancial: state.addressBook.modification.companyFinancial,
      shipmentStatusTypes: state.support.shipmentStatusTypes
    };
  })
  .dispatchToProps((shell, dispatch, getState) => {
    return {
      async searchCustomerShipments(companyId, offset, limit, sort) {
        dispatch(shell.actions.sys.processStart(SearchProcessName));
        dispatch(await shell.actions.shipment.customer
          .searchCustomerShipments(AgedShipmentSearchId, companyId, null, null, null, true, offset, limit, sort));
        dispatch(shell.actions.sys.processComplete(SearchProcessName));
      },
      async load(companyId) {
        dispatch(shell.actions.sys.processStart(LoadProcessName));
        const actions = await Promise.all([
          shell.actions.addressBook.modification.loadCompany(companyId),
          shell.actions.addressBook.modification.loadCompanyFinancial(companyId),
          shell.actions.addressBook.modification.loadCompanyAgingNotes(companyId)
        ]);
        actions.forEach(dispatch);
        dispatch(shell.actions.sys.processComplete(LoadProcessName));
      },
      async clearCustomerSearch() {
        dispatch(await shell.actions.shipment.customer.clearCustomerShipmentsSearch(AgedShipmentSearchId));
      },
      async saveCompanyAgingNote(companyId, noteText) {
        dispatch(shell.actions.sys.processStart(SaveProcessName));
        dispatch(await shell.actions.addressBook.modification.saveCompanyAgingNote(companyId, noteText));
        dispatch(shell.actions.sys.processComplete(SaveProcessName));
      },
      createCompanyFinancial(companyId) {
        return {
          ...shell.gateway.createEmptyCompanyFinancial(),
          companyId
        };
      },
      async saveCompanyFinancial(companyFinancial) {
        dispatch(shell.actions.sys.processStart(SaveProcessName));
        dispatch(await shell.actions.addressBook.modification.saveCompanyFinancial(companyFinancial));
        dispatch(shell.actions.sys.processComplete(SaveProcessName));
      },
      async dispose() {
        dispatch(await shell.actions.addressBook.modification.dispose());
      },
      async sendSnackbarMessage(message) {
        dispatch(await shell.actions.sys.sendSnackbarMessage(message));
      }
    };
  })
  .build();