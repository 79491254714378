import ActionBuilder from '../../../ActionBuilder2';
import GatewayFacade from '../../../GatewayFacade';
import ActionTypes from '../../../ActionTypes';

const saveShipmentTruckLoadConfirmation = async (shell, shipmentTruckloadValue) => {

  const gateway = new GatewayFacade(shell.communicator);

  const newShipmentTruckLoadValue = gateway.createShipmentTruckLoadConfirmation();
  newShipmentTruckLoadValue.id = shipmentTruckloadValue.id;
  newShipmentTruckLoadValue.bolNumber = shipmentTruckloadValue.bolNumber;
  newShipmentTruckLoadValue.typeId = shipmentTruckloadValue.typeId;
  newShipmentTruckLoadValue.confirmedDate = shipmentTruckloadValue.confirmedDate = new Date();
  newShipmentTruckLoadValue.confirmingAssociateId = shipmentTruckloadValue.confirmingAssociateId;
  newShipmentTruckLoadValue.confirmedValue = shipmentTruckloadValue.confirmedValue;

  const updateShipmentTruckLoadConfirmation = await gateway.saveShipmentTruckLoadConfirmation(newShipmentTruckLoadValue);

  return {
    type: ActionTypes.Shipment.Modification.ShipmentTruckLoadConfirmationSaved,
    truckloadValueConfirmation: updateShipmentTruckLoadConfirmation
  }
};

export default ActionBuilder
  .for(saveShipmentTruckLoadConfirmation)
  .build();
