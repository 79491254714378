import React from 'react';
import { Grid } from '@material-ui/core';

import ComponentBuilder from '../../../core/ComponentBuilder';
import FullWidthLayout from '../../../core/layouts/FullWidthLayout';
import isAce from '../../../hubs/persona/selectors/isAce';
import isProcessActive from '../../../hubs/sys/selectors/isProcessActive';
import LaneLookupListingTable from './includes/LaneLookupListingTable';
import ReportNav from '../ReportNav';
import selectStateProvinces from '../../../hubs/support/selectors/stateProvinces';

import AppDropdown from '../../../core/components/dropdowns/AppDropdown/AppDropdown';

const LoadProcessName = 'Reporting.LaneLookup.Loaded';

const LaneLookup = (props) => {
  const [offset, setOffset] = React.useState(0);
  const [limit, setLimit] = React.useState(20);
  const [sort, setSort] = React.useState([['bolNumber', 'desc']]);
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('bolNumber');
  const [fromState, setFromState] = React.useState(null);
  const [toState, setToState] = React.useState(null);

  const {
    dispose,
    associate,
    isAce,
    load,
    laneLookupListing,
    laneLookupCount,
    stateProvinces
  } = props;

React.useEffect(() => () => dispose(), [])

  React.useEffect(() => {
    const associateid = isAce ? null : associate.id;

    load(fromState, toState, offset, limit, sort, associateid)
  }, [fromState, toState, offset, limit, sort]);

  //Handles when the user changes pages within the table.
  const handlePageChange = (e, page) => {
    setOffset(page * limit);
  };
  const handleFromState = (e, value) => {
    setFromState(value.abbreviation);
    setOffset(0);
  }
  const handleToState = (e, value) => {
    setToState(value.abbreviation);
    setOffset(0);
  }

  // Handles when the user clicks on column headers for sorting.
  const handleSortChange = (column) => {
    const changeOrder = (order === 'asc' && sort[0][0] === column) ? 'desc' : 'asc';

    setSort([[column, changeOrder]]);
    setOrder(changeOrder);
    setOrderBy(column);
  };
  const handleChangeRowsPerPage = (e) => {
    setOffset(0);
    setLimit(e.target.value);
  };

  const options = [{ abbreviation: null, name: 'All' }, ...stateProvinces];

  return (
    <FullWidthLayout SideNav={ReportNav} title="Lane Look Up/History">
      <Grid container spacing={1} direction="column">
        <Grid item>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={12} md={2}>
              <AppDropdown
                label="State From"
                id="fromState"
                defaultValue={{ abbreviation: null, name: 'All' }}
                field={'name'}
                fieldIndex={'abbreviation'}
                options={options}
                onChange={handleFromState}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <AppDropdown
                label="State To"
                id="toState"
                defaultValue={{ abbreviation: null, name: 'All' }}
                field={'name'}
                fieldIndex={'abbreviation'}
                options={options}
                onChange={handleToState}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <LaneLookupListingTable
            count={laneLookupCount}
            data={laneLookupListing}
            orderBy={orderBy}
            order={order}
            rowsPerPage={limit}
            page={offset / limit}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            onSort={handleSortChange}
          />
        </Grid>
      </Grid>
    </FullWidthLayout>
  )
}
export default ComponentBuilder
  .wrap(LaneLookup)
  .stateToProps((state, ownProps) => {
    return {
      isAce: isAce(state),
      laneLookupListing: state.reporting.laneLookup.listing,
      laneLookupCount: state.reporting.laneLookup.laneLookupCount,
      associate: state.persona.modifyingAssociate,
      stateProvinces: selectStateProvinces(state)
        .map(([abbreviation, name]) => ({ abbreviation, name })),
    }
  })
  .dispatchToProps((shell, dispatch, getState) => {
    return {
      async load(fromState, toState, offset, limit, sort, associateId) {
        dispatch(shell.actions.sys.processStart(LoadProcessName));
        dispatch(await shell.actions.reporting.laneLookup.load(fromState, toState, offset, limit, sort, associateId));
        dispatch(shell.actions.sys.processComplete(LoadProcessName));
      },
      async dispose() {
        dispatch(await shell.actions.reporting.laneLookup.dispose());
      }
    }
  })
  .build()