import ActionTypes from '../../../ActionTypes';

const deliveredStatusType = (state = null, action) => {
  switch (action.type) {
    case ActionTypes.Shipment.Statusing.Loaded:
      return action.deliveredStatusType;
    default:
      return state;
  }
};

export default deliveredStatusType;