import ActionBuilder from '../../../ActionBuilder';
import ProcessTypes from '../../../ProcessTypes';
import ActionTypes from '../../../ActionTypes';
// import createShipmentModel from '../../models/createShipmentModel';
// import createShipmentInvoiceModel from '../../models/createShipmentInvoiceModel';
// TODO: Revisit open-tl and open-ltl carrier assignments.
// import selectOpenTlCarrier from '../../../carrierDirectory/selectors/entries/selectOpenTlCarrier';

const readyNew = ({ getState }) => {

  const state = getState();

  const {
    persona: {
      associate: {
        id: associateId
      }
    },
    shipment: {
      freight: {
        defaultFreightCategory
      },
      statusing: {
        defaultStatusType
      }
    }
  } = state;

  // TODO: Revisit open-tl and open-ltl carrier assignments.
  // const openTlCarrier = selectOpenTlCarrier(state);

  const freightCategoryId = defaultFreightCategory.id;
  const statusId = defaultStatusType.id;
  const equipmentType = null;
  // TODO: Revisit open-tl and open-ltl carrier assignments.
  // const carrierMcNumber = openTlCarrier.mcNumber;
  const carrierMcNumber = 'TODO';

  const billingCompanyId = null;

  // const shipment = createShipmentModel({}, new Date(), freightCategoryId, equipmentType, associateId, carrierMcNumber, billingCompanyId, statusId);
  // const invoice = createShipmentInvoiceModel({});

  return {
    type: ActionTypes.Shipment.Modification.Loaded,
    // shipment,
    // invoice,
    bolCarrierContact: null,
    loadTrackingNotes: [],
    loadContents: [],
    multiStopLocations: [],
    truckloadValueConfirmation: null,
    invoiceAuditNotes: []
  };
};

export default ActionBuilder
  .for(readyNew)
  .process(ProcessTypes.Shipment.Modification.Loading)
  .build();