import ActionBuilder from '../../../ActionBuilder';
import ActionTypes from '../../../ActionTypes';
import VisitationStore from './VisitationStore';

const clearVisitations = (context) => {

  const visitStore = new VisitationStore();

  visitStore.clearVisitations();

  return {
    type: ActionTypes.Persona.Mrv.VisitationsCleared
  };
};

export default ActionBuilder
  .for(clearVisitations)
  .build();