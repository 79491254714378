import ActionTypes from '../../../ActionTypes';
import ActionBuilder from '../../../ActionBuilder';
import ProcessTypes from '../../../ProcessTypes';

const confirmTruckloadValue = async ({ shell }, bolNumber, newConfirmation) => {

  const truckloadValueConfirmation = await shell.communicator
    .post(`/shipment/${bolNumber}/confirm-truckload-value`, newConfirmation);

  return {
    type: ActionTypes.Shipment.Modification.ConfirmedTruckloadValue,
    truckloadValueConfirmation
  };

};

export default ActionBuilder
  .for(confirmTruckloadValue)
  .process(ProcessTypes.Shipment.Modification.ConfirmingTruckloadValue)
  .build();