import * as Redux from 'redux';

import defaultFreightCategory from './defaultFreightCategory';
import defaultPackageType from './defaultPackageType';
import freightCategories from './freightCategories';
import freightClasses from './freightClasses';
import ltlFreightCategory from './ltlFreightCategory';
import packageTypes from './packageTypes';
import tlFreightCategory from './tlFreightCategory';
import truckloadValueConfirmationTypes from './truckloadValueConfirmationTypes';
import nmfc from './nmfc';

export default Redux.combineReducers({
  defaultFreightCategory,
  defaultPackageType,
  freightCategories,
  freightClasses,
  ltlFreightCategory,
  packageTypes,
  tlFreightCategory,
  truckloadValueConfirmationTypes,
  nmfc
});