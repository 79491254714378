import React from 'react';
import * as Yup from 'yup';
import { Grid } from '@material-ui/core';
import Business from '@tgf-crm/business';

import FullWidthLayout from 'core/layouts/FullWidthLayout';
import ComponentBuilder from 'core/ComponentBuilder';
import AppDialog from 'core/components/AppDialog';
import composePageTitle from 'core/utils/composePageTitle';
import FasterFormFactor from 'core/FormFactor/FasterFormFactor';
import selectStateProvinces from 'hubs/support/selectors/stateProvinces';

import useStyles from './styles';
import AddressBookRecordNav from '../AddressBookRecordNav';
import BillingAddressCard from './includes/BillingAddressCard';
import InvoiceSettingsCard from './includes/InvoiceSettingsCard';
import FormButtonBar from './includes/FormButtonBar';
import BillingNotesCard from './includes/BillingNotesCard';
import ModifyContactDialog from './includes/ModifyContactDialog';
import BillingContactListingCard from './includes/BillingContactListingCard';

const LoadProcessName = 'AddressBook.BillingSettingsPage.Load';
const SaveProcessName = 'AddressBook.BillingSettingsPage.Save';
const SaveContactProcessName = 'AddressBook.BillingSettingsPage.SaveContact';
const DeleteContactProcessName = 'AddressBook.BillingSettingsPage.DeleteContact';

const EmailMethodType = Business.Company.CompanyInvoiceDeliveryMethodTypes.Email;
const BillingRole = Business.Company.CompanyRoles.Billing;

const DeliveryMethodSelectionRule = {
  invoke(event, context) {
    if (event?.pathSpec === 'selectedInvoiceDeliveryMethodType') {

      const deliveryMethodType = event.newValue;

      // Allow batching only if Email is selected.
      const allowInvoiceBatching = deliveryMethodType?.id === EmailMethodType.Id;
      context.setSupportingValue('allowInvoiceBatching', allowInvoiceBatching);

      // If batching is no longer allowed for the type selected, set the flag to false.
      if (!allowInvoiceBatching) {
        context.setFieldValue('batchInvoices', false);
      }

      // Capture the ID of the type selected or clear it if nothing is selected.
      const deliveryMethodTypeId = deliveryMethodType?.id || null;
      context.setFieldValue('deliveryMethodTypeId', deliveryMethodTypeId);
    }
  }
};

const SelectedStateRule = {
  invoke(event, context) {
    if (event?.pathSpec === 'selectedStateProvince') {
      const newState = event.newValue;
      context.setFieldValue('billingStateProvince', newState.abbreviation);
    }
  }
};

const buildSchema = (otherDeliveryMethodType) => {
  return Yup
    .lazy(values => {

      let schema = {
        selectedInvoiceDeliveryMethodType: Yup.object()
          .required('Delivery Method is required.')
      };

      const {
        billingAddress1,
        billingAddress2,
        billingCity,
        selectedStateProvince,
        billingPostalCode,
        selectedInvoiceDeliveryMethodType
      } = values;

      // Check if Other is selected to ensure notes are entered.
      if (selectedInvoiceDeliveryMethodType?.id === otherDeliveryMethodType.id) {
        const delTypeSchema = {
          deliveryMethodNote: Yup.string()
            .nullable()
            .required('Delivery Method Notes is required for "Other".')
        };
        Object.assign(schema, delTypeSchema);
      }

      const hasOneValue = Boolean(billingAddress1) ||
        Boolean(billingAddress2) ||
        Boolean(billingCity) ||
        Boolean(selectedStateProvince) ||
        Boolean(billingPostalCode);

      if (hasOneValue) {
        const addressSchema = {
          billingAddress1: Yup.string().nullable()
            .required('Address Line 1 is required'),
          billingCity: Yup.string().nullable()
            .required('City is required.'),
          selectedStateProvince: Yup.object()
            .nullable()
            .required('State/Province is required.'),
          billingPostalCode: Yup.string().nullable()
            .min(5, 'Postal Code must be 5-7 digits.')
            .max(7, 'Postal Code must be 5-7 digits.')
            .required('Postal Code is required.')
        };
        Object.assign(schema, addressSchema);
      }

      return Yup.object().shape(schema);
    });
}

const BillingSettingsPage = ({
    match,
    company,
    contacts,
    companyInvoiceSettings,
    dispose,
    load,
    saveSettings,
    saveContact,
    deleteContact,
    stateProvinces,
    invoiceDeliveryTypes,
    sendSnackbarMessage,
  }) => {

  const classes = useStyles();
  const companyId = parseInt(match.params.id);
  const billingContacts = contacts
    .filter(c => c.companyContactRoles.some(ccr => ccr.companyRoleId === BillingRole.Id))
  const otherDeliveryMethodType = invoiceDeliveryTypes.find(dt => dt.id === 'other');

  const [draftContact, setDraftContact] = React.useState(null);
  const [deleteCandidate, setDeleteCandidate] = React.useState(null);

  React.useEffect(() => {
    load(companyId);
    return dispose;
  }, [companyId]);

  const allStateProvinces = stateProvinces?.map(([abbreviation, name]) =>({ abbreviation, name }));

  const handleDeleteContactClose = (e) =>
    setDeleteCandidate(null);

  const handleNewContactClick = () =>
    setDraftContact({
      companyId,
      firstName: null,
      lastName: null,
      emailAddress: null,
      businessPhone: null,
      cellPhone: null,
      faxPhone: null,
      isPrimary: false,
      companyContactRoles: [
        { companyId, companyRoleId: BillingRole.Id }
      ]
    });

  const handleEditContactClick = (contact) => {
    setDraftContact(contact);
  };

  const handleDeleteContactClick = (contact) =>
    setDeleteCandidate(contact);

  const handleContactSaveClick = (contact) => {
    saveContact(contact)
      .then(() => {
        sendSnackbarMessage({ content: 'Billing contact saved.' })
      })
      .catch((error) => {
        console.error(error);
        sendSnackbarMessage({ content: 'Saving of billing contact failed.', color: 'error' });
      });
    setDraftContact(null);
  };

  const handleContactCancelClick = () =>
    setDraftContact(null);

  const selectedInvoiceDeliveryMethodType = invoiceDeliveryTypes
    .find(dt => dt.id === companyInvoiceSettings?.deliveryMethodTypeId);

  const mainFormProps = {
    initialValues: {
      id: companyInvoiceSettings?.id ?? null,
      companyId,
      billingAddress1: companyInvoiceSettings?.billingAddress1 ?? null,
      billingAddress2: companyInvoiceSettings?.billingAddress2 ?? null,
      billingCity: companyInvoiceSettings?.billingCity ?? null,
      billingStateProvince: companyInvoiceSettings?.billingStateProvince ?? null,
      selectedStateProvince: allStateProvinces
        .find(sp => sp.abbreviation === companyInvoiceSettings?.billingStateProvince) ?? null,
      billingPostalCode: companyInvoiceSettings?.billingPostalCode ?? null,
      deliveryMethodTypeId: companyInvoiceSettings?.deliveryMethodTypeId,
      selectedInvoiceDeliveryMethodType,
      batchInvoices: companyInvoiceSettings?.batchInvoices ?? false,
      deliveryMethodNote: companyInvoiceSettings?.deliveryMethodNote ?? null,
      generalNote: companyInvoiceSettings?.generalNote ?? null,
      allowInvoiceBackdating: true
    },
    supportingValues: {
      company,
      allowInvoiceBatching: selectedInvoiceDeliveryMethodType?.id === EmailMethodType.Id,
      stateProvinces: allStateProvinces,
      invoiceDeliveryTypes
    },
    schema: buildSchema(otherDeliveryMethodType),
    rules: [
      SelectedStateRule,
      DeliveryMethodSelectionRule
    ],
    onSubmit(values, context) {

      const billingSettings = values;

      saveSettings(billingSettings)
        .then(() => {
            sendSnackbarMessage({ content: 'Customer billing settings saved.' })
          })
        .catch((error) => {
          console.error(error);
          sendSnackbarMessage({ content: 'Saving of the customer billing settings failed.', color: 'error' });
        });
    }
  };

  const title = composePageTitle('Address Book', 'Billing', company?.name);


  const handleDeleteContactConfirm = (e) => {
    const contactName = [deleteCandidate.firstName, deleteCandidate.lastName].join(' ');
    deleteContact(companyId, deleteCandidate.id)
      .then(() => {
        sendSnackbarMessage({ content: `Contact [${contactName}] deleted.` });
      })
      .catch((error) => {
        console.error(error);
        sendSnackbarMessage({ content: `Deletion of contact [${contactName}] encountered an error.`, color: 'error' });
      });
    setDeleteCandidate(null);
  };

  const deleteActions = [
    {
      title: 'Cancel',
      action: handleDeleteContactClose
    },
    {
      title: 'Confirm',
      action: handleDeleteContactConfirm
    }
  ];

  return (
    <React.Fragment>
      <FullWidthLayout
        SideNav={AddressBookRecordNav}
        title={title}
        className={classes.header}
      >
        <FasterFormFactor {...mainFormProps}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormButtonBar />
            </Grid>
            <Grid item xs={4}>
              <BillingAddressCard />
            </Grid>
            <Grid item xs={4}>
              <InvoiceSettingsCard />
            </Grid>
            <Grid item xs={4}>
              <BillingNotesCard />
            </Grid>
            <Grid item xs={12}>
              <BillingContactListingCard
                contacts={billingContacts}
                onNewContactClick={handleNewContactClick}
                onEditContactClick={handleEditContactClick}
                onDeleteContactClick={handleDeleteContactClick}
              />
            </Grid>
          </Grid>
        </FasterFormFactor>

      </FullWidthLayout>

      <ModifyContactDialog
        contact={draftContact}
        onSaveClick={handleContactSaveClick}
        onCancelClick={handleContactCancelClick}
      />

      {
        deleteCandidate && <AppDialog
          title={'Contact Deletion'}
          width={'sm'}
          open={true}
          onClose={handleDeleteContactClose}
          actionButtons={deleteActions}>
          Confirm that you want to delete contact [{deleteCandidate.firstName} {deleteCandidate.lastName}]?
        </AppDialog>
      }
    </React.Fragment>
  );
};

export default ComponentBuilder
  .wrap(BillingSettingsPage)
  .stateToProps((state, ownProps) => {
    return {
      company: state.addressBook.modification.company,
      contacts: state.addressBook.modification.contacts,
      companyInvoiceSettings: state.addressBook.modification.companyInvoiceSettings,
      stateProvinces: selectStateProvinces(state),
      invoiceDeliveryTypes: state.support.companyInvoiceDeliveryMethodTypes
    };
  })
  .dispatchToProps((shell, dispatch, getState) => {
    return {
      async load(companyId) {
        dispatch(shell.actions.sys.processStart(LoadProcessName));
        const actions = await Promise.all([
          shell.actions.addressBook.modification.loadCompany(companyId),
          shell.actions.addressBook.modification.loadCompanyContacts(companyId),
          shell.actions.addressBook.modification.loadCompanyInvoiceSettings(companyId)
        ]);
        actions.forEach(dispatch);
        dispatch(shell.actions.sys.processComplete(LoadProcessName));
      },
      async dispose() {
        dispatch(await shell.actions.addressBook.modification.dispose());
      },
      async saveSettings(settings) {
        dispatch(shell.actions.sys.processStart(SaveProcessName));
        dispatch(await shell.actions.addressBook.modification.saveCompanyInvoiceSettings(settings));
        dispatch(shell.actions.sys.processComplete(SaveProcessName));
      },
      async saveContact(contact) {
        dispatch(shell.actions.sys.processStart(SaveContactProcessName));
        dispatch(await shell.actions.addressBook.modification.saveCompanyContact(contact));
        dispatch(shell.actions.sys.processComplete(SaveContactProcessName));
      },
      async deleteContact(companyId, contactId) {
        dispatch(shell.actions.sys.processStart(DeleteContactProcessName));
        dispatch(await shell.actions.addressBook.modification.deleteCompanyContact(companyId, contactId));
        dispatch(shell.actions.sys.processComplete(DeleteContactProcessName));
      },
      async sendSnackbarMessage(message) {
        dispatch(await shell.actions.sys.sendSnackbarMessage(message));
      }
    };
  })
  .build();
