import ActionBuilder from '../../../ActionBuilder2';
import GatewayFacade from '../../../GatewayFacade';
import ActionTypes from '../../../ActionTypes';

const loadShipment = async (shell, bolNumber) => {

  const gateway = new GatewayFacade(shell.communicator);
  const shipment = await gateway.getShipment(bolNumber);

  return {
    type: ActionTypes.Shipment.Modification.ShipmentLoaded,
    shipment
  }
};

export default ActionBuilder
  .for(loadShipment)
  .build();