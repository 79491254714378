import ActionBuilder from "../../ActionBuilder2";
import ActionTypes from '../../ActionTypes';
import GatewayFacade from '../../GatewayFacade';

const loadPaymentTerms = async ({ shell }) => {

  const gateway = new GatewayFacade(shell.communicator);
  const paymentTerms = await gateway.getPaymentTerms();

  return {
    type: ActionTypes.Support.PaymentTermsLoaded,
    paymentTerms
  };

};

export default ActionBuilder
  .for(loadPaymentTerms)
  .build();