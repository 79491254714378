import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';
import GatewayFacade from '../../../GatewayFacade';

const loadCompanyContacts = async (shell, companyId) => {

  const gateway = new GatewayFacade(shell.communicator);

  const contacts = await gateway
    .getCompanyContacts(companyId);

  return {
    type: ActionTypes.AddressBook.Modification.CompanyContactsLoaded,
    contacts
  }
};

export default ActionBuilder
  .for(loadCompanyContacts)
  .build();