import ActionBuilder from '../../../ActionBuilder2';
import GatewayFacade from '../../../GatewayFacade';
import ActionTypes from '../../../ActionTypes';

const saveCompanyNote = async (shell, companyId, noteText) => {

  const state = shell.stateStore.getState();
  const associate = state.persona.associate;

  const gateway = new GatewayFacade(shell.communicator);

  const note = gateway.createCompanyNote();
  note.note = noteText;
  note.companyId = companyId;
  note.createDate = new Date();
  note.associateId = associate.id;

  const updatedNoteData = await gateway.saveCompanyNote(note);

  const notes = await gateway.getCompanyNotes(companyId);

  return {
    type: ActionTypes.AddressBook.Modification.CompanyNoteSaved,
    note: updatedNoteData,
    notes
  };

};

export default ActionBuilder
  .for(saveCompanyNote)
  .build();