import Core from '@atomos/core';
import ActionBuilder from '../../../ActionBuilder';
import ProcessTypes from '../../../ProcessTypes';
import ActionTypes from '../../../ActionTypes';
// import {toast} from 'react-toastify';
import shipmentWash from './support/shipmentWash';
import invoiceWash from './support/invoiceWash';

const mapLoadTrackingNote = (loadTrackingNoteData) => {
  return {
    bolNumber: loadTrackingNoteData.bolNumber,
    trackingDate: loadTrackingNoteData.trackingDate,
    signer: loadTrackingNoteData.signer,
    associateId: loadTrackingNoteData.associateId,
    note: loadTrackingNoteData.note,
    loadTrackingPhaseId: loadTrackingNoteData.loadTrackingPhaseId,
    createDate: loadTrackingNoteData.createDate
  };
};

const mapLoadContent = (loadContent) => {
  return {
    bolNumber: loadContent.bolNumber,
    packageCount: loadContent.packageCount,
    pieceCount: loadContent.pieceCount,
    packageTypeId: loadContent.packageTypeId,
    description: loadContent.description,
    dimensions: loadContent.dimensions,
    freightClassId: loadContent.freightClassId,
    nmfc: loadContent.nmfc,
    packageWeight: loadContent.packageWeight,
    note: loadContent.note
  };
};

const saveLoadTrackingNote = async (shell, bolNumber, loadTrackingNote) => {
  return await shell.communicator.post(`/shipment/${bolNumber}/load-tracking-note`, mapLoadTrackingNote(loadTrackingNote));
};

const saveLoadContent = async (shell, bolNumber, loadContent) => {
  return await shell.communicator.post(`/shipment/${bolNumber}/load-content`, mapLoadContent(loadContent));
};

const saveLoadTrackingNotes = async (shell, bolNumber, loadTrackingNotes) => {

  // Split notes into new and existing; new notes won't have an `id` property.
  const [existingNotes, newNotes] = Core.Utils.partition(loadTrackingNotes, 'id');

  const promises = newNotes
    .map(n => saveLoadTrackingNote(shell, bolNumber, n))

  const updatedNotes = await Promise.all(promises);

  return existingNotes.concat(updatedNotes);
};

const saveLoadContents = async (shell, bolNumber, loadContents) => {

  const [newContents, existingContents] = Core.Utils.partition(loadContents, 'isNew');

  const promises = newContents
    .map(c => saveLoadContent(shell, bolNumber, c));

  const updatedContents = await Promise.all(promises);

  return existingContents.concat(updatedContents);

};

const save = async ({ shell }, shipment, invoice, loadTrackingNotes, loadContents) => {

  const updatedShipment = await shell.communicator.post(`/shipment`, shipment);

  const updatedInvoicePromise = shell.communicator.post(`/shipment/${updatedShipment.bolNumber}/invoice`, invoice);

  const updatedLoadTrackingNotesPromise = saveLoadTrackingNotes(shell, updatedShipment.bolNumber, loadTrackingNotes);

  const updatedLoadContentsPromise = saveLoadContents(shell, updatedShipment.bolNumber, loadContents);

  const [
    updatedInvoice,
    updatedLoadTrackingNotes,
    updatedLoadContents
  ] = await Promise.all([
    updatedInvoicePromise,
    updatedLoadTrackingNotesPromise,
    updatedLoadContentsPromise
  ]);

  // Notify the user of success.
  // toast.success(`Shipment [${updatedShipment.bolNumber}] saved.`);

  return {
    type: ActionTypes.Shipment.Modification.Saved,
    shipment: shipmentWash(updatedShipment),
    invoice: invoiceWash(updatedInvoice),
    loadTrackingNotes: updatedLoadTrackingNotes,
    loadContents: updatedLoadContents
  };

};

export default ActionBuilder
  .for(save)
  .process(ProcessTypes.Shipment.Modification.Saving)
  .build();