import React from 'react';
import AppCheckbox from '../../../core/components/inputs/AppCheckbox';
import { Grid } from '@material-ui/core';
import MultipleStopCard from './MultipleStopCard';
import AppButton from '../../../core/components/AppButton/AppMuiButton';
import { Save, Undo } from '@material-ui/icons';
import AppAlertStatic from '../../../core/components/AppAlertStatic';
import SecureField from "../permissions/SecureField";

const renderMultipleStopsForm = ({ values, originalValues, errors, isValid, isDirty, setFieldValue, resetForm, addArrayValue, removeArrayValue }) => {

  const customerId = values.shipment ? values.shipment.customerId : -1;

  const handleResetClick = (e) =>
    resetForm();

  const handleChange = (index) => (key, value) => {
    setFieldValue(`multipleStops[${index}].${key}`, value);
  };

  const handleCheckboxChanged = (key) => (e) => {
    setFieldValue(key, e.target.checked);
  };

  const handleLocationChanged = (index) => (company) => {
    if (company) {
      setFieldValue(`multipleStops[${index}].selectedCompany`, company);
      setFieldValue(`multipleStops[${index}].companyId`, company.companyId);
    }
    else {
      setFieldValue(`multipleStops[${index}].selectedCompany`, null);
    }
  };

  const handleStopTypeChanged = (index) => (stopType) => {
    setFieldValue(`multipleStops[${index}].selectedStopType`, stopType);
    switch (stopType.value) {
      case 'isPickup':
        setFieldValue(`multipleStops[${index}].isPickup`, true);
        setFieldValue(`multipleStops[${index}].isDelivery`, false);
        setFieldValue(`multipleStops[${index}].isUnloadReload`, false);
        return;
      case 'isDelivery':
        setFieldValue(`multipleStops[${index}].isPickup`, false);
        setFieldValue(`multipleStops[${index}].isDelivery`, true);
        setFieldValue(`multipleStops[${index}].isUnloadReload`, false);
        return;
      case 'isUnloadReload':
        setFieldValue(`multipleStops[${index}].isPickup`, false);
        setFieldValue(`multipleStops[${index}].isDelivery`, false);
        setFieldValue(`multipleStops[${index}].isUnloadReload`, true);
        return;
      default:
        return;
    }
  };

  const handleCompanySearch = (index, companyId) => (searchTerm) => {

    if (searchTerm.trim().length > 0) {
      const option = {
        filter: {
          companyName: {
            $like: `${searchTerm}%`
          },
          affiliateCompanyId: companyId
        },
        offset: 0,
        limit: 25,
        sort: [['companyName', 'asc']]
      };

      values.searchCompanies(option)
        .then(companies => {
          setFieldValue(`candidateCompanies`, companies);
        });
    }
    else {
      setFieldValue(`candidateCompanies`, []);
    }
  };

  const handleClearCard = (index) => () => {
    removeArrayValue('multipleStops', index);
    addArrayValue('multipleStops', values.createEmptyCompany());
  };

  const renderMultipleStop = (stop, index) =>
    <Grid item xs={12} key={index}>
      <MultipleStopCard
        stop={values.multipleStops[index]}
        companies={values.candidateCompanies}
        title={`Stop ${index + 1}`}
        stopTypes={values.stopTypes}
        isMultipleStop={values.isMultipleStop}
        onLocationChanged={handleLocationChanged(index)}
        onStopTypeChanged={handleStopTypeChanged(index)}
        onCompanySearch={handleCompanySearch(index, customerId)}
        onFieldChange={handleChange(index)}
        onClearCard={handleClearCard(index)}
        customerId={customerId}
        errors={errors}
      />
    </Grid>

  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item>
              <AppButton
                type={'submit'}
                startIcon={<Save />}
                disabled={!(isValid && isDirty)}
              >
                Save
              </AppButton>
            </Grid>
            <Grid item>
              <AppButton
                startIcon={<Undo />}
                color={'warning'}
                disabled={!isDirty}
                onClick={handleResetClick}
              >
                Reset
              </AppButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <SecureField
            pageName={'multipleStops'}
            fieldName={'isMultiStop'}
            as={AppCheckbox}
            id={'isMultiStop'}
            type={'checkbox'}
            label={'Confirm this is a Multi-Stop Truckload'}
            checked={values.isMultipleStop}
            onChange={handleCheckboxChanged('isMultipleStop')}
          />
        </Grid>
        <Grid item xs={12}>
          <AppAlertStatic title={'Bill of Lading Consignee'}>
            <p>Consignee chosen on Details page will be the ultimate destination.</p>
            <p>Be sure that all pages of the rate confirmation are signed and on file to prevent service and financial issues.</p>
          P/U Time, P/U Ref, Del Date, Del Time and Del Ref entered on the Details page will only apply to the Shipper and Consignee chosen on that same screen. Select the additional stops below and enter each stop's own information.
        </AppAlertStatic>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {
              values.multipleStops.map(renderMultipleStop)
            }
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default renderMultipleStopsForm;