import React from 'react';
import { Grid } from '@material-ui/core';
import * as Yup from 'yup';

import ComponentBuilder from '../../../core/ComponentBuilder';
import isAce from '../../../hubs/persona/selectors/isAce';
import FullWidthLayout from '../../../core/layouts/FullWidthLayout';
import ShipmentRecordNav from "../ShipmentRecordNav";
import FormFactor from "../../../core/FormFactor/FormFactor";
import renderDetailForm from "./renderDetailForm";
import FreightCategoryTypeNames from "../../../hubs/shipment/FreightCategoryTypeNames";
import StatusTypeNames from "../../../hubs/shipment/StatusTypeNames";
import isNewShipment from "../../../hubs/shipment/actions/modification/isNewShipment";
import Core from "@atomos/core";
import ShipmentHazardousStateNames from "../../../hubs/shipment/ShipmentHazardousStateNames";
import When from "../../../core/components/condtionals/When";
import MissingOrDeniedShipmentAlert from '../MissingOrDeniedShipmentAlert';

const LoadProcessName = 'Shipment.DetailPage.Load';
const SaveProcessName = 'Shipment.DetailPage.Save';

const DetailsPage = (props) => {

  const {
    isAce,
    associate,
    dispose,
    equipmentTypes,
    freightCategoryTypes,
    hazardousStates,
    match,
    history,
    shipment,
    invoice,
    carrierContact,
    currentCustomer,
    currentShipper,
    currentConsignee,
    currentThirdParty,
    shipmentStatus,
    tallgrassBillingCompany,
    lockedAssociate,
    load,
    getCarrier,
    createCarrierContact,
    saveShipment,
    sendSnackbarMessage
  } = props;

  const bolNumber = match.params.id !== 'new' ?
    parseInt(match.params.id) : undefined;

  React.useEffect(() => {
    load(bolNumber);

    return () => dispose();
  }, [bolNumber]);


  const coerceCompany = (company) => {
    return {
      affiliateCompanyId: company.affiliateId,
      affiliateCompanyName: null,
      associateFirstName: null,
      associateId: company.associateId,
      associateLastName: null,
      categoryTypeId: company.categoryTypeId,
      categoryTypeName: null,
      companyAddress1: company.address1,
      companyAddress2: company.address2,
      companyBusinessPhone: company.businessPhone,
      companyCity: company.city,
      companyId: company.id,
      companyIsDisabled: company.isDisabled,
      companyIsInactive: company.isInactive,
      companyIsPodRequired: company.isHardCopyPodRequired,
      companyName: company.name,
      companyPostalCode: company.postalCode,
      companyStateProvince: company.stateProvince,
      primaryContactFirstName: null,
      primaryContactId: null,
      primaryContactLastName: null
    }
  };

  const isTruckloadCategory = () => {
    if(shipment) {
      const freightCategoryNominee = freightCategoryTypes
        .find(fct => fct.id === shipment.freightCategoryId)
    return freightCategoryNominee ?
      freightCategoryNominee.name === FreightCategoryTypeNames.Truckload :
      true;
    }
    return true;
  };

  const initialValues = {
    isAce,
    associate,
    bolNumber,
    bolDate: shipment && shipment.bolDate,
    candidateCarriers: shipment && shipment.candidateCarriers,
    candidateEquipmentTypes: shipment ?
      equipmentTypes.filter(et => et.freightCategoryId === shipment.freightCategoryId) :
      [],
    carrierContact,
    carrierMcNumber: shipment && shipment.carrierMcNumber,
    consigneeId: shipment && shipment.consigneeId,
    customerId: shipment && shipment.customerId,
    customerRep: shipment && shipment.customerRep,
    tallgrassBillingCompany,
    deliveryNumber: shipment && shipment.deliveryNumber,
    deliveryTime: shipment && shipment.deliveryTime,
    driverName: shipment && shipment.driverName,
    driverPhone: shipment && shipment.driverPhone,
    equipmentType: shipment && shipment.equipmentType,
    equipmentTypes,
    estimatedDeliveryDate: shipment && shipment.estimatedDeliveryDate,
    freightCategoryTypes,
    freightCategoryTypeId: shipment && shipment.freightCategoryId,
    hasCustomerChanged: false,
    hazardousStates,
    hazardousStateId: shipment && shipment.hazardousStateId,
    invoice,
    isBlind: shipment && shipment.isBlind,
    isDeliveryOrderRequired: shipment && shipment.isDeliveryOrderRequired,
    isLoadingCandidateCarriers: false,
    isMultipleStop: shipment && shipment.isMultipleStop,
    isNew: isNewShipment(bolNumber),
    isTlCategory: isTruckloadCategory(),
    lockedDate: shipment?.lockedDate,
    lockedAssociateId: shipment?.lockedAssociateId,
    lockedAssociate,
    note: shipment && shipment.note,
    pickupNumber: shipment && shipment.pickupNumber,
    pickupTime: shipment && shipment.pickupTime,
    proNumber: shipment && shipment.proNumber,
    rateConNote: shipment && shipment.rateConNote,
    ratingRefNumber: shipment && shipment.ratingRefNumber,
    refNum1Description: shipment && shipment.refNum1Description,
    refNum1: shipment && shipment.refNum1,
    refNum2Description: shipment && shipment.refNum2Description,
    refNum2: shipment && shipment.refNum2,
    refNum3Description: shipment && shipment.refNum3Description,
    refNum3: shipment && shipment.refNum3,
    refNum4Description: shipment && shipment.refNum4Description,
    refNum4: shipment && shipment.refNum4,
    repName: carrierContact && carrierContact.repName,
    repPhone: carrierContact && carrierContact.repPhone,
    repEmail: carrierContact && carrierContact.repEmail,
    experienceNote: carrierContact && carrierContact.experienceNote,
    getCarrier,
    selectedCarrier: shipment && shipment.carrier,
    selectedConsignee: currentConsignee && coerceCompany(currentConsignee),
    selectedCustomer: currentCustomer && coerceCompany(currentCustomer),
    selectedEquipmentType: shipment && equipmentTypes.find(et => et.name === shipment.equipmentType),
    selectedFreightCategory: shipment && freightCategoryTypes.find(fct => fct.id === shipment.freightCategoryId),
    selectedShipmentStatus: shipment && shipmentStatus.find(s => s.id === shipment.statusId),
    selectedThirdParty: currentThirdParty && coerceCompany(currentThirdParty),
    selectedShipper: currentShipper && coerceCompany(currentShipper),
    shipperId: shipment && shipment.shipperId,
    shipment,
    shipmentStatus,
    statusId: shipment && shipment.statusId,
    thirdPartyId: shipment && shipment.thirdPartyId,
    trailerNumber: shipment && shipment.trailerNumber,
    truckNumber: shipment && shipment.truckNumber
  };

  const handleSubmit = (values, formFactor) => {

    // get latest shipment data
    const shipmentData = Object.assign({},
      values.shipment, values)

    const invoiceData = values.invoice;

    // collect carrier contact data
    const carrierContactData = Core.Utils.merge({}, createCarrierContact(), values.carrierContact);
    if (!carrierContactData.id) {
      carrierContactData.bolNumber = bolNumber;
    }
    carrierContactData.repName = values.repName;
    carrierContactData.repEmail = values.repEmail;
    carrierContactData.repPhone = values.repPhone;
    carrierContactData.experienceNote = values.experienceNote;

    const isNew = isNewShipment(bolNumber);

    saveShipment(shipmentData, invoiceData, carrierContactData, isNew, values.hasCustomerChanged)
      .then((updatedShipment) => {
        sendSnackbarMessage({ content: 'Shipment details saved.'})
        if (isNew)
          history.replace(`/shipment/${updatedShipment.bolNumber}/details`);
      });
  };

  const title = `Shipments - Details - ${bolNumber ? bolNumber : 'New'}`;
  const leftNav = isNewShipment(bolNumber) ? null : ShipmentRecordNav;

  return (
    <FullWidthLayout title={title} SideNav={leftNav}>
      <When condition={bolNumber && !shipment}>
        <MissingOrDeniedShipmentAlert />
      </When>
      <When condition={shipment}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormFactor
              initialValues={initialValues}
              schema={DetailFormSchema}
              onSubmit={handleSubmit}>
              {renderDetailForm}
            </FormFactor>
          </Grid>
        </Grid>
      </When>
    </FullWidthLayout>
  );

};

const DetailFormSchema = Yup.lazy(values => {
  const schema = {
    selectedEquipmentType: Yup.object()
      .nullable()
      .required('Equipment Type must be selected'),
    selectedCustomer: Yup.object()
      .nullable()
      .required('Customer must be selected.'),
    selectedCarrier: Yup.object()
      .nullable()
      .required('Carrier must be selected.'),
    bolDate: Yup.date()
      .nullable()
      .required('Pickup date is required.'),
    repEmail: Yup.string()
    .nullable()
    .email('Email is invalid.'),
    repPhone: Yup.string()
      .nullable()
      .matches(Core.Text.PhoneRegExp, 'Rep. Phone is invalid.'),
    driverPhone: Yup.string()
      .nullable()
      .matches(Core.Text.PhoneRegExp, 'Driver Phone is invalid.')
  };
  return Yup.object().shape(schema);
});

export default ComponentBuilder
  .wrap(DetailsPage)
  .stateToProps((state, ownProps) => ({
    associate: state.persona.associate,
    lockedAssociate: state.shipment.modification.shipmentLockedAssociate,
    shipment: state.shipment.modification.shipment,
    invoice: state.shipment.modification.shipmentInvoice,
    shipmentStatus: state.support.shipmentStatusTypes,
    freightCategoryTypes: state.support.freightCategoryTypes,
    hazardousStates: state.support.shipmentHazardousStates,
    equipmentTypes: state.support.shipmentEquipmentTypes,
    carrierContact: state.shipment.modification.shipmentCarrierContact,
    currentCustomer: state.shipment.modification.shipmentCompanies.customer,
    currentShipper: state.shipment.modification.shipmentCompanies.shipper,
    currentConsignee: state.shipment.modification.shipmentCompanies.consignee,
    currentThirdParty: state.shipment.modification.shipmentCompanies.thirdParty,
    tallgrassBillingCompany: state.addressBook.tallgrassBillingCompany,
    isAce: isAce(state)
  }))
  .dispatchToProps((shell, dispatch, getState) => {
    return {
      async load(bolNumber) {

        // Grab current state to reference supporting data.
        const {
          support
        } = shell.stateStore.getState();

        const billingCompanyCategoryType = support.billingCompanyCategoryType;

        dispatch(shell.actions.sys.processStart(LoadProcessName));
        dispatch(await shell.actions.addressBook.loadTallgrassBillingCompany(billingCompanyCategoryType.id));

        const isNew = isNewShipment(bolNumber);

        if (isNew) {
          const newStatusType = support.shipmentStatusTypes.find(st => st.name === StatusTypeNames.New);
          const ltCategoryType = support.freightCategoryTypes.find(x => x.name === FreightCategoryTypeNames.Truckload);
          const defaultHazardState = support.shipmentHazardousStates.find(h => h.name === ShipmentHazardousStateNames.NonHazardous);
          const associate = shell.stateStore.getState().persona.associate;
          const [newShipment, ...basicActions] = await Promise.all ([
            shell.actions.shipment.modification.loadNewShipment(newStatusType.id,
              ltCategoryType.id,
              billingCompanyCategoryType.id,
              defaultHazardState.id,
              associate)
          ]);
          dispatch(newShipment);
          basicActions.forEach(dispatch);

          dispatch(await shell.actions.shipment.modification.loadShipmentCompany('thirdParty', newShipment.shipment.thirdPartyId))
        } else {

          const [shipmentAction, ...actions] = await Promise.all([
            shell.actions.shipment.modification.loadShipment(bolNumber),
            shell.actions.shipment.modification.loadShipmentInvoice(bolNumber),
            shell.actions.shipment.modification.loadShipmentCarrierContact(bolNumber)
          ]);
          dispatch(shipmentAction);
          actions.forEach(dispatch);

          if (shipmentAction.shipment) {

            const actionPromises = [
              shell.actions.shipment.modification.loadShipmentCompany('customer', shipmentAction.shipment.customerId),
              shell.actions.shipment.modification.loadShipmentCompany('shipper', shipmentAction.shipment.shipperId),
              shell.actions.shipment.modification.loadShipmentCompany('consignee', shipmentAction.shipment.consigneeId),
              shell.actions.shipment.modification.loadShipmentCompany('thirdParty', shipmentAction.shipment.thirdPartyId)
            ];

            // Optionally load the associate that locked the shipment to display on screen.
            if (Core.Utils.isNumber(shipmentAction.shipment.lockedAssociateId)) {
              actionPromises.push(shell.actions.shipment.modification.loadShipmentLockedAssociate(shipmentAction.shipment.lockedAssociateId));
            }

            const additionalActions = await Promise.all(actionPromises);

            additionalActions.forEach(dispatch);
          }
        }

        dispatch(shell.actions.sys.processComplete(LoadProcessName));
      },
      async saveShipment(shipment, invoice, carrierContact, isNewShipment, hasCustomerChanged) {
        dispatch(shell.actions.sys.processStart(SaveProcessName));

        // Save Shipment
        const saveShipmentAction = await shell.actions.shipment.modification.saveShipment(shipment);
        dispatch(saveShipmentAction);

        // Update Carrier Contacts
        const canSaveContact = carrierContact.id || carrierContact.repEmail || carrierContact.repPhone ||
          carrierContact.repName || carrierContact.experienceNote;

        if (canSaveContact) {
          if (isNewShipment)
            carrierContact.bolNumber = saveShipmentAction.shipment.bolNumber;
          dispatch(await shell.actions.shipment.modification.saveShipmentCarrierContact(carrierContact));
        }

        if (!isNewShipment)
          dispatch(await shell.actions.shipment.modification.saveShipmentInvoice(invoice));

        // Remove multi-stop in case is NOT new and isMultipleStop is false
        if (!isNewShipment && shipment.isMultipleStop && hasCustomerChanged) {
          dispatch(await shell.actions.shipment.modification.resetShipmentMultipleStop(saveShipmentAction.shipment.bolNumber))
        }

        // Reload shipment customer, shipper, consignee & thirdParty
        const companyActions = await Promise.all([
          shell.actions.shipment.modification.loadShipmentCompany('customer', saveShipmentAction.shipment.customerId),
          shell.actions.shipment.modification.loadShipmentCompany('shipper', saveShipmentAction.shipment.shipperId),
          shell.actions.shipment.modification.loadShipmentCompany('consignee', saveShipmentAction.shipment.consigneeId),
          shell.actions.shipment.modification.loadShipmentCompany('thirdParty', saveShipmentAction.shipment.thirdPartyId)
        ]);

        companyActions.forEach(dispatch);

        // Optionally load the associate that locked the shipment to display on screen.
        if (Core.Utils.isNumber(saveShipmentAction.shipment.lockedAssociateId)) {
          dispatch(await shell.actions.shipment.modification.loadShipmentLockedAssociate(saveShipmentAction.shipment.lockedAssociateId));
        }

        dispatch(shell.actions.sys.processComplete(SaveProcessName));
        return saveShipmentAction.shipment;
      },
      async createCarrierContact() {
        return shell.gateway.createCarrierContact();
      },
      async getCarrier(mcNumber){
        const carrier = await shell.gateway.getCarrier(mcNumber);
        return carrier;
      },
      async dispose() {
        dispatch(await shell.actions.shipment.modification.dispose());
      },
      async sendSnackbarMessage(message) {
        dispatch(await shell.actions.sys.sendSnackbarMessage(message));
      }
    }
  })
  .build();