import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Assertion } from '@atomos/assertion';
import Business from '@tgf-crm/business';

import ComponentBuilder from '../../../core/ComponentBuilder';
import isProcessActive from '../../../hubs/sys/selectors/isProcessActive';

import FullWidthLayout from '../../../core/layouts/FullWidthLayout';
import LeftNav from '../LeftNav';
import AppButton from '../../../core/components/AppButton';
import AppInput from '../../../core/components/inputs/AppInput/AppMuiInput';
import PaidShipmentsTable from './includes/PaidShipmentsTable';
import UnpaidShipmentsTable from './includes/UnpaidShipmentsTable';
import SubmitPaymentsConfirmationDialog from './includes/SubmitPaymentsConfirmationDialog';

const LoadProcessName = 'Shipments.RetrieveAll';
const StatusWarningMessage = 'Based on the current shipment status, marking this paid may be abnormal and cause potential issues.';

const useStyles = makeStyles(theme => ({
  disabledText: {
    color: theme.palette.tgfGrey.main
  }
}));

const CustomerPaymentsPage = (props) => {

  const classes = useStyles();

  const {
    dispose,
    freightCategoryTypes,
    shipments,
    shipmentStatusTypes,
    markShipmentsCustomerPaid,
    loadCustomerPaymentShipments
  } = props;

  const shipmentAssertions = Business.Shipment.ShipmentAssertions(shipmentStatusTypes, freightCategoryTypes);

  const [bolNumbers, setBolNumbers] = React.useState([]);
  const [workingBolNumbers, setWorkingBolNumbers] = React.useState('');
  const [showSubmitConfirmation, setShowSubmitConfirmation] = React.useState(false);

  React.useEffect(() => {
    loadCustomerPaymentShipments(bolNumbers)
    return () => dispose();
  }, [bolNumbers])

  const handleBolNumber = (e) =>
    setWorkingBolNumbers(e.target.value);

  const handleClearClick = () => {
    setBolNumbers([])
    setWorkingBolNumbers('');
  };

  const handleLookupShipmentsClick = () => {
    const bolNumbers = workingBolNumbers.split(',')
      .map(x => x.trim())
      .map(x => parseInt(x))
      .filter(x => !Number.isNaN(x));
    setBolNumbers(bolNumbers);
  }

  const isPaid = Assertion.from(shipment => shipment.invoice.customerWasPaid);
  const isNotPaid = isPaid.not();
  const [paidShipments, unpaidShipments] = Assertion.group(shipments, [isPaid, isNotPaid]);

  // Show a warning message for unpaid shipments when
  // the shipment is not in dispute or payments pending.
  const shouldShowWarning = shipmentAssertions
    .isStatusDispute.not()
    .andNot(shipmentAssertions.isStatusPaymentsPending);

  const unpaidShipmentMap = shipment => ({
    bolNumber: shipment.bolNumber,
    customerName: shipment.customer.name,
    paymentTotal: shipment.invoice.adjustedCustomerCost,
    statusType: shipmentStatusTypes.find(st => st.id === shipment.statusId).name,
    warningMessage: shouldShowWarning.matches(shipment) ?
      StatusWarningMessage : null
  });

  const paidShipmentMap = shipment => ({
    bolNumber: shipment.bolNumber,
    customerName: shipment.customer.name,
    paymentTotal: shipment.invoice.adjustedCustomerCost,
    paymentDate: shipment.invoice.customerPaidDate,
    statusType: shipmentStatusTypes.find(st => st.id === shipment.statusId).name
  });

  const paidShipmentRecordsList = paidShipments.map(paidShipmentMap);
  const paidShipmentRecords = bolNumbers
    .map(bolNum => paidShipmentRecordsList.find(shipment => shipment.bolNumber === bolNum))
    .filter(x=>x);

  const unpaidShipmentsList = unpaidShipments.map(unpaidShipmentMap);
  const unpaidShipmentRecords = bolNumbers
    .map(bolNum => unpaidShipmentsList.find(shipment => shipment.bolNumber === bolNum))
    .filter(x=>x);
  
  const handleConfirmSubmitClick = () =>
    setShowSubmitConfirmation(true);

  const handleCancelConfirmClick = () =>
    setShowSubmitConfirmation(false);

  const handleConfirmClick = () => {
    setShowSubmitConfirmation(false);
    markShipmentsCustomerPaid(unpaidShipments.map(s => s.bolNumber));
  };

  const actionButtons = (
    <AppButton onClick={handleConfirmSubmitClick} disabled={unpaidShipments.length === 0} style={{ lineHeight: '17px' }}>
      Submit Payments
    </AppButton>
  );

  return (
    <FullWidthLayout SideNav={LeftNav} title={'Customer Payments'}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={8} md={5}>
              <AppInput
                onChange={handleBolNumber}
                value={workingBolNumbers}
                multiline
                rows={2}
              />
              <span className={classes.disabledText}>Separate multiple shipment numbers with a comma.</span>
            </Grid>
            <Grid item xs={4} md={5}>
              <Grid container spacing={1} direction="column">
                <Grid item>
                  <AppButton onClick={handleLookupShipmentsClick} size="small" disabled={!workingBolNumbers}>Lookup Shipments</AppButton>
                </Grid>
                <Grid item>
                  <AppButton onClick={handleClearClick} size="small" disabled={!workingBolNumbers}>Clear</AppButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <UnpaidShipmentsTable
            data={unpaidShipmentRecords}
            actionButtons={actionButtons}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <PaidShipmentsTable
            data={paidShipmentRecords}
          />
        </Grid>
      </Grid>
      {
        showSubmitConfirmation &&
        <SubmitPaymentsConfirmationDialog
          bolNumbers={unpaidShipmentRecords.map(s => s.bolNumber)}
          onConfirmClick={handleConfirmClick}
          onCancelClick={handleCancelConfirmClick}
        />
      }
    </FullWidthLayout>
  )
}
export default ComponentBuilder
  .wrap(CustomerPaymentsPage)
  .stateToProps((state, ownProps) => {
    return {
      freightCategoryTypes: state.support.freightCategoryTypes,
      shipments: state.reporting.customerPayments.shipments,
      shipmentStatusTypes: state.support.shipmentStatusTypes
    };
  })
  .dispatchToProps((shell, dispatch, getState) => {
    return {
      dispose: async () => {
        dispatch(await shell.actions.reporting.customerPayments.dispose());
      },
      loadCustomerPaymentShipments: async (bolNumbers) => {
        dispatch(shell.actions.sys.processStart(LoadProcessName));
        dispatch(await shell.actions.reporting.customerPayments.loadCustomerPaymentShipments(bolNumbers));
        dispatch(shell.actions.sys.processComplete(LoadProcessName));
      },
      markShipmentsCustomerPaid: async (invoices) => {
        dispatch(shell.actions.sys.processStart(LoadProcessName));
        dispatch(await shell.actions.reporting.customerPayments.markShipmentsCustomerPaid(invoices));
        dispatch(shell.actions.sys.processComplete(LoadProcessName));
      },
    }
  })
  .build();