import React from 'react';
import AppDenseGrid from '../../../../core/components/AppDenseGrid/AppDenseGrid';
import AppCurrencyText from '../../../../core/components/text/AppCurrencyText';
import AppIconButton from '../../../../core/components/AppButton/AppIconButton';
import {FindInPage as FindInPageIcon} from '@material-ui/icons';

const CheckPayoutCell = ({ rowData: record }) => {
  if (!record.invoiceActualDeliveryDate)
    return null;

  return (<AppCurrencyText value={record.invoiceEarnedPayout} />);
};

const ShipmentHistoryListing = (props) => {

  const {
    count,
    data = [],
    orderBy,
    order,
    rowsPerPage,
    page,
    onChangePage,
    onChangeRowsPerPage,
    onSort,
    onDocumentQuickViewClick
  } = props;

  const GridColumns = [
    {
      field: 'shipmentBolNumber',
      title: 'Ship #',
      dataType: 'component',
      styles: {
        width: 60
      },
      component: AppDenseGrid.Cells
        .LinkCell((record) => [`/shipment/${record.shipmentBolNumber}/details`, record.shipmentBolNumber])
    },
    {
      field: 'shipmentBolDate',
      title: 'Pickup Date',
      dataType: 'date',
      styles: {
        width: 80
      }
    },
    {
      field: 'carrierName',
      title: 'Carrier',
      dataType: 'component',
      styles: {
        width: 160
      },
      component: AppDenseGrid.Cells
        .LinkCell((record) => [`/carrier/${record.carrierMcNumber}/details`, record.carrierName])
    },
    {
      field: 'shipmentStatusName',
      title: 'Status',
      dataType: 'string',
      styles: {
        width: 80
      }
    },
    {
      field: 'carrierCost',
      title: 'Carrier Cost',
      dataType: 'currency',
      styles: {
        width: 80
      }
    },
    {
      field: 'invoiceTgfFee',
      title: 'TGF Fee',
      dataType: 'currency',
      styles: {
        width: 80
      }
    },
    {
      field: 'customerCost',
      title: 'Cust. Cost',
      dataType: 'currency',
      styles: {
        width: 80
      }
    },
    {
      field: 'invoicePaidDate',
      title: 'Cust. Paid Date',
      dataType: 'date',
      styles: {
        width: 80
      }
    },
    {
      field: 'totalMargin',
      title: 'Total Margin',
      dataType: 'currency',
      styles: {
        width: 80
      }
    },
    {
      field: 'invoiceActualDeliveryDate',
      title: 'Locked Date',
      dataType: 'date',
      styles: {
        width: 80
      }
    },
    {
      field: 'invoiceEarnedPayout',
      title: 'Payout',
      styles: {
        width: 80
      },
      dataType: 'component',
      component: CheckPayoutCell
    },
    {
      field: 'commissionPayoutDate',
      title: 'Payout Date',
      dataType: 'date',
      styles: {
        width: 80
      }
    },
    {
      field: '',
      title: '',
      component: ({ rowData: shipmentRecord }) => {
        return (
          <div style={{textAlign: 'center'}}>
            <AppIconButton Icon={FindInPageIcon} tooltip={'Quick view the shipment\'s documents.'} onClick={() => onDocumentQuickViewClick(shipmentRecord)}/>
          </div>
        );
      },
      styles: {
        width: 40
      },
      dataType: 'component'
    }
  ];

  const rowProps = (props) => props.invoiceDaysPastDue > 0 ? 'warning' : null;

  return (
    <AppDenseGrid
      count={count || 0}
      data={data}
      orderBy={orderBy}
      order={order}
      rowsPerPage={rowsPerPage}
      page={page}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      onSort={onSort}
      columns={GridColumns}
      rowProps={rowProps}
    />
  )
}

export default ShipmentHistoryListing;    