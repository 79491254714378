import React from 'react';
import {Link} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';

const NoOpProps = () => ({});

const useStyles = makeStyles((theme) => ({
  linkCell: {
    textDecoration: 'none',
    color: theme.palette.tgfLink.main,
    fontWeight: 'bold',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}));

const LinkCell = (partsSelector, additionalProps = NoOpProps) => {
  const classes = useStyles();

  return ({ rowData }) => {
    const [url, title] = partsSelector(rowData);
    const otherProps = additionalProps(rowData);
    return (
      <Link to={url} className={classes.linkCell} {...otherProps}>{title}</Link>
    );
  };
};

export default LinkCell;