import ActionBuilder from "../../../ActionBuilder";
import GatewayFacade from "../../../GatewayFacade";
import ProcessTypes from "../../../ProcessTypes";
import ActionTypes from "../../../ActionTypes";

const loadShipmentInvoice = async ({shell}, bolNumber) => {

  const gateway = new GatewayFacade(shell.communicator);
  const invoice = await gateway.getInvoice(bolNumber);

  return {
    type: ActionTypes.Shipment.Modification.InvoiceLoaded,
    invoice
  }
};

export default ActionBuilder
  .for(loadShipmentInvoice)
  .process(ProcessTypes.Shipment.Modification.InvoiceLoading)
  .build();