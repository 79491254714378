import ActionBuilder from "../../../ActionBuilder";
import ActionTypes from '../../../ActionTypes';

const dispose = async (context) => {
    return {
        type: ActionTypes.Reporting.CustomerReport.Disposed
    };
};

export default ActionBuilder
    .for(dispose)
    .build();