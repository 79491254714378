import ActionBuilder from '../../../ActionBuilder2';
import GatewayFacade from '../../../GatewayFacade';
import ActionTypes from '../../../ActionTypes';

const saveShipmentTrackingNote = async (shell, note) => {

  const gateway = new GatewayFacade(shell.communicator);

  const trackingNote = gateway.createShipmentTrackingNote();
  trackingNote.bolNumber = note.bolNumber;
  trackingNote.associateId = note.associateId;
  trackingNote.trackingDate = trackingNote.createdDate = new Date();
  trackingNote.note = note.note.trim();
  trackingNote.loadTrackingPhaseId = note.loadTrackingPhaseId;

  const updateTrackingNote = await gateway.saveLoadTrackingNote(trackingNote);

  const loadTrackingNotes = await gateway.getAllShipmentTrackingNotes(note.bolNumber);

  return {
    type: ActionTypes.Shipment.SaveTrackingNote.Saved,
    trackingNote: updateTrackingNote,
    loadTrackingNotes
  }
};

export default ActionBuilder
  .for(saveShipmentTrackingNote)
  .build();
