import React from 'react';
import {
  Grid,
  Container,
  CssBaseline,
  CardMedia,
  Button
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import backgroundTrucking from '../../core/media/MovingTruckBackground.png';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0
  },
  contentCenter: {
    position: "absolute",
    top: "50%",
    left: "50%",
    zIndex: "3",
    transform: "translate(-50%,-50%)",
    textAlign: "center",
    color: theme.palette.black,
    padding: "0 15px",
    width: "100%",
    maxWidth: "880px"
  },
  backgroundLogo: {
    height: '100vh',
    width: '100vw',
    backgroundImage: `url(${backgroundTrucking})`
  },
  subTitle: {
    fontSize: "2.25rem",
    marginTop: "0",
    marginBottom: "8px"
  },
  description: {
    fontSize: "1.125rem",
    marginTop: "0",
    marginBottom: "8px"
  }
}));

const Error404Page = (props) => {
  const classes = useStyles();

  return (
    <CardMedia component={'div'} className={classes.backgroundLogo}>
      <Container component={'main'} maxWidth={'sm'} className={classes.root}>
        <CssBaseline/>
        <div className={classes.contentCenter}>
          <Grid container spacing={6} direction={'column'}>
            <Grid item>
              <h2 className={classes.subTitle}>Page not found - 404</h2>
            </Grid>
            <Grid item>
              <h4 className={classes.description}>
                Oops! Looks like you got lost.
              </h4>
            </Grid>
            <Grid item>
              <Button component={Link} to={'/'} variant={'contained'}>Return Home</Button>
            </Grid>
          </Grid>
        </div>
      </Container>
    </CardMedia>
  );

};

export default Error404Page;