import createDocs from './createDocs';
import customer from './customer';
import duplication from './duplication';
import equipment from './equipment';
import freight from './freight';
import init from './init';
import loadBoard from './loadBoard';
import loadTrackedSetCounts from './loadTrackedSetCounts';
import loadTracking from './loadTracking';
import modification from './modification';
import openLoad from './openLoad';
import statusing from './statusing';

export default {
  createDocs,
  customer,
  duplication,
  equipment,
  freight,
  init,
  loadBoard,
  loadTrackedSetCounts,
  loadTracking,
  modification,
  openLoad,
  statusing
};