import React from 'react';
import ReactDom from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import {ThemeProvider} from '@material-ui/core/styles';

import oe from './oe';
import App from './App';
import theme from './theme';

const startAndRender = async () => {

  const shell = await oe.start();

  const root = (
    <Provider store={shell.stateStore}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  );

  ReactDom.render(root, document.getElementById('root'));

};

startAndRender()
  .catch(err => console.error(err));