const PackageTypeNames = {
  Crate: 'Crate',
  Pallet: 'Pallet',
  Machine: 'Machine',
  Bunch: 'Bunch',
  Coil: 'Coil',
  Bale: 'Bale',
  Bag: 'Bag',
  Cylinder: 'Cylinder',
  Drum: 'Drum',
  Pail: 'Pail',
  Roll: 'Roll',
  TubePipe: 'Tube-Pipe',
  Tote: 'Tote',
  Box: 'Box',
  Carton: 'Carton',
  Bundle: 'Bundle',
  Reel: 'Reel',
  Piece: 'Piece',
  Case: 'Case',
};

export default PackageTypeNames;