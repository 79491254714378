import ActionBuilder from '../../../ActionBuilder';
import ProcessTypes from '../../../ProcessTypes';
import FreightCategoryTypeNames from '../../FreightCategoryTypeNames';
import PackageTypeNames from '../../PackageTypeNames';
import ActionTypes from '../../../ActionTypes';

const DefaultPackageTypeName = PackageTypeNames.Pallet;

const ensureFreightCategory = (freightCategories, name) => {
  const freightCategory = freightCategories.find(fc => fc.name === name);
  if (!freightCategory) {
    throw new Error(`Unable to find freight category [${name}] in the categories received from the gateway.`);
  }
  return freightCategory;
};

const load = async ({ shell }) => {

  const [
    freightCategories,
    freightClasses,
    packageTypes,
    truckloadValueConfirmationTypes,
    nmfc
  ] = await Promise.all([
    shell.communicator.get(`/shipment-freight-category`),
    shell.communicator.get(`/shipment-freight-class`),
    shell.communicator.get(`/shipment-package-type`),
    shell.communicator.get(`/shipment-truckload-value-confirmation-type`),
    shell.communicator.get(`/shipment-nmfc`)
  ]);

  const ltlFreightCategory = ensureFreightCategory(freightCategories, FreightCategoryTypeNames.LessThanLoad);
  const tlFreightCategory = ensureFreightCategory(freightCategories, FreightCategoryTypeNames.Truckload);
  const defaultFreightCategory = tlFreightCategory;

  const defaultPackageType = packageTypes.find(pt => pt.name === DefaultPackageTypeName);

  if (!defaultPackageType) {
    throw new Error(`Default package type [${DefaultPackageTypeName}] could not be found in package types received from the gateway`);
  }

  return {
    type: ActionTypes.Shipment.Freight.Loaded,
    freightCategories,
    freightClasses,
    ltlFreightCategory,
    tlFreightCategory,
    defaultFreightCategory,
    packageTypes,
    defaultPackageType,
    truckloadValueConfirmationTypes,
    nmfc
  };

};

export default ActionBuilder
  .for(load)
  .process(ProcessTypes.Shipment.Freight.Loading)
  .build();