import React from 'react';
import {Grid} from '@material-ui/core';
import AppSwitch from '../../../core/components/inputs/AppSwitch/AppMuiSwitch';
import AppMuiCard from '../../../core/components/cards/AppCard';
import { Typography, Divider, Tooltip } from '@material-ui/core';
import { CheckCircleOutline, HighlightOff, HelpOutline } from '@material-ui/icons';
import ComponentBuilder from '../../../core/ComponentBuilder';

const CarrierOptionsCard = (props) => {

  const {
    blackListed,
    isCertified,
    setFieldValue,
    tgfOverride,
    isDisabled,
    classes
  } = props;

  const handleCheckboxChange = (key) => (e) => {
    setFieldValue(key, e.target.checked);
  };

  return (
    <AppMuiCard title={'Options'}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <AppSwitch
            label={'Carrier Is Blacklisted'}
            disabled={isDisabled}
            checked={blackListed || false}
            onChange={handleCheckboxChange('blackListed')}
          />
        </Grid>
        <Grid item xs={12}>
          <AppSwitch
            label={'RMIS Override / Marked Good To Go'}
            disabled={isDisabled}
            checked={tgfOverride || false}
            onChange={handleCheckboxChange('tgfOverride')}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <RmisCertificationStatus
            isCertified={isCertified}
            classes={classes}
            />
        </Grid>
      </Grid>
    </AppMuiCard>
  );
};

const RmisCertificationStatus = (props) => {

  const {
    isCertified,
    classes
  } = props;

  const label = isCertified === null ?
    'Carrier not linked to RMIS' :
    isCertified ?
      'Carrier is RMIS Compliant' :
      'Carrier is not RMIS Compliant';

  return (
    <Grid container spacing={1}>
      <Grid item xs={1}>
        <RmisCertificationIcon isCertified={isCertified} classes={classes} />
      </Grid>
      <Grid item xs={11}>
        <Typography variant={'body1'} className={classes.label}>
          {label}
        </Typography>
      </Grid>
    </Grid>
  );
};

const RmisCertificationIcon = (props) => {
  const {
    isCertified,
    classes
  } = props;

  switch (isCertified) {
    case true:
      return <CheckCircleOutline className={classes.rmisSuccess} />;
    case false:
      return <HighlightOff className={classes.rmisFail} />;
    case null:
    default:
      return <HelpOutline className={classes.rmisNotLinked} />
  }
};

const styles = (theme) => ({
  label: {
    marginTop: 3
  },
  rmisSuccess: {
    color: theme.palette.success.main
  },
  rmisFail: {
    color: theme.palette.danger.main
  },
  rmisNotLinked: {
    color: theme.palette.warning.main
  }
});

export default ComponentBuilder
  .wrap(CarrierOptionsCard)
  .withStyles(styles)
  .build();