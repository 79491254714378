import ActionTypes from '../../../ActionTypes';

const defaultLtlEquipmentType = (state = null, action) => {
  switch (action.type) {
    case ActionTypes.Shipment.Equipment.Loaded:
      return action.defaultLtlEquipmentType;
    default:
      return state;
  }
};

export default defaultLtlEquipmentType;