import ActionTypes from '../../../ActionTypes';

const truckloadValueConfirmationTypes = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.Shipment.Freight.Loaded:
      return action.truckloadValueConfirmationTypes;
    default:
      return state;
  }
};

export default truckloadValueConfirmationTypes;