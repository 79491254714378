import React from 'react';
import ComponentBuilder from '../../../core/ComponentBuilder';
import AddressBookRecordNav from '../AddressBookRecordNav';
import composePageTitle from '../../../core/utils/composePageTitle';
import FullWidthLayout from '../../../core/layouts/FullWidthLayout';
import {Grid, Paper} from '@material-ui/core';

const OutcomeDisplay = ComponentBuilder.wrap(({ outcome, expanded = false, classes, staticContext, children, ...otherProps }) => {

  const outcomeData = expanded ?
    (
      <pre>
        {JSON.stringify(outcome, null, 2)}
      </pre>
    ) :
    JSON.stringify(outcome);

  return (
    <Paper className={classes.root} {...otherProps}>
      {outcomeData}
    </Paper>
  );
})
  .withStyles((theme) => ({
    root: {
      padding: 5,
      borderRadius: 5,
      cursor: 'pointer'
    }
  }))
  .build();

const OutcomeStreamPage = (props) => {

  const {
    company,
    load,
    match
  } = props;

  const companyId = parseInt(match.params.id);

  const [companyOutcomes, setCompanyOutcomes] = React.useState([]);
  const [selectedOutcomes, setSelectedOutcomes] = React.useState(new Set());

  React.useEffect(() => {
    load(companyId)
      .then((outcomes) => {
        setCompanyOutcomes(outcomes);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const title = composePageTitle('Address Book', 'Outcome Stream', company?.name);

  const toggleOutcomeSelection = (outcome) => {
    if (selectedOutcomes.has(outcome)) {
      setSelectedOutcomes(
        new Set(Array.from(selectedOutcomes.values()).filter((o) => o !== outcome))
      );
    }
    else {
      setSelectedOutcomes(
        new Set(Array.from(selectedOutcomes.values()).concat([outcome]))
      );
    }
  };

  return (
    <FullWidthLayout SideNav={AddressBookRecordNav} title={title}>
      <Grid container spacing={2} direction={'row'}>
        {
          companyOutcomes.map((outcome, index) => {
            return (
              <Grid item xs={12} key={index}>
                <OutcomeDisplay
                  expanded={selectedOutcomes.has(outcome)}
                  outcome={outcome}
                  onClick={() => toggleOutcomeSelection(outcome)}
                />
              </Grid>
            );
          })
        }
      </Grid>
    </FullWidthLayout>
  );
};

export default ComponentBuilder
  .wrap(OutcomeStreamPage)
  .dispatchToProps((shell, dispatch, getState) => ({
    async load(companyId) {
      // HACK: Man, redux needs to go away...
      dispatch(await shell.actions.addressBook.modification.loadCompany(companyId));
      return await shell.gateway.communicator.get(`/company/${companyId}/outcome-stream`);
    }
  }))
  .build();