import Core from '@atomos/core';

const FullNames = [
  // ADDRESS BOOK
  'AddressBook.Initializing',
  'AddressBook.Addressing.Loading',
  'AddressBook.Entries.IdentifyingCompany',
  'AddressBook.Listing.Loading',
  'AddressBook.Modification.AffiliatesSearching',
  'AddressBook.Modification.DetailsLoading',
  'AddressBook.Modification.CompanyLoading',
  'AddressBook.Modification.CompanyAffiliateLoading',
  'AddressBook.Modification.CompanyContactsLoading',
  'AddressBook.Modification.CompanyContactSaving',
  'AddressBook.Modification.CompanyContactDeleting',
  'AddressBook.Modification.CompanyCreditStatusLoading',
  'AddressBook.Modification.CompanyFirstShipmentLoading',
  'AddressBook.Modification.CompanyNotesLoading',
  'AddressBook.Modification.CompanyAgingNotesLoading',
  'AddressBook.Modification.CompanyNoteSaving',
  'AddressBook.Modification.CompanyAgingNoteSaving',
  'AddressBook.Modification.CompanyAffiliateLoading',
  'AddressBook.Modification.Saving',
  'AddressBook.Payment.Loading',
  'AddressBook.Searching.SearchingCustomers',
  'AddressBook.Searching.SearchingCompanies',

  // CARRIER DIRECTORY
  'CarrierDirectory.Initializing',
  'CarrierDirectory.Entries.IdentifyingCarrier',
  'CarrierDirectory.Searching.SearchingCarriers',

  // PERSONA
  'Persona.Initializing',
  'Persona.AssociatesLoading',
  'Persona.LoadingDeliveredCount',
  'Persona.LoadingAnnouncementNote',
  'Persona.SavingAnnouncementNote',
  'Persona.LoadingMonthlyMetrics',
  'Persona.Authenticating',
  'Persona.Identifying',

  // Reporting
  'Reporting.LaneLookup.Loading',
  'Reporting.LaneLookup.Load',
  'Reporting.LaneLookup.Loaded',
  'Reporting.InvoiceAging.Loading',
  'Reporting.InvoiceAging.Load',
  'Reporting.InvoiceAging.Loaded',
  'Reporting.SearchLoads.Loading',
  'Reporting.SearchLoads.Load',
  'Reporting.SearchLoads.Loaded',

  // SHIPMENT
  'Shipment.Initializing',
  'Shipment.Equipment.Loading',
  'Shipment.Freight.Loading',
  'Shipment.Loading',
  'Shipment.LoadTrackingPhases.Loading',
  'Shipment.LoadTrackingNotes.Loading',
  'Shipment.SaveTrackingNote.Saving',
  'Shipment.Statusing.Loading',
  'Shipment.Customer.SearchingCustomerShipments',
  'Shipment.LoadBoard.Loading',
  'Shipment.LoadBoard.ShipmentDocuments',
  'Shipment.Modification.ContentsLoading',
  'Shipment.Modification.ContentsSaving',
  'Shipment.Modification.ShipmentLoading',
  'Shipment.Modification.SavingShipment',
  'Shipment.Modification.InvoiceLoading',
  'Shipment.Modification.InvoiceSaving',
  'Shipment.Modification.Loading',
  'Shipment.Modification.Saving',
  'Shipment.Reporting.RetrieveAllShipments',
  'Shipment.Reporting.RetrievingCustomerFirstShipment'
];

const ProcessTypes = {};

FullNames.forEach(name => Core.Utils.set(ProcessTypes, name, name));

export default ProcessTypes;