import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import Core from "@atomos/core";

import AppCard from '../../../core/components/cards/AppCard';
import FullWidthLayout from '../../../core/layouts/FullWidthLayout';
import AppTable from '../../../core/components/AppTable';
import ComplianceValueList from './ComplianceValueList';
import CarrierSummaryCard from './CarrierSummaryCard';
import ComponentBuilder from '../../../core/ComponentBuilder';

import When from '../../../core/components/condtionals/When';
import CarrierNotMonitoredAlert from './CarrierNotMonitoredAlert';
import CarrierRecordNav from '../CarrierRecordNav';
import CarrierComplianceRules from "../../../hubs/carrier/rules/CarrierComplianceRules";
import GatewayFacade from "../../../hubs/GatewayFacade";
import AuthorityStatuses from '../../../hubs/carrier/AuthorityStatuses';
import FreightCategoryTypeNames from "../../../hubs/shipment/FreightCategoryTypeNames";
import CarrierLtlAlert from './CarrierLtlAlert';
import AppDateText from '../../../core/components/text/AppDateText';
import AppCurrencyText from '../../../core/components/text/AppCurrencyText';

const LoadProcessName = 'Carrier.CompliancePage.Load';

const carrierContactModel = {
  type: null,
  name: null,
  title: null,
  phoneNumber: null,
  mobileNumber: null,
  email: null,
  faxNumber: null
}

const headers = [
  {
    title: 'Description'
  },
  {
    title: 'Contact'
  },
  {
    title: 'Title'
  },
  {
    title: 'Phone Number',
  },
  {
    title: 'Cell Number',
  },
  {
    title: 'Email'
  },
  {
    title: 'Fax',
  }
];

const CompliancePage = (props) => {

  const {
    carrier,
    carrierHistory,
    carrierHistoryCounts,
    dispose,
    freightCategoryTypes,
    load,
    match
  } = props

  const mcNumber = match.params.id;

  React.useEffect(() => {
    load(mcNumber);

    return () => dispose();
  }, [mcNumber]);

  const title = carrier ?
    `Carrier - Compliance - ${carrier.name} (${mcNumber})` :
    'Carrier - Compliance';

  const isLtl = carrier && freightCategoryTypes &&
    freightCategoryTypes.find(fct => fct.id === carrier.freightCategoryId).name === FreightCategoryTypeNames.LessThanLoad;

  const isMonitored = Boolean(carrier) && Boolean(carrier.rmisCarrier);

  return (
    <FullWidthLayout SideNav={CarrierRecordNav} title={title}>
      <Grid container spacing={2}>
        <When condition={isLtl}>
          <Grid item>
            <CarrierLtlAlert />
          </Grid>
        </When>
        <When condition={!isLtl}>
          <When condition={isMonitored}>
            {renderMonitored({carrier, carrierHistory, carrierHistoryCounts, ...props})}
          </When>
          <When condition={!isMonitored}>
            <Grid item>
              <CarrierNotMonitoredAlert />
            </Grid>
          </When>
        </When>
      </Grid>
    </FullWidthLayout>
  )
}

const extractInsuranceData = (carrier, type) => {

  const insCoverage = carrier.rmisInsuranceCoverage &&
    carrier.rmisInsuranceCoverage.find(ic => ic.description === type);

  if (!insCoverage) {
    return { expirationDate: null, amount: null };
  }

  const insCoverageLimit = insCoverage.rmisInsuranceCoverageLimit
    .find(l => l.synonym === type.toLowerCase());

  return {
    expirationDate: insCoverage.expirationDate ?
      GatewayFacade.makeDateOnly(insCoverage.expirationDate) : null,
    amount: insCoverageLimit ?
      insCoverageLimit.amount : null
  };
}

const groupComplianceData = (carrier) => {
  if (!carrier)
    return null;

  const operatingStatusAuthorized = CarrierComplianceRules
    .passesOperatingStatusAuthorizedRule(carrier);
  const dotSafetyRating = CarrierComplianceRules
    .passesDotSafetyRatingRule(carrier);
  const dotAuthority = CarrierComplianceRules
    .passesAuthorityRule(carrier);

  const certificationDate = carrier.rmisCarrier && carrier.rmisCarrier.certificationDate && GatewayFacade.makeDate(carrier.rmisCarrier.certificationDate);

  const autoInsurance = extractInsuranceData(carrier, 'AUTO');
  const cargoInsurance = extractInsuranceData(carrier, 'CARGO');

  const complianceData =
    [
      {
        data: [
          {
            title: 'Good To Go:',
            value: carrier.isGoodToGo ? 'Yes' : 'No',
            type: carrier.isGoodToGo ? 'Pass' : 'Fail'
          },
          {
            title: 'Blacklisted:',
            value: carrier.blackListed ? 'Yes' : 'No',
            type: carrier.blackListed ? 'Fail' : 'Pass'
          },
          {
            title: 'RMIS Compliant:',
            value: carrier.rmisCarrier && carrier.rmisCarrier.isCertified ? 'Yes' : 'No',
            type: carrier.rmisCarrier && carrier.rmisCarrier.isCertified ? 'Pass' : 'Fail'
          },
          {
            title: 'RMIS Status Updated:',
            value: <AppDateText value={certificationDate} />,
            type: 'none'
          }
        ]
      },
      {
        data: [
          {
            title: 'Operating Status Rule:',
            value: operatingStatusAuthorized ? 'Pass' : 'Fail',
            type: operatingStatusAuthorized ? 'Pass' : 'Fail'
          },
          {
            title: 'DOT Authority Rule:',
            value: dotAuthority ? 'Pass' : 'Fail',
            type: dotAuthority ? 'Pass' : 'Fail'
          },
          {
            title: 'DOT Safety Rating Rule:',
            value: dotSafetyRating ? 'Pass' : 'Fail',
            type: dotSafetyRating ? 'Pass' : 'Fail'
          }
        ]
      },
      {
        data: [
          {
            title: 'Common Authority',
            value: carrier.rmisDot && AuthorityStatuses.getStatusName(carrier.rmisDot.commonAuthority),
            type: carrier.rmisDot && CarrierComplianceRules.passesActiveCommonAuthorityStatus(carrier) ?
              'Pass' : 'Fail'
          },
          {
            title: 'Contract Authority',
            value: carrier.rmisDot && AuthorityStatuses.getStatusName(carrier.rmisDot.contractAuthority),
            type: carrier.rmisDot && CarrierComplianceRules.passesActiveContractAuthorityStatus(carrier) ?
              'Pass' : 'Fail'
          },
          {
            title: 'Broker Authority',
            value: carrier.rmisDot && AuthorityStatuses.getStatusName(carrier.rmisDot.brokerAuthority),
            type: carrier.rmisDot && CarrierComplianceRules.passesActiveBrokerAuthorityStatus(carrier) ?
              'Pass' : 'Fail'
          },
          {
            title: 'Current Operating Status:',
            'valueTooltip': carrier.rmisDot && carrier.rmisDotTesting && carrier.rmisDotTesting.operatingStatus,
            value: carrier.rmisDot && operatingStatusAuthorized ? 'Authorized' : 'Not-authorized',
            type: carrier.rmisDot && operatingStatusAuthorized ? 'Pass' : 'Fail'
          },
          {
            title: 'Current Safety Rating',
            value: carrier.rmisDotTesting && carrier.rmisDotTesting.safetyRating,
            type: dotSafetyRating ? 'Pass' : 'Fail'
          }
        ]
      },
      {
        data: [
          {
            title: 'Cargo Liability Limit',
            value: (<AppCurrencyText value={cargoInsurance.amount} minFractionDigits={0} maxFractionDigits={0} />),
            type: 'none'
          },
          {
            title: 'Cargo Expiration',
            value: <AppDateText value={cargoInsurance.expirationDate} />,
            type: 'none'
          },
          {
            title: 'Auto Liability Limit',
            value: (<AppCurrencyText value={autoInsurance.amount} minFractionDigits={0} maxFractionDigits={0} />),
            type: 'none'
          },
          {
            title: 'Auto Expiration',
            value: <AppDateText value={autoInsurance.expirationDate} />,
            type: 'none'
          }
        ]
      }
    ]

  return complianceData;

}

const renderMonitored = (props) => {

  const {
    carrier,
    carrierHistory,
    carrierHistoryCounts
  } = props;

  const contactData = carrier && carrier.rmisCarrierContact && carrier.rmisCarrierContact
    .map((contact) => GatewayFacade.extract(contact, carrierContactModel))
    .map((coerce) => {
      const overrides = {
        phoneNumber: coerce.phoneNumber && GatewayFacade.makePhone(coerce.phoneNumber),
        faxNumber: coerce.faxNumber && GatewayFacade.makePhone(coerce.faxNumber),
        mobileNumber: coerce.mobileNumber && GatewayFacade.makePhone(coerce.mobileNumber)
      };
      return Core.Utils
        .merge({}, carrierContactModel, coerce, overrides);
    });
  const complianceDataGroup = groupComplianceData(carrier);

  const hasAddress = carrier && carrier.address1 &&
    carrier.city &&
    carrier.stateProvince &&
    carrier.postalCode;

  return (
    <React.Fragment>

      <Grid item xs={12} md={6}>
        <CarrierSummaryCard
          name={carrier && carrier.name}
          mcNumber={carrier && carrier.mcNumber || 'N/A'}
          dotNumber={carrier && carrier.dotNumber || 'N/A'}
          taxIdNumber={carrier && carrier.vendorTaxId || 'N/A'}
          totalPowerUnits={carrier && carrier.rmisDotTesting && carrier.rmisDotTesting.totalPowerUnits || 'N/A'}
          tgfShipmentCount={carrierHistoryCounts && carrierHistoryCounts || 'N/A'}
          lastShipmentDate={(carrierHistory && carrierHistory.length > 0 && <AppDateText value={carrierHistory[0].shipmentBolDate} />) || 'N/A'}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <AppCard>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant={'h4'}>
                Address:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <When condition={hasAddress}>
                {() =>
                  <React.Fragment>
                    <Typography variant={'h4'}>
                      {carrier.address1}
                    </Typography>
                    <When condition={carrier.address2}>
                      {() =>
                        <Typography variant={'h4'}>
                          {carrier.address2}
                        </Typography>
                      }
                    </When>
                    <Typography variant={'h4'}>
                      {carrier.city}, {carrier.stateProvince} {carrier.postalCode}
                    </Typography>
                  </React.Fragment>
                }
              </When>
              <When condition={!hasAddress}>
                No address on file.
              </When>
            </Grid>
          </Grid>
        </AppCard>
      </Grid>

      {
        complianceDataGroup && complianceDataGroup.map((group, index) => {
          return (
            <Grid item xs={12} md={3} key={index}>
              <ComplianceValueList
                complianceValues={group.data}
              />
            </Grid>
          );
        })
      }

      <Grid item xs={12}>
        <AppCard title="Carrier Contact Information">
          {carrier && contactData &&
            <AppTable headers={headers} data={contactData} />
          }
        </AppCard>
      </Grid>
    </React.Fragment>
  );
}

const styles = (theme) => ({
  pass: {
    color: theme.palette.success.main
  },
  fail: {
    color: theme.palette.danger.main,
    fontWeight: 600
  }
});

export default ComponentBuilder
  .wrap(CompliancePage)
  .withStyles(styles)
  .stateToProps((state, ownProps) => ({
    carrier: state.carrier.modification.carrier,
    carrierHistory: state.carrier.modification.history,
    carrierHistoryCounts: state.carrier.modification.historyCount,
    freightCategoryTypes: state.support.freightCategoryTypes
  }))
  .dispatchToProps((shell, dispatch, getState) => {
    return {
      async load(mcNumber) {
        dispatch(shell.actions.sys.processStart(LoadProcessName));

        const actions = await Promise.all([
          shell.actions.carrier.modification.loadCarrier(mcNumber),
          shell.actions.carrier.modification.loadHistory(mcNumber, 0, 1, [['shipmentBolNumber', 'desc']])
        ])
        actions.forEach(dispatch);

        dispatch(shell.actions.sys.processComplete(LoadProcessName));
      },
      async dispose() {
        dispatch(await shell.actions.carrier.modification.dispose())
      }
    }
  })
  .build();