import React from 'react';
import {Grid} from '@material-ui/core';
import AppDatePicker from '../../../core/components/inputs/AppDatePicker';
import AppMuiCard from '../../../core/components/cards/AppCard';
import AppNumericInput from '../../../core/components/inputs/AppNumericInput/AppMuiNumericInput';
import AppMuiInput from "../../../core/components/inputs/AppInput";
import AppValidationFailure from "../../../core/components/AppValidationFailure";

const CarrierInsuranceCard = (props) => {

  const {
    cargoLiabilityAmount,
    errors,
    generalLiabilityAmount,
    generalLiabilityExpirationDate,
    insuranceExpirationDate,
    setFieldValue,
    isDisabled
  } = props;

  const handleFieldChange = (key) => (e) => {
    setFieldValue(key, e.target.value)
  };

  const handleInsuranceExpirationDateChange = (date) => {
    const packetReceivedDate = date && date.isValid() ?
      date.toDate() : null;
    setFieldValue('insuranceExpirationDate', packetReceivedDate);
  };

  const handleLiabilityExpirationDateChange = (date) => {
    const packetReceivedDate = date && date.isValid() ?
      date.toDate() : null;
    setFieldValue('generalLiabilityExpirationDate', packetReceivedDate);
  };

  return (
    <AppMuiCard title={'Insurance'}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <AppNumericInput
            id={'cargoLiabilityAmount'}
            label={'Cargo Liability Amount'}
            decimalScale={2}
            disabled={isDisabled}
            value={cargoLiabilityAmount || ''}
            onChange={handleFieldChange('cargoLiabilityAmount')}
          />
        </Grid>
        <AppValidationFailure
          message={errors.cargoLiabilityAmount}
        />
        <Grid item xs={12}>
          <AppDatePicker
            id={'insuranceExpirationDate'}
            label={'Cargo Insurance Expires'}
            disabled={isDisabled}
            value={insuranceExpirationDate}
            onChange={handleInsuranceExpirationDateChange}
          />
        </Grid>
        <Grid item xs={12}>
          <AppNumericInput
            id={'generalLiabilityAmount'}
            label={'Auto Liability Amount'}
            decimalScale={2}
            disabled={isDisabled}
            value={generalLiabilityAmount || ''}
            onChange={handleFieldChange('generalLiabilityAmount')}
          />
          <AppValidationFailure
            message={errors.generalLiabilityAmount}
          />
        </Grid>
        <Grid item xs={12}>
          <AppDatePicker
            id={'generalLiabilityExpirationDate'}
            label={'Auto Insurance Expires'}
            disabled={isDisabled}
            value={generalLiabilityExpirationDate}
            onChange={handleLiabilityExpirationDateChange}
          />
        </Grid>
      </Grid>
    </AppMuiCard>
  )
};

export default CarrierInsuranceCard;