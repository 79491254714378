import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';
import GatewayFacade from '../../../GatewayFacade';

const saveCompanyContact = async (shell, contact) => {

  const gateway = new GatewayFacade(shell.communicator);

  const updatedContact = await gateway.saveCompanyContact(contact);

  const contacts = await gateway.getCompanyContacts(updatedContact.companyId);

  return {
    type: ActionTypes.AddressBook.Modification.CompanyContactSaved,
    contact,
    contacts
  };

};

export default ActionBuilder
  .for(saveCompanyContact)
  .build();