import ActionBuilder from '../../ActionBuilder2';
import ActionTypes from '../../ActionTypes';
import GatewayFacade from '../../GatewayFacade';

const loadAllAssociates = async (shell, options ) => {

  const gateway = new GatewayFacade(shell.communicator);

  const associates = await gateway.searchAllAssociates(options);

  return {
    type: ActionTypes.Persona.AllAssociatesLoaded,
    associates
  }
};

export default ActionBuilder
  .for(loadAllAssociates)
  .build();