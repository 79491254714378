import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';
import GatewayFacade from '../../../GatewayFacade';

const loadShipmentTrackingNotes = async (shell, bolNumber) => {

  const gateway = new GatewayFacade(shell.communicator);
  const loadTrackingNotes = await gateway.getAllShipmentTrackingNotes(bolNumber);

  return {
    type: ActionTypes.Shipment.LoadTrackingNotes.Loaded,
    loadTrackingNotes
  };
};

export default ActionBuilder
  .for(loadShipmentTrackingNotes)
  .build();
