import ActionBuilder from "../../../ActionBuilder";
import ActionTypes from '../../../ActionTypes';

const dispose = async (context) => {
  return {
    type: ActionTypes.Reporting.SearchLoads.Disposed
  };
};

export default ActionBuilder
  .for(dispose)
  .build();