import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Button, Menu, MenuItem, Hidden } from '@material-ui/core';
import { Person, Menu as MenuIcon } from '@material-ui/icons';

import ComponentBuilder from '../../ComponentBuilder';
import selectIsAce from '../../../hubs/persona/selectors/isAce';
import TgfLogo from '../../media/TgfLogo.svg';
import TopMenu from '../../layouts/includes/Menu/TopMenu';
import AppDrawer from '../AppDrawer';
import './AppNavBar.scss';
import AppNavDrawer from './AppNavDrawer';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  toolbar: {
    paddingLeft: 5,
    paddingRight: 15
  },
  title: {
    flexGrow: 1,
    display: 'flex'
  },
  newItem: {
    color: theme.palette.tgfBlack.main,
    '&:hover': {
      color: theme.palette.tgfBlack.main
    }
  },
  navButtons: {
    textDecoration: 'none',
    padding: 0,
    color: theme.palette.tgfBlack.main
  },
  removeOutline: {
    color: theme.palette.white,
    outline: 'none',
    opacity: .7,
    '&:focus': {
      outline: 'none',
      opacity: 1
    },
    '&:hover': {
      backgroundColor: theme.palette.tgfBlack.main,
      opacity: 1
    }
  },
  menu: {
    marginLeft: 'auto'
  },
  buttonNoStyle: {
    backgroundColor: 'none',
    '&:hover': {
      backgroundColor: 'none',
    }
  },
  menuDropdown: {
    marginTop: 30
  }
}));

const AppNavBar = (props) => {
  const classes = useStyles();

  const {
    associateName,
    logout,
    isAce
  } = props


  const [anchorEl, setAnchorEl] = useState(null);
  const [addOpen, setAddOpen] = useState(null);
  const openAddDropdown = Boolean(addOpen);
  const openDropdown = Boolean(anchorEl);
  const [toggleNav, setToggleNav] = useState(false);
  const currentLoc = window.location.pathname.split('/')[1];

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutClick = (e) => {
    logout();
    setAnchorEl(null);
}
  const handleAddClose = () => {
    setAddOpen(null);
  };

  const handleAddMenu = (event) => {
    setAddOpen(event.currentTarget);
  };

  const handleToggleNav = () => setToggleNav(!toggleNav);
  const handleMenuToggle = () => setAddOpen(!addOpen);

  const navLinks = [
    { title: 'Load Board', key: 'shipment', url: '/shipment/listing/working', isRestricted: false },
    { title: 'Address Book', key: 'address-book', url: '/address-book/listing', isRestricted: false },
    { title: 'Carriers', key: 'carrier', url: '/carrier/listing', isRestricted: false },
    { title: 'Open Loads', key: 'open-loads', url: '/open-loads', isRestricted: false },
    { title: 'Reports', key: 'reports', url: '/reports', isRestricted: false },
    { title: 'Admin', key: 'admin', url: '/admin', isRestricted: true },
    { title: 'Help', key: 'support', url: '/support/help', isRestricted: false },
    {
      title: 'Add', key: 'add', isRestricted: false,
      handleAddMenu: handleAddMenu,
      openAddDropdown: openAddDropdown,
      handleAddClose: handleAddClose,
      addOpen: addOpen,
      submenus: [
        {
          title: 'New Company',
          url: '/address-book/new/details'
        },
        {
          title: 'New Shipment',
          url: '/shipment/new/details'
        },
      ]
    },
  ];

  return (
    <>
      <AppBar>
        <Toolbar className={classes.toolbar} disableGutters>
          <Link to={'/'}>
            <img src={TgfLogo} className={'navbarLogo'} alt="TallGrassFreight logo" />
          </Link>
          <Hidden lgUp>
            <MenuIcon onClick={handleToggleNav} className={classes.menu} />
          </Hidden>
          <Hidden mdDown>
            <div className={classes.title}>
              <TopMenu currentLoc={currentLoc} navLinks={navLinks} isAce={isAce} />
            </div>
            <Button onClick={handleProfileMenuOpen} className={classes.removeOutline} endIcon={<Person />}>
              {associateName}
            </Button>
            <Menu
              id="logout"
              anchorEl={anchorEl}
              keepMounted
              open={openDropdown}
              onClose={handleProfileMenuClose}
              className={classes.menuDropdown}
            >
              <MenuItem onClick={handleLogoutClick}>Log Out</MenuItem>
            </Menu>
          </Hidden>
        </Toolbar>
      </AppBar>
      <AppDrawer open={toggleNav} toggle={handleToggleNav} onClose={handleToggleNav} anchor='right'>
        <div>
          <AppNavDrawer
            navLinks={navLinks}
            logout={handleLogoutClick}
            handleMenuToggle={handleMenuToggle}
            addOpen={addOpen}
            isAce={isAce} />
        </div>
      </AppDrawer>
    </>
  );

};

export default ComponentBuilder
  .wrap(AppNavBar)
  .stateToProps((state, ownProps) => {
    const associate = state.persona.associate;
    return {
      isAce: selectIsAce(state),
      associateName: `${associate.firstName} ${associate.lastName}`
    };
  })
  .dispatchToProps((shell, dispatch, getState) => {
    return {
      async logout() {
        dispatch(await shell.actions.persona.invalidate())
      }
    };
  })
  .build();