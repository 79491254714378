import ActionBuilder from '../../../ActionBuilder2';
import GatewayFacade from '../../../GatewayFacade';
import ActionTypes from '../../../ActionTypes';

const deleteShipmentContent = async (shell, bolNumber, shipmentContentId) => {

  const gateway = new GatewayFacade(shell.communicator);

  const deleteContent = await gateway.deleteShipmentContent(bolNumber, shipmentContentId);

  const loadContents = await gateway.getAllShipmentContents(bolNumber);

  return {
    type: ActionTypes.Shipment.Modification.ShipmentContentsDeleted,
    loadContents
  }
};

export default ActionBuilder
  .for(deleteShipmentContent)
  .build();
