const SiteDescriptorKey = 'tgf-crm-site-descriptor';
const OneMinuteInterval = 60000;

const FibonacciSequencer = (interval, maximum) => {
  // Start the sequence a few terms in, no need to query
  // for updates nearly immediately.
  const sequence = [2, 3];

  return () => {

    const [term1, term2] = sequence;
    const value = term1 + term2;

    if (value <= maximum) {
      sequence.shift();
      sequence.push(value);
    }

    return value * interval;
  };
};

const StaticSequencer = (interval) => () => interval;

class CacheBuster {

  static loadLocalDescriptor() {
    return JSON.parse(localStorage.getItem(SiteDescriptorKey) || '{}');
  }

  static saveLocalDescriptor(descriptor) {
    localStorage.setItem(SiteDescriptorKey, JSON.stringify(descriptor));
  }

  static async getServerDescriptor() {
    return await window.shell.gateway.getSiteDescriptor();
  }

  constructor(sequencer = StaticSequencer(OneMinuteInterval)) {
    this.timerId = null;
    this.sequencer = sequencer;
  }

  async validateVersion() {
    const localDescriptor = CacheBuster.loadLocalDescriptor();
    const serverDescriptor = await CacheBuster.getServerDescriptor();

    if (localDescriptor.version !== serverDescriptor.version) {
      CacheBuster.saveLocalDescriptor(serverDescriptor);
      window.location.reload(true);
    }
  }

  async activate() {

    // Perform initial check.
    await this.validateVersion();

    // Set timer to perform repeated checks.
    this.queueCheck();

  }

  clearTimer() {
    if (this.timerId) {
      clearTimeout(this.timerId);
    }
  }

  queueCheck() {

    this.clearTimer();

    this.timerId = setTimeout(() => {

      this.validateVersion();
      this.queueCheck();

    }, this.sequencer());

  }

  deactivate() {

    // Disable existing timer.
    this.clearTimer();

  }

}

export default CacheBuster;