import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Divider } from '@material-ui/core';
import { Publish } from '@material-ui/icons';

import ComponentBuilder from '../../../core/ComponentBuilder';

import FullWidthLayout from '../../../core/layouts/FullWidthLayout';
import isAce from '../../../hubs/persona/selectors/isAce';
import AddressBookRecordNav from "../AddressBookRecordNav";
import CompanyDocumentTypeNames from '../../../hubs/addressBook/CompanyDocumentTypeNames';

import AppDocumentDrawer from '../../../core/components/Documents/AppDocumentDrawer';
import AppDocumentRemoveFile from '../../../core/components/Documents/AppDocumentRemoveFile';
import AppDocumentFilters from '../../../core/components/Documents/AppDocumentFilters';
import AppComposeDocumentTypeColors from '../../../core/components/Documents/AppComposeDocumentTypeColors';

import AppButton from '../../../core/components/AppButton';
import AppDocumentCardGroup from '../../../core/components/Documents/AppDocumentCardGroup';
import composePageTitle from '../../../core/utils/composePageTitle';

const LoadProcessName = 'Company.DocumentsPage.Load';
const PublishProcessName = 'Company.DocumentsPage.Publish';
const DeleteProcessName = 'Company.DocumentsPage.Delete';

const useStyles = makeStyles(theme => ({
  noDocs: {
    marginTop: 50,
    textAlign: 'center'
  }
}));

const createDocColors = (isAce) => ({
  [CompanyDocumentTypeNames.CreditApplication]: {
    color: 'tgfBlack',
    isVisible: isAce
  },
  [CompanyDocumentTypeNames.Other]: {
    color: 'warning',
    isVisible: true
  }
});

const DocumentsPage = (props) => {
  const classes = useStyles();
  const [openNewDocument, setOpenNewDocument] = React.useState(false);
  const [removeFileOpen, setRemoveFileOpen] = React.useState(false);
  const [cardDocument, setCardDocument] = React.useState(null);
  const [documentId, setDocumentId] = React.useState(null);
  const [documentFilter, setDocumentFilter] = React.useState(0); //Defaults to All (0)
  const [animateIn, setAnimateIn] = React.useState(true);

  const {
    dispose,
    isAce,
    load,
    match,
    company,
    companyDocuments,
    deleteCompanyDocument,
    publishCompanyDocument,
    companyDocumentTypes,
    sendSnackbarMessage
  } = props;

  const companyId = parseInt(match.params.id);
  const docColors = createDocColors(isAce);
  const securedCategoryTypes = companyDocumentTypes
    .filter(docType => docColors[docType.name].isVisible);

  const coloredDocumentTypes = AppComposeDocumentTypeColors(companyDocumentTypes, docColors);
  const filterColoredDocumentTypes = AppComposeDocumentTypeColors(securedCategoryTypes, docColors);

  React.useEffect(() => {
    load(companyId);

    return () => dispose();
  }, [companyId]);

  const handleCloseNewDocument = () => setOpenNewDocument(false);
  const handleNewDocument = () => setOpenNewDocument(true);
  const handleRemoveFileClose = () => setRemoveFileOpen(false);

  const handlePublishClick = (companyDocument) => {
    publishCompanyDocument(companyId, companyDocument)
      .then(() => {
        setOpenNewDocument(false);
        sendSnackbarMessage({ content: 'Document uploaded.' });
      });
  };

  const handleOpenDownloadFileClick = (document, shouldOpen) => {
    const a = window.document.createElement('a');

    shouldOpen ? a.target = '_blank' : a.download = document.filename;

    a.href = `/file/company/${companyId}/document/${document.id}`;
    a.click();
    a.remove();
  };

  const handleRemoveFileClick = (document) => {
    setCardDocument(document);
    setDocumentId(document.id);
    setRemoveFileOpen(true);
  };

  const buildData = (filterItem) => {
    const cardCompanyDocuments = companyDocuments
      .filter(item => filterItem === 0 ? item : filterItem === item.typeId)
      .map(document => ({
        ...document,
        docType: coloredDocumentTypes.find(docType => docType.id === document.typeId),
        path: `/company/${document.companyId}/document/${document.id}`,
      }));
    return cardCompanyDocuments;
  };

  const handleRemoveFileConfirmClick = () => {
    setRemoveFileOpen(false);
    deleteCompanyDocument(companyId, documentId)
      .then(() => {
        sendSnackbarMessage({ content: 'Document deleted.' });
      });
  };

  const NoDocuments = () => (
        <Typography variant="h4" className={classes.noDocs}>No documents available.</Typography>
  );

  const handleFilter = (filterItem) => {
    setAnimateIn(false);
    setDocumentFilter(filterItem);
    setTimeout(() => {
      setAnimateIn(true);
    }, 300);
  };

  const filterDocuments = buildData(documentFilter);

  const options = [
    {
      label: 'Open',
      click: (document) => handleOpenDownloadFileClick(document, true),
      isVisible: true
    },
    {
      label: 'Download',
      click: (document) => handleOpenDownloadFileClick(document, false),
      isVisible: true
    },
    {
      label: 'Remove',
      click: (document) => handleRemoveFileClick(document),
      isVisible: isAce
    }
  ];

  const title = composePageTitle('Address Book', 'Documents', company?.name);

  return (
    <FullWidthLayout SideNav={AddressBookRecordNav}>
      <Grid container spacing={2}>
        <Grid item xs={6}><Typography variant="h3">{title}</Typography></Grid>
        <Grid item container xs={6} justify="flex-end">
          <AppButton onClick={handleNewDocument}>
            <Publish /> Upload
          </AppButton>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <AppDocumentFilters
            documentTypes={coloredDocumentTypes}
            onFilter={handleFilter}
            filterItem={documentFilter}
          />
        </Grid>
        <Grid item xs={12}>
          {
            filterDocuments.length > 0 ?
              <AppDocumentCardGroup
                documents={filterDocuments}
                options={options}
                animateIn={animateIn}
              />
              : <NoDocuments />
          }
        </Grid>
      </Grid>
      <AppDocumentDrawer
        anchor="right"
        documentTypes={filterColoredDocumentTypes}
        open={openNewDocument}
        onClose={handleCloseNewDocument}
        onPublish={handlePublishClick}
      />
      {cardDocument && <AppDocumentRemoveFile
        document={cardDocument}
        open={removeFileOpen}
        onClose={handleRemoveFileClose}
        onConfirmClick={handleRemoveFileConfirmClick}
      />}
    </FullWidthLayout>
  )
};

export default ComponentBuilder
  .wrap(DocumentsPage)
  .stateToProps((state, ownProps) => (
    {
      company: state.addressBook.modification.company,
      companyDocuments: state.addressBook.modification.companyDocuments,
      companyDocumentTypes: state.support.companyDocumentTypes,
      isAce: isAce(state)
    }))
  .dispatchToProps((shell, dispatch, getState) => {
    return {
      async load(companyId) {
        dispatch(shell.actions.sys.processStart(LoadProcessName));

        const actionResults = await Promise.all([
          shell.actions.addressBook.modification.loadCompany(companyId),
          shell.actions.addressBook.modification.loadCompanyDocuments(companyId)
        ]);

        actionResults.forEach(dispatch);

        dispatch(shell.actions.sys.processComplete(LoadProcessName));
      },
      async dispose() {
        dispatch(await shell.actions.addressBook.modification.dispose());
      },
      async deleteCompanyDocument(companyId, documentId) {
        dispatch(shell.actions.sys.processStart(DeleteProcessName));
        dispatch(await shell.actions.addressBook.modification.deleteCompanyDocument(companyId, documentId));
        dispatch(shell.actions.sys.processComplete(DeleteProcessName));
      },
      async publishCompanyDocument(companyId, companyDocument) {
        dispatch(shell.actions.sys.processStart(PublishProcessName));
        dispatch(await shell.actions.addressBook.modification.publishCompanyDocument(companyId, companyDocument));
        dispatch(shell.actions.sys.processComplete(PublishProcessName));
      },
      async sendSnackbarMessage(message) {
        dispatch(await shell.actions.sys.sendSnackbarMessage(message));
      }
    }
  })
  .build();
