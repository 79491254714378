import ActionTypes from '../../../ActionTypes';
import ActionBuilder from '../../../ActionBuilder';

const clearCustomerFirstShipment = (context, customerId) => {
  return {
    type: ActionTypes.Shipment.Customer.ClearedCustomerFirstShipment,
    customerId
  };
};

export default ActionBuilder
  .for(clearCustomerFirstShipment)
  .build();