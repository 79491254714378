import Lodash from 'lodash';
import ActionTypes from './ActionTypes';

const ActionBuilderInternalInvoke = Symbol.for('ActionBuilder.InternalInvoke');

/**
 * ActionBuilder enhances, or standardizes, complex action
 * invocation and error handling.
 */
class ActionBuilder {

  /**
   * Factory for creating the starting action.
   * @param {string} processName - The unique name for the process.
   * @param {object} [metadata] - Optional metadata to apply to the outgoing action.
   * @return {{processName: *, type: *}}
   */
  static createProcessStartingAction(processName, keySelector, metadata) {
    return Object.assign({}, metadata, {
      type: ActionTypes.Sys.ProcessStarting,
      processName,
      keySelector
    });
  }

  /**
   * Factory for creating the complete action.
   * @param {string} processName - The unique name for the process.
   * @param {object} [metadata] - Optional metadata to apply to the outgoing action.
   * @return {{processName: *, type: *}}
   */
  static createProcessCompleteAction(processName, keySelector, metadata) {
    return Object.assign({}, metadata, {
      type: ActionTypes.Sys.ProcessComplete,
      processName,
      keySelector
    });
  }

  /**
   * Factory for creating the uncaught error action.
   * @param {string} processName - The unique name for the process.
   * @param {Error} error - The error encountered.
   * @return {{processName: *, type: *, error: *}}
   */
  static createUncaughtProcessErrorAction(processName, error) {
    return {
      type: ActionTypes.Sys.UncaughtActionError,
      processName,
      error
    };
  }

  /**
   * Wrapper method for fluent creation of actions.
   * @param {function} delegate - The core function of the action.
   * @return {ActionBuilder}
   */
  static for(delegate) {

    if (!Lodash.isFunction(delegate)) {
      throw new Error(`[delegate] is required.`);
    }

    return new ActionBuilder(delegate);
  }

  /**
   * Initializes a new instance specifying the delegate
   * involved in carrying out the action.
   * @param {function} delegate - The core function of the action.
   */
  constructor(delegate) {
    this.delegate = delegate;
    this.processName = null;
    this.processKeySelector = null;
    this.processMetadataReducer = null;
    this.uncaughtErrorMap = null;
  }

  /**
   * Gives a name to an action's processing in order
   * to ease displaying spinners and what not.
   *
   * @param {string} processName - The unique name to give the process.
   * @param {function|null} [keySelector] - Optional selector to uniquely identify multiple processes.
   * @return {ActionBuilder}
   */
  process(processName, keySelector = null, metadataReducer = null) {
    this.processName = processName;
    this.processKeySelector = keySelector;
    this.processMetadataReducer = metadataReducer;
    return this;
  }

  /**
   * Assigns a function that can customize uncaught error
   * action messages before they are dispatched.
   * @param {function} actionMap - The map function for action messages.
   * @return {ActionBuilder}
   */
  uncaughtErrorActionMap(actionMap) {
    this.uncaughtErrorMap = actionMap;
    return this;
  }

  /**
   * Constructs the final function that will be used when
   * invoking the action.
   * @return {function}
   */
  build() {

    const processName = this.processName;
    const processKeySelector = this.processKeySelector;
    const processMetadataReducer = this.processMetadataReducer;
    const delegate = this.delegate;
    const uncaughtErrorMap = this.uncaughtErrorMap || Lodash.identity;

    /**
     * Action that receives N args needed for the delegate.
     * This is the function invoked by the developer.
     */
    return (...actionArgs) => {

      // Grab the shell for the action context.
      const shell = window.shell;

      // The thunk is just a shell to the internal invoke.
      // This separation allows Actions to call other actions
      // built with ActionBuilder and respect the promise chain.
      const thunk = async (dispatch, getState) => {

        const context = {
          shell,
          dispatch,
          getState
        };

        await thunk[ActionBuilderInternalInvoke](context);

      };

      // The internal invoke function that performs the work.
      const invoke = async (context) => 
      {
        // Be friendly for troubleshooting.
        try {

          // Notify app that we're starting a process.
          if (processName) {
            let metadata = {};
            if (processMetadataReducer) {
              metadata = processMetadataReducer(context, ...actionArgs);
            }
            context.dispatch(ActionBuilder.createProcessStartingAction(processName, processKeySelector, metadata));
          }

          // Invoke the delegate with the crm and any
          // args received.
          const delegateResult = await delegate(context, ...actionArgs);

          // Make delegation support arrays of actions as results.
          const actionsToDispatch = Lodash.concat([], delegateResult);

          // Identify all indexes for actions that are ActionBuilder thunks.
          const abThunkIndexes = actionsToDispatch
            .reduce((acc, action, index) => {
              return action && action[ActionBuilderInternalInvoke] ?
                acc.concat(index) : acc;
            }, []);

          // Execute each internal invoke function using the
          // current context.
          // TODO: Will not dispatching all immediately cause issues later?
          const abThunkInvokePromises = abThunkIndexes
            .map(i => {
              const actionInvoke = actionsToDispatch[i][ActionBuilderInternalInvoke];
              return actionInvoke(context);
            });

          // Await the results.
          const abThunkInvokeResults = await Promise.all(abThunkInvokePromises);

          // Replace the ActionBuilder thunk with the results.
          abThunkInvokeResults.forEach((result, index) => {
            const originalActionIndex = abThunkIndexes[index];
            actionsToDispatch[originalActionIndex] = result;
          });

          // Dispatch the actions.
          actionsToDispatch
            .filter(Lodash.identity) // Skip any undefined results.
            .forEach(context.dispatch);

          // Notify app that the process is complete.
          if (processName) {
            let metadata = {};
            if (processMetadataReducer) {
              metadata = processMetadataReducer(context, ...actionArgs);
            }
            context.dispatch(ActionBuilder.createProcessCompleteAction(processName, processKeySelector, metadata));
          }

        }
        catch (actionError) {

          if (process.env.NODE_ENV === 'development')
            console.error(actionError);

          // Allow adjustment of the final message.
          const errorMapResult = uncaughtErrorMap(ActionBuilder.createUncaughtProcessErrorAction(processName, actionError));

          // Make sure the errorMap supports arrays.
          const errorMapActions = Lodash.concat([], errorMapResult);

          errorMapActions.forEach(context.dispatch);

        }
      };

      thunk[ActionBuilderInternalInvoke] = invoke;

      return thunk;

    };
  }

}

export default ActionBuilder;