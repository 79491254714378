import React from 'react';
import {Divider, Grid} from '@material-ui/core';
import AppDialogMui from "../../../../core/components/AppDialog";
import AppMuiInput from "../../../../core/components/inputs/AppInput";
import AppNumericInput from '../../../../core/components/inputs/AppNumericInput/AppMuiNumericInput';
import AppValidationFailure from "../../../../core/components/AppValidationFailure";


const renderSetupCarrierFrom = ({values, errors, isValid, isDirty, setFieldValue, submitForm}) => {

  const  actions = [
    {
      title: 'Cancel',
      action: values.onClose
    },
    {
      title: 'Save',
      action: submitForm,
      disabled: !isValid || !isDirty
    }
  ];

  const handleFieldChange = (key) => (e) => {
    setFieldValue(key, e.target.value);
  }

  return (
    <AppDialogMui
      title={'New Carrier Setup'}
      width={'md'}
      open={true}
      onClose={values.onClose}
      actionButtons={actions}
    >
      <Grid>
        <p>
          Any carrier this is either not already in our system OR in our system, but not showing to be currently monitored by RMIS on behalf of Tallgrass Freight must complete the setup process with RMIS, even if they have done so in the past. If a carrier hasn't moved a shipment for Tallgrass Freight within the last six months, they will be removed from our monitored carrier list at RMIS.
        </p>
        <Divider variant="middle"/>
        <p>
          <b>
            Instruct the carrier to go to <span style={{color: 'red'}}>www.tallgrasscarriers.com.</span> This is where they get setup with us in RMIS. If they do not have internet access, they can call RMIS at <span style={{color: 'red'}}>888-643-8174</span> nad request a Manual Registration Packet for Tallgrass Freight. If they have to call RMIS, the process will take longer.
          </b>
        </p>

        <p style={{marginBottom: '10px'}}>
          Once the carrier has started the setup process with RMIS, their information will transfer over accordingly to our database. if you would like to monitor their progress with RMIS within our system, please enter their MC # along with a label for your choosing for the carrier. Then hit the "Save" button. You can then monitor the setup, along with all other pending carrier setups, via the "Pending Carrier Setups" link on the home screen.
        </p>
        <Divider variant="middle"/>
      </Grid>
      <Grid style={{marginBottom: '20px'}}>
        <p style={{textAlign: "center"}}>
          Please use the MC # if all possible. Enter accurate information!
        </p>
        <Grid container justify={'center'} spacing={2}  style={{marginBottom: '10px'}}>
          <Grid item xs={3}>
            <Grid container>
              <AppMuiInput
                label={'MC #'}
                value={values.mcNumber || ''}
                onChange={handleFieldChange('mcNumber')}
              />
              <AppValidationFailure
                message={errors.mcNumber}
              />
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Grid container>
              <AppMuiInput
                label={'Carrier Label'}
                value={values.carrierLabel || ''}
                onChange={handleFieldChange('carrierLabel')}
                inputProps={{
                  maxLength: 20
                }}
              />
              <AppValidationFailure
                message={errors.carrierLabel}
              />
            </Grid>
          </Grid>
        </Grid>
        <Divider variant="middle"/>
      </Grid>

      <Grid>
        <p style={{color: 'red'}}>
          MPORTANT: RMIS carrier assistance/customer service is available 8 am to 7 pm CST Monday through Friday. Carriers can, however register 24 hours a day with RMIS, but before a carrier's setup/registration with RMIS can be finalized, RMIS must confirm the carrier's Tax ID information along with their insurance coverages. If the carrier is already within the RMIS system and they have that information on file already, then nothing additional will need to be done. However, if the carrier isn't within the RMIS system already, then RMIS personnel may have to manually confirm that information, which can only be done during the customer service hours. The good news is that over 90% of carriers are already within the RMIS system.
        </p>
      </Grid>

    </AppDialogMui>
  )
};

export default renderSetupCarrierFrom;