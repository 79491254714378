import ActionBuilder from '../../ActionBuilder2';
import ActionTypes from '../../ActionTypes';

const savePayPeriodAdjustment = async (shell, associateId, commissionAdjustments) => {

  const updateCommissionAdjustment = await shell.gateway.savePayPeriodAdjustment(associateId, commissionAdjustments);

  // const {adjustments, count} = await shell.gateway.getAssociatePayPeriodCommissionAdjustments(
  //   associateId,
  //   commissionDate
  // );

  return {
    type: ActionTypes.CommissionCenter.CommissionAdjustmentSaved,
    // payPeriodAdjustments: adjustments,
    // payPeriodAdjustmentCount: count
  }
};

export default ActionBuilder
  .for(savePayPeriodAdjustment)
  .build();