import React from 'react';
import { Grid } from '@material-ui/core';

import ComponentBuilder from '../../../core/ComponentBuilder';
import isProcessActive from '../../../hubs/sys/selectors/isProcessActive';

import FullWidthLayout from '../../../core/layouts/FullWidthLayout';
import ReportNav from '../ReportNav';
import SearchLoadsListingTable from './includes/SearchLoadsListingTable';

import AppButton from '../../../core/components/AppButton';
import AppInput from '../../../core/components/inputs/AppInput/AppMuiInput';
import AppTitleBar from '../../../core/components/AppTitleBar';

const LoadProcessName = 'Reporting.SearchLoads.Loaded';

const SearchLoads = (props) => {

  const {
    shipments,
    shipmentCount,
    load,
    dispose,
  } = props;

  const [offset, setOffset] = React.useState(0);
  const [limit, setLimit] = React.useState(20);
  const [sort, setSort] = React.useState([['bolNum', 'desc']]);
  const [workingSearchTerm, setWorkingSearchTerm] = React.useState('');
  const [searchTerm, setSearchTerm] = React.useState('');

  React.useEffect(() => () => dispose(), []);

  React.useEffect(() => {
    if (searchTerm?.length >= 3) {
      load(searchTerm, offset, limit, sort);
    }
  }, [searchTerm, offset, limit, sort]);

  const handleSortChange = (column) => {
    const [[sortColumn, sortDir]] = sort;
    const changeOrder = (sortDir === 'asc' && sortColumn === column) ? 'desc' : 'asc';
    setSort([[column, changeOrder]]);
  };

  const handleSearchChange = (e) =>
    setWorkingSearchTerm(e.target.value);

  const handleSearch = () => {
    setOffset(0);
    setSearchTerm(workingSearchTerm);
  };

  const handlePageChange = (e, page) => {
    setOffset(page * limit);
  };

  const handleChangeRowsPerPage = (e) => {
    setOffset(0);
    setLimit(e.target.value);
  }

  return (
    <FullWidthLayout SideNav={ReportNav}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <AppTitleBar
            title="Search Loads"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid container spacing={1} alignItems="flex-end">
            <Grid item>
              <label htmlFor="search">Search Term</label>
              <AppInput id="search"
                name="search"
                value={workingSearchTerm}
                onChange={handleSearchChange}
                tabIndex={1}
                className="form-control"
                maxLength="50" />
            </Grid>
            <Grid item>
              <AppButton onClick={handleSearch} disabled={workingSearchTerm?.length < 3}>Search</AppButton>
            </Grid>
          </Grid>

        </Grid>
        <Grid item xs={12}>
          <SearchLoadsListingTable
            count={shipmentCount}
            data={shipments}
            orderBy={sort[0][0]}
            order={sort[0][1]}
            searchTerm={searchTerm}
            rowsPerPage={limit}
            page={offset / limit}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            onSort={handleSortChange}
          />
        </Grid>
      </Grid>
    </FullWidthLayout>
  )
}
export default ComponentBuilder
  .wrap(SearchLoads)
  .stateToProps((state, ownProps) => ({
    shipmentCount: state.reporting.searchLoads.searchLoadsCount,
    shipments: state.reporting.searchLoads.listing
  }))
  .dispatchToProps((shell, dispatch, getState) => {
    return {
      async load(searchTerm, offset, limit, sort) {
        dispatch(shell.actions.sys.processStart(LoadProcessName));
        dispatch(await shell.actions.reporting.searchLoads.load(searchTerm, offset, limit, sort));
        dispatch(shell.actions.sys.processComplete(LoadProcessName));
      },
      async dispose() {
        dispatch(await shell.actions.reporting.searchLoads.dispose())
      }
    };
  })
  .build();