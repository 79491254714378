import * as Redux from 'redux';

import announcementNote from './announcementNote';
import authenticationStatus from './authenticationStatus';
import associate from './associate';
import associates from './associates';
import brokerAssociates from "./brokerAssociates";
import deliveredCount from './deliveredCount';
import tgfMetrics from './tgfMetrics';
import associateMetrics from './associateMetrics';
import modifyingAssociate from './modifyingAssociate';
import mrv from './mrv';
import isAuthenticated from './isAuthenticated';
import roles from "./roles";

export default Redux.combineReducers({
  announcementNote,
  authenticationStatus,
  associate,
  associates,
  brokerAssociates,
  deliveredCount,
  isAuthenticated,
  associateMetrics,
  tgfMetrics,
  modifyingAssociate,
  mrv,
  roles
});